import { useApp } from "@hooks/useApp"
import { useCore } from "@hooks/useCore"
import { AddressDetails } from "@hooks/useGooglePlaces"
import React, { useCallback, useMemo, useState } from "react"

export const GooglePlacesContext: any = React.createContext({})

export const GooglePlacesProvider = ({ children }) => {
  const [address, setAddress] = useState<AddressDetails | undefined>()
  const [error, setError] = useState<boolean | true>()
  const [placeId, setPlaceId] = useState<string | undefined>()
  const {
    config: {
      settings: { keys },
    },
  } = useApp()
  const {
    helpers: { storage },
  } = useCore()

  const clearAddress = useCallback(() => {
    setAddress(undefined)
    setPlaceId(undefined)
    storage?.remove(keys?.place_id)
  }, [storage, setAddress, setPlaceId])

  const values = useMemo(
    () => ({
      address,
      clearAddress,
      error,
      placeId,
      setAddress,
      setError,
      setPlaceId,
    }),
    [address, clearAddress, error, placeId, setAddress, setError, setPlaceId]
  )

  return <GooglePlacesContext.Provider value={values}>{children}</GooglePlacesContext.Provider>
}
