import React, { FC } from "react"
import { useRichText } from "@hooks/useRichText"
import { useSettings } from "../../hooks/useSettings"
import { ComponentProps } from "../../types/components"

export type FooterAcknowledgementOfCountryOutputProps = {
  text: string
}

export const withFooterAcknowledgementOfCountry =
  (Component: FC<FooterAcknowledgementOfCountryOutputProps>) =>
  ({ name = "FooterAcknowledgementOfCountry" }: ComponentProps) => {
    const { acknowledgementOfCountry } = useSettings()
    const { parseContent } = useRichText()

    const text = parseContent(acknowledgementOfCountry?.acknowledgementOfCountry)

    if (!text) {
      return null
    }

    Component.displayName = name
    return <Component text={text} />
  }
