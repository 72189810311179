import { withFocusRing } from "@components/Accessibility/FocusRing/FocusRing"
import { Widget as AccountWidget } from "@components/Account/Widget/Widget"
import { Icon } from "@components/Icon"
import { WishlistWidget } from "@components/Wishlist/WishlistWidget"
import React from "react"
import tw from "twin.macro"
import { CartWidget } from "../Cart/Widget/CartWidget"
import { withHeaderAccount } from "./withHeaderAccount"

const Wrapper = tw.div`w-4/9 ml-auto flex flex-row items-center justify-end float-right`

const StyledIcon = tw(Icon)`p-mini text-secondary hover:text-primary lg:px-mini lg:py-2`

const Button = withFocusRing(tw.button`
   hidden items-center lg:flex
`)

const HeaderAccount = withHeaderAccount(({ handleClick }) => {
  return (
    <Wrapper>
      <Button onClick={handleClick} aria-label={"Search"} data-testid="header-search-trigger">
        <StyledIcon name="search" size="xsmall" />
      </Button>
      <WishlistWidget />
      <AccountWidget />
      <CartWidget />
    </Wrapper>
  )
})

export default HeaderAccount
