import { Icon } from "@components/Icon"
import { Link } from "@components/Link/Link"
import { ComponentProps } from "@ts/components"
import React from "react"
import tw from "twin.macro"

type NavigationHeaderSubShoeIconProps = ComponentProps & {
  title: string
  url: string
  icon: string
  onClick?: () => void
}

const StyledLink = tw.div`
  relative flex flex-col justify-center items-center mb-micro pb-mini transition-presentation duration-fast text-secondary hover:text-primary
`

const Title = tw.h5`
  absolute bottom-0 block text-hint leading-h3 text-center
`

const NavigationHeaderSubShoeIcon = ({ title, url, icon, className, onClick, "data-testid": dataTestId }: NavigationHeaderSubShoeIconProps) => {
  return (
    <StyledLink as={url ? Link : "div"} className={className} to={url} onClick={onClick} data-testid={dataTestId}>
      <Icon name={icon} size="xxlarge" />
      <Title>{title}</Title>
    </StyledLink>
  )
}

export default NavigationHeaderSubShoeIcon
