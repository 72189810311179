import { Link } from "@components/Link/Link"
import { StyledSmall } from "@components/Styled/Text"
import { styledWithInvalidProps } from "@components/Styled/StyledWithInvalidProps"
import React from "react"
import tw from "twin.macro"
import NavigationHeaderSub from "./NavigationHeaderSub"
import { withNavigationHeaderItems } from "./withNavigationHeaderItems"

type PrimaryLinkProps = {
  active?: boolean
  first?: string
}

const PrimaryLink = styledWithInvalidProps("div")(({ active, first }: PrimaryLinkProps) => [
  tw`relative flex items-center overflow-hidden cursor-pointer md:ml-midi lg:ml-midi xl:ml-mega`,
  active ? tw`text-primary` : tw`text-secondary`,
  first === "true" ? tw`lg:ml-0` : null,
])

const Dash = styledWithInvalidProps("div")(({ active }: Pick<PrimaryLinkProps, "active">) => [
  tw`absolute w-full border-b-4 border-primary -bottom-maxi lg:bottom-zero`,
  active ? tw`block` : tw`hidden`,
])

const MenuListDesktop = tw.ul`
  flex flex-row items-center w-5/11 hidden lg:pl-midi lg:flex xl:pl-midi
`

const TitleContent = tw.div`
  leading-h1 xl:text-[18px]
`

const Title = tw(StyledSmall)`
  leading-h1 whitespace-nowrap xl:text-[18px]
`

const MenuItem = tw.li`
  flex content-center h-full
`

const NavigationHeaderDesktop = withNavigationHeaderItems(({ items, active, handleHover, isActivePath }) => (
  <MenuListDesktop>
    {items?.map((item, index) => {
      const isActive = isActivePath(item)
      return (
        <MenuItem
          key={item.title}
          onMouseEnter={handleHover(item.title)}
          onMouseLeave={handleHover(null)}
          data-testid={`header-navigation-desktop-item-${index}`}
        >
          <PrimaryLink
            onClick={handleHover(null)}
            as={item?.url ? Link : null}
            to={item?.url}
            active={isActive}
            first={index === 0 ? "true" : undefined}
          >
            <TitleContent>
              <Title as="span">{item?.title}</Title>
              <Dash active={isActive} data-testid="menuText-underline" />
            </TitleContent>
          </PrimaryLink>
          {item?.items && item?.items?.length > 0 ? (
            <NavigationHeaderSub
              subNavigation={item}
              active={active === item?.title}
              data-testid={`header-navigation-desktop-panel-${index}`}
              handleHover={handleHover}
            />
          ) : null}
        </MenuItem>
      )
    })}
  </MenuListDesktop>
))

export default NavigationHeaderDesktop
