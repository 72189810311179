import { useCallback } from "react"
import { useApp } from "./useApp"
import { useCheckoutContext } from "./useCheckout"
import { useCore } from "./useCore"
import { useLocalisationContext } from "./useLocalisation"
import { useLocation } from "./useLocation"

export const useGeofencing = () => {
  const { country } = useLocation()
  const { localisation, currentLocale, enableGeolocation } = useLocalisationContext()
  const {
    config: {
      settings: {
        keys: { geofencing_locale },
      },
    },
  } = useApp()
  const {
    helpers: { storage },
  } = useCore()
  const { checkout } = useCheckoutContext()
  const { buyerIdentity, lineItems } = checkout || {}
  const { countryCode } = buyerIdentity || {}

  const isUserCountryAvailableInLocale = process.env.GATSBY_SHIPPING_GEOFENCING_ENABLED
    ? lineItems?.length > 0
      ? currentLocale.allowedCountryCodes.includes(countryCode)
      : currentLocale.allowedCountryCodes.includes(country)
    : currentLocale.allowedCountryCodes.includes(country)

  const preferredLocale = process.env.GATSBY_SHIPPING_GEOFENCING_ENABLED
    ? lineItems?.length > 0
      ? localisation.find(locale => locale.allowedCountryCodes.includes(countryCode))
      : localisation.find(locale => locale.allowedCountryCodes.includes(country))
    : localisation.find(locale => locale.allowedCountryCodes.includes(country))

  const fallbackLocale = localisation.find(locale => locale.isDefault)
  const isAllowed =
    !enableGeolocation || process.env.GATSBY_GEOFENCING_DISABLED
      ? true
      : isUserCountryAvailableInLocale || (!isUserCountryAvailableInLocale && !preferredLocale)
  const redirectLocale = !isAllowed ? preferredLocale || fallbackLocale : undefined

  const savePreferredLocale = useCallback(() => {
    if (!redirectLocale) {
      return
    }

    storage.set(geofencing_locale, redirectLocale.baseRoute)
  }, [redirectLocale])

  return {
    isAllowed,
    redirectLocale,
    savePreferredLocale,
  }
}
