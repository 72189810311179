import { useAnnouncement } from "@hooks/useAnnouncement"
import { useRoutes } from "@hooks/useRoutes"
import { ComponentProps, ResolvedLink } from "@ts/components"
import React, { FC, useEffect, useMemo, useState } from "react"

export type AnnouncementInputProps = ComponentProps

export type AnnouncementItem = {
  title?: string
  link?: ResolvedLink
}

export type AnnouncementOutputProps = {
  items: AnnouncementItem[]
  active: number
}

export const withAnnouncement = (Component: FC<AnnouncementOutputProps>) =>
  React.memo(({ name = "Announcement" }: AnnouncementInputProps) => {
    const { items, duration } = useAnnouncement()
    const { linkResolver } = useRoutes()
    const [active, setActive] = useState(0)
    const announcements = items?.map(({ link, ...item }) => ({
      ...item,
      link: linkResolver(link),
    }))
    const length = items?.length - 1

    useEffect(() => {
      if (length) {
        const interval = setInterval(() => {
          setActive(active => (active === length ? 0 : active + 1))
        }, duration * 1000)
        return () => clearInterval(interval)
      }
    }, [duration, length])

    Component.displayName = name
    return useMemo(() => <Component active={active} items={announcements} />, [active, announcements])
  })
