import { useCore } from "./useCore"
import { useSettings } from "./useSettings"
import { useApp } from "./useApp"
import { useSale } from "./useSale"
import { useShopify } from "./useShopify"

export const useMeta = () => {
  const { helpers } = useCore()
  const { organisation, tracking, social } = useSettings()
  const { getSaleTagForDefaultCurrencyFromTags } = useShopify()

  const {
    config: { localisation },
  } = useApp()

  const { sale } = useSale()

  const mapping = {
    page: helpers.schemaWebSite,
    blog: helpers.schemaBlog,
    article: helpers.schemaArticle,
    product: helpers.schemaProduct,
    collection: helpers.schemaWebSite,
    search: helpers.schemaSearch,
    store: helpers.schemaStore,
  }

  const getData = ({ data, url, language, routes }) =>
    helpers.schemaData({ data, language, organisation, routes, social, tracking, url, template: {}, global: "", breadcrumbs: [] }, mapping)

  const getTags = data => helpers.metaTags(data)

  const getLanguages = ({ url, data, path }) => {
    return localisation
      .filter(({ enabled, defaultCountryCode }) => {
        if (!enabled) return false

        if (data?.product?.tags) {
          return data?.product?.tags?.filter(tag => tag === `country:${defaultCountryCode}`)
        }

        return true
      })
      .map(({ languageCode, hrefLang, isDefault, baseRoute }) => ({
        primary: isDefault,
        href: `${url}${baseRoute}${path || ""}`,
        hrefLang,
        rel: "alternate",
        language: languageCode,
      }))
  }

  const getSchemas = data => [helpers.schemaOrg(data), modifyMetadata(data, helpers.schemaContent(data)), helpers.schemaBreadcrumbs(data)]

  // 1. Update sale price in Structured Data markup during sale
  // 2. Update normal price in Structured Data markup if applicable
  const modifyMetadata = (data, schemaContent) => {
    const productTags = data?.document?.tags
    const variantPrice = data?.document?.variants?.reduce(
      (result, item) => (item?.sku?.length > 0 ? { ...result, [item.sku]: item.priceV2 } : result),
      {}
    )
    const saleTag = getSaleTagForDefaultCurrencyFromTags(productTags)
    const salePrice = saleTag?.split(":")?.[3]

    if (sale?.enabled && "sale" === sale?.type && salePrice) {
      return {
        ...schemaContent,
        offers: {
          ...schemaContent?.offers,
          lowPrice: Number(salePrice).toFixed(2),
          offers: schemaContent?.offers?.offers?.map(offer => ({
            ...offer,
            price: `${salePrice}`,
            priceCurrency: schemaContent?.offers?.priceCurrency || "",
          })),
        },
      }
    } else if (variantPrice && Object.keys(variantPrice).length > 0) {
      return {
        ...schemaContent,
        offers: {
          ...schemaContent?.offers,
          offers: schemaContent?.offers?.offers?.map(offer => ({
            ...offer,
            price: offer?.sku?.length > 0 ? variantPrice[offer.sku] || "" : "",
            priceCurrency: schemaContent?.offers?.priceCurrency || "",
          })),
        },
      }
    } else {
      return schemaContent
    }
  }

  const getOpenGraphData = doc => {
    const { type, canonical, siteImage, title, description } = doc
    const { facebookAppId } = tracking
    const {
      document: { metadata, shopify },
    } = doc

    const { title: metaTitle, image: metaImage, description: metaDescription, canonicalUrl: metaUrl } = metadata || {}

    switch (type) {
      case "product":
        const {
          document: { image: productImage, title: productTitle, descriptionHtml: productDescription },
        } = doc

        const plainTextDescription = productDescription?.replace(/(<([^>]+)>)/gi, "")?.replace(/(\n.*)$/m, "")

        return {
          title: `${metaTitle || title}`,
          image: {
            url: metaImage?.asset?.url || productImage || siteImage?.url,
            description: metaImage?.alt || productTitle || siteImage?.description,
          },
          description: metaDescription || plainTextDescription || description,
          url: metaUrl || canonical,
          facebookAppId,
        }
      case "collection":
        const { image: collectionShopifyMetaImage, description: collectionShopifyMetaDescription } = shopify || {}

        return {
          title: `${metaTitle || title}`,
          image: {
            url: metaImage?.asset?.url || collectionShopifyMetaImage || siteImage?.url,
            description: metaImage?.alt || collectionShopifyMetaDescription || siteImage?.description,
          },
          description: metaDescription || collectionShopifyMetaDescription || description,
          url: metaUrl || canonical,
          facebookAppId,
        }
      case "article":
        const {
          document: { title: articleTitle, summary: articleDescription, image: articleImage },
        } = doc

        return {
          title: `${metaTitle || articleTitle || title}`,
          image: {
            url: metaImage?.asset?.url || articleImage?.asset?.url || siteImage?.url,
            description: metaImage?.alt || articleImage?.asset?.alt || siteImage?.description,
          },
          description: metaDescription || articleDescription || description,
          url: metaUrl || canonical,
          facebookAppId,
        }
      case "page":
        const { title: pageTitle } = doc

        return {
          title: metaTitle || pageTitle || title,
          image: {
            url: metaImage?.asset?.url || siteImage?.url,
            description: metaImage?.asset?.alt || siteImage?.description,
          },
          description: metaDescription || description,
          url: metaUrl || canonical,
          facebookAppId,
        }
      default:
        return {
          title,
          image: {
            url: siteImage?.url,
            description: siteImage?.description,
          },
          description: description,
          url: canonical,
          facebookAppId,
        }
    }
  }

  return {
    getData,
    getLanguages,
    getOpenGraphData,
    getTags,
    getSchemas,
  }
}
