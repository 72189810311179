import React, { useState, useEffect, FC, ChangeEvent } from "react"

import { usePage } from "../../../hooks/usePage"
import { useCheckout, useCheckoutContext } from "../../../hooks/useCheckout"
import { useApp } from "../../../hooks/useApp"
import { ComponentProps } from "../../../types/components"
import { PageCart } from "../../../types/sanity"
import { CheckoutUserError } from "../../../types/shopify-storefront"

export type CartNotesInputProps = ComponentProps

export type CartNotesOutputProps = CartNotesInputProps &
  Partial<PageCart> & {
    errors?: CheckoutUserError[]
    handleNoteChange: (e: ChangeEvent<HTMLInputElement>) => void
    handleOptionChange: (e: ChangeEvent<HTMLInputElement>) => void
    handleSave: () => void
    note?: string
    selected?: string[]
  }

export const withCartNotes =
  (Component: FC<CartNotesOutputProps>) =>
  ({ name = "CartNotes" }: CartNotesInputProps) => {
    const {
      config: {
        settings: { keys },
      },
    } = useApp()
    const { cart } = usePage()
    const { additionalCustomerNotePlaceholder, noteDescription, noteOption, noteTitle } = cart
    const { checkout } = useCheckoutContext()
    const { updateAttributes, errors } = useCheckout()
    const [note, setNote] = useState<string>(checkout?.note || "")
    const [canSave, setCanSave] = useState<boolean>(false)
    const [selected, setSelected] = useState<string[]>(
      checkout?.customAttributes?.find(attribute => attribute.key === keys?.attributes_fitting_option)?.value?.split(",") || []
    )

    const handleOptionChange = ({ target: { name: optionName } }) => {
      if (!selected.includes(optionName)) {
        setSelected([...selected, optionName])
      } else {
        setSelected(prevValue => prevValue.filter(item => item !== optionName))
      }
      setCanSave(true)
    }

    const handleNoteChange = e => {
      setNote(e.target.value)
      setCanSave(true)
    }

    const handleSave = () => {
      const customAttributes = checkout.customAttributes
        .filter(attribute => attribute.key !== keys?.attributes_fitting_option)
        .filter(attribute => attribute.key !== keys?.attributes_note)
        .map(({ key, value }) => ({ key, value }))
      if (selected.length > 0) customAttributes.push({ key: keys?.attributes_fitting_option, value: selected.toString() })
      if (canSave) {
        updateAttributes({
          customAttributes,
          note,
        })
      }
    }

    useEffect(() => {
      handleSave()
    }, [selected])

    Component.displayName = name

    return (
      <Component
        additionalCustomerNotePlaceholder={additionalCustomerNotePlaceholder}
        errors={errors}
        handleNoteChange={handleNoteChange}
        handleOptionChange={handleOptionChange}
        handleSave={handleSave}
        note={note}
        noteDescription={noteDescription}
        noteOption={noteOption}
        noteTitle={noteTitle}
        selected={selected}
      />
    )
  }
