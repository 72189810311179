import { useCheckout } from "@hooks/useCheckout"
import { ComponentProps } from "@ts/components"
import { SearchResultProductPresentmentPriceRanges, SearchResultProductVariant } from "@ts/search"
import React, { FC } from "react"

export type SearchResultsProductPriceInputProps = ComponentProps & {
  priceRange: SearchResultProductPresentmentPriceRanges
  saleTag: string
  variants: SearchResultProductVariant[]
}

export type SearchResultsProductOutputProps = ComponentProps & {
  price: string
  salePrice: string
}

export const withSearchResultsProductPrice =
  (Component: FC<SearchResultsProductOutputProps>) =>
  ({ name = "SearchResultsProductPrice", priceRange, variants, className, saleTag }: SearchResultsProductPriceInputProps) => {
    const { getFormattedSalePriceFromTagOrRange } = useCheckout()
    const { price, salePrice } = getFormattedSalePriceFromTagOrRange(priceRange, saleTag, null, variants)

    Component.displayName = name

    return <Component price={price} salePrice={salePrice} className={className} />
  }
