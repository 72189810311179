import React from "react"
import { useShopify } from "../../hooks/useShopify"
import { ImageProps } from "./withImage"
import { useTemplate } from "@hooks/useTemplate"

export type ShopifyImageProps = ImageProps

export const withShopifyImage =
  Component =>
  ({ name = "ShopifyImage", src, maxWidth, useSrcSet = true, ...props }: ShopifyImageProps) => {
    const { imageUrl, imageSrcSets } = useShopify()

    const normalisedSrc = imageUrl(src, useSrcSet ? 1 : maxWidth)
    const normalisedSrcSet = useSrcSet ? imageSrcSets(src, maxWidth) : undefined

    Component.displayName = name
    return <Component {...props} src={normalisedSrc} srcSet={normalisedSrcSet} />
  }
