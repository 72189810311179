import { useApp } from "@hooks/useApp"
import { useDebounce } from "@react-hooks-library/core"
import React, { Context, useEffect, useMemo, useCallback, useState } from "react"

export type ReviewsContextProps = {
  addProductToQueue?: (handle: string) => void
  productQueue?: readonly string[]
}

export const ReviewsContext: Context<ReviewsContextProps> = React.createContext<ReviewsContextProps>({})

export const ReviewsProvider = ({ children }) => {
  const [productQueue, setProductQueue] = useState([])
  const productQueueDebounced = useDebounce<string[]>(productQueue, 250)
  const {
    config: {
      services: {
        reviews_io: {
          store,
          snippet: { color, name },
        },
      },
    },
  } = useApp()

  const addProductToQueue = useCallback(
    (handle: string) => {
      return setProductQueue(prevState => {
        return [...prevState, handle]
      })
    },
    [setProductQueue]
  )

  const renderRatingSnippet = useCallback((): NodeJS.Timeout | void => {
    const ratingSnippet = window["ratingSnippet"]
    if (!ratingSnippet) {
      return setTimeout(() => renderRatingSnippet(), 1000)
    }

    try {
      ratingSnippet(name, {
        store,
        color,
        text: "reviews",
        linebreak: false,
        useCustomLookup: true,
      })
    } catch (e) {
      console.error("Call to ratingSnippet threw an exception.", e)
    }
  }, [store, color])

  const values = useMemo(
    () => ({
      addProductToQueue,
      productQueue: productQueueDebounced,
    }),
    [addProductToQueue, productQueueDebounced]
  )

  useEffect(() => {
    renderRatingSnippet()
  }, [productQueueDebounced])

  return <ReviewsContext.Provider value={values}>{children}</ReviewsContext.Provider>
}
