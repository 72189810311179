import { withFocusRing } from "@components/Accessibility/FocusRing/FocusRing"
import { Accordion } from "@components/Accordion/Accordion"
import { Icon } from "@components/Icon"
import { Link } from "@components/Link/Link"
import ModalsDrawer from "@components/Modals/ModalsDrawer"
import { styledWithInvalidProps } from "@components/Styled/StyledWithInvalidProps"
import { StyledSmall } from "@components/Styled/Text"
import { NormalisedNavigation } from "@hooks/useNavigation"
import { Location } from "@reach/router"
import React from "react"
import tw from "twin.macro"
import NavigationHeaderSubImage from "./NavigationHeaderSubImage"
import NavigationHeaderSubShoeIcon from "./NavigationHeaderSubShoeIcon"
import { NavigationHeaderItemsOutputProps, withNavigationHeaderItems } from "./withNavigationHeaderItems"

type LinkListItemsProps = {
  handleMenuClick?: () => void
  linkItems?: NormalisedNavigation[]
  shopEditItems?: NormalisedNavigation[]
  title?: any
}

type LinkListProps = {
  size: string
}

const LinkListItem = styledWithInvalidProps(Link)(({ highlight }: LinkListItemsProps) => [
  tw`text-body text-secondary hover:text-primary`,
  highlight ? tw`font-medium border-b-2 text-secondary pb-pico` : null,
])

const LinkList = styledWithInvalidProps("ul")(({ size }: LinkListProps) => [
  tw`flex flex-col pb-mini`,
  size ? tw`w-1/2 first:mr-micro last:ml-micro` : tw`w-full`,
])

const ContentList = tw.div`
  flex flex-wrap w-full mb-maxi
`

const IconWrapper = tw.div`
  grid grid-cols-4 w-full
`

const StyledIcon = tw(Icon)`text-secondary`

const LinkListWrapper = tw.div`
  flex flex-row
`

const LinkListItemList = tw.li`
  py-mini 
`

const Button = withFocusRing(tw.button`
  flex items-center p-micro
`)

const MenuListMobile = tw.div`
  flex flex-row items-center w-4/9 -ml-pico md:ml-mini lg:hidden
`

const LinkListTitle = tw(StyledSmall)`
  w-full uppercase text-secondary border-b border-tertiary pb-mini mb-mini
`

const ImageItem = tw.div`
  block mb-midi last:mb-3 first:mt-3
`

const ImageListWrapper = tw.div``
const Image = tw(NavigationHeaderSubImage)``

const LinkListItems = ({ shopEditItems, title, linkItems, handleMenuClick }: LinkListItemsProps) => (
  <LinkList size={shopEditItems?.length > 0}>
    {title ? <LinkListTitle>{title}</LinkListTitle> : null}
    {linkItems?.map((linkItem, index) => {
      return (
        <LinkListItemList key={index}>
          <LinkListItem
            to={linkItem?.url}
            onClick={handleMenuClick}
            data-testid={`header-navigation-mobile-accordion-link-item-${index}`}
            highlight={linkItem?.highlightLink}
          >
            {linkItem?.title}
          </LinkListItem>
        </LinkListItemList>
      )
    })}
  </LinkList>
)

const NavigationHeaderMobile = withNavigationHeaderItems(
  ({ items, activeMenu, handleMenuClick, handleSearchClick, isActivePath }: NavigationHeaderItemsOutputProps) => (
    <MenuListMobile>
      <Button data-testid={"header-navigation-mobile-menu-trigger"} onClick={handleMenuClick} title={"Menu"}>
        {!activeMenu ? <StyledIcon name="hamburger" size="small" /> : <StyledIcon name="close" size="small" />}
      </Button>
      <Button data-testid={"header-navigation-mobile-search-trigger"} onClick={handleSearchClick} title={"Search"}>
        <StyledIcon name="search" size="xsmall" />
      </Button>
      <Location>
        {({ location }) => (
          <ModalsDrawer data-testid="header-navigation-mobile-drawer" active={activeMenu} setActive={handleMenuClick} noClose>
            {items?.map((item, index) => {
              const isActive = isActivePath(item, location)
              return (
                <Accordion
                  key={index}
                  title={item?.title}
                  size="header"
                  icon={Boolean(item?.contents?.length > 0 || item?.items?.length > 0)}
                  link={item?.url}
                  handleRedirect={handleMenuClick}
                  active={isActive}
                  hightlight={isActive}
                  data-testid={`header-navigation-mobile-accordion-${index}`}
                >
                  {!!item?.contents?.length ? (
                    <ContentList>
                      {item?.collectionTitle && <LinkListTitle>{item?.collectionTitle}</LinkListTitle>}
                      <IconWrapper>
                        {item.contents?.map((content, index) => (
                          <NavigationHeaderSubShoeIcon
                            key={index}
                            title={content.title}
                            icon={content.icon}
                            url={content.url}
                            onClick={handleMenuClick}
                            data-testid={`header-navigation-mobile-accordion-shoe-icon-${index}`}
                          />
                        ))}
                      </IconWrapper>
                    </ContentList>
                  ) : null}

                  <LinkListWrapper>
                    {!!item?.items?.length ? (
                      <LinkListItems
                        shopEditItems={item?.shopEditItems}
                        title={item?.featuredTitle}
                        linkItems={item.items}
                        handleMenuClick={handleMenuClick}
                      />
                    ) : null}
                    {!!item?.shopEditItems?.length ? (
                      <LinkListItems
                        shopEditItems={item?.shopEditItems}
                        title={item?.shopEditTitle}
                        linkItems={item.shopEditItems}
                        handleMenuClick={handleMenuClick}
                      />
                    ) : null}
                  </LinkListWrapper>

                  {item?.images && (
                    <ImageListWrapper>
                      {item?.images.map(
                        (imageItem, index) =>
                          imageItem?.mobileImage && (
                            <ImageItem
                              key={`${imageItem.title}-${index}`}
                              onClick={handleMenuClick}
                              data-testid={`header-navigation-mobile-accordion-images-${index}`}
                            >
                              <Image image={imageItem} data-testid={`header-navigation-images-${index}`} />
                            </ImageItem>
                          )
                      )}
                    </ImageListWrapper>
                  )}
                </Accordion>
              )
            })}
          </ModalsDrawer>
        )}
      </Location>
    </MenuListMobile>
  )
)

export default NavigationHeaderMobile
