import { useAnalytics } from "@hooks/useAnalytics"
import { useApp } from "@hooks/useApp"
import { useCore } from "@hooks/useCore"
import { useCustomerContext } from "@hooks/useCustomer"
import { useFunctions } from "@hooks/useFunctions"
import { useGeofencing } from "@hooks/useGeofencing"
import { useImage } from "@hooks/useImage"
import { useRichText } from "@hooks/useRichText"
import { useRoutes } from "@hooks/useRoutes"
import { useSubscribe } from "@hooks/useSubscribe"
import { globalHistory } from "@reach/router"
import { type ComponentProps, type GatsbyCustomImage, type GatsbyImageSourceProps, type ResolvedLink } from "@ts/components"
import { type SubscribeOption } from "@ts/sanity"
import { CustomerMarketingOptInLevel, CustomerSmsMarketingState } from "@ts/shopify-admin"
import React, { ChangeEvent, ReactNode, memo, useEffect, useState, type FC, type FormEvent } from "react"

export type SubscribeFormOutputProps = {
  active: boolean
  data: any
  emailsInterestedInNotSetupMessage: string
  emailTitle: string
  description: ReactNode | ReactNode[]
  errorInterestedInIsEmpty: boolean
  errors: any[]
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void
  handleInvalid: (event: ChangeEvent<HTMLInputElement>) => void
  handleModalToggle: () => void
  handleSubmit: (event: FormEvent) => Promise<void>
  handleSuccessButtonClick: () => void
  images: GatsbyImageSourceProps[]
  loading: boolean
  options: SubscribeOption[]
  privacy: ResolvedLink
  requiredFields: {
    [key: string]: boolean
  }
  success: boolean
  successButton: any
  successMessage: string
  successTitle: string
  tags: string[]
  terms: string
  toggleTag: (tag: string) => void
}

type ChildComponentContext = {
  trackingName: "Popup" | "Footer"
}

type FormData = {
  email?: string
  phone?: string
}

export const withSubscribeForm = (Component: FC<SubscribeFormOutputProps>, context: ChildComponentContext) =>
  memo(({ name = "SubscribeForm" }: ComponentProps) => {
    const {
      helpers: { storage, decodeBase64 },
    } = useCore()
    const { parseContent } = useRichText()
    const {
      config: {
        settings: { keys },
      },
    } = useApp()
    const { trackSignup } = useAnalytics()
    const [active, setActive] = useState(false)
    const { subscribe } = useSubscribe()
    const { customerSubscribe, errors, setErrors } = useFunctions()
    const geofencing = useGeofencing()
    const { customer } = useCustomerContext()
    const { linkResolver } = useRoutes()
    const { getFluidImage } = useImage()

    const [data, setData] = useState<FormData>({ email: "", phone: "" })
    const [tags, setTags] = useState(["email_pop_up", "newsletter"])
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [hasClosed, setHasClosed] = useState(false)
    const [requiredFields, setRequiredFields] = useState({})
    const { getUrlFragments } = useRoutes()
    const { path } = getUrlFragments()
    const [errorInterestedInIsEmpty, setErrorInterestedInIsEmpty] = useState(false)

    const hasValidInterests = () => {
      return !!tags.find(tag => tag === "men" || tag === "women")
    }

    const handleModalToggle = () => {
      setActive(prevState => !prevState)
      setHasClosed(true)
    }

    const handleSubmit = async (event: FormEvent) => {
      setLoading(true)
      event.preventDefault()
      setErrorInterestedInIsEmpty(false)
      if (!hasValidInterests()) {
        setLoading(false)
        return setErrorInterestedInIsEmpty(true)
      }
      const hasMarketingPreference = typeof customer?.acceptsMarketing !== "undefined"
      const acceptsMarketing = hasMarketingPreference ? customer?.acceptsMarketing : !!data?.email
      const smsMarketingConsent = data?.phone
        ? {
            marketingState: CustomerSmsMarketingState.SUBSCRIBED,
            marketingOptInLevel: CustomerMarketingOptInLevel.SINGLE_OPT_IN,
          }
        : undefined
      const { customer: newCustomer, userErrors } = await customerSubscribe(
        data?.email,
        tags,
        acceptsMarketing,
        smsMarketingConsent,
        customer?.id ? decodeBase64(customer?.id) : null,
        customer?.email,
        data?.phone
      )

      if (newCustomer?.id) {
        setSuccess(true)
        trackSignup(context?.trackingName)
      }

      setErrors(userErrors)
      setLoading(false)
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { type, name, value, checked },
      } = event
      setData(prevState => ({
        ...prevState,
        [name]: type === "checkbox" ? checked : value,
      }))

      handleInvalid(event)
    }

    const handleInvalid = (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault()
      const {
        target: { name, value },
      } = event

      setRequiredFields(prevState => ({
        ...prevState,
        [name]: !value,
      }))
    }

    const toggleTag = tag => {
      const index = tags.indexOf(tag)
      index === -1 ? setTags([...tags, tag]) : setTags(tags.filter(a => a !== tag))
    }

    const handleSuccessButtonClick = () => {
      storage.set(keys?.subscribe, "hidden")
      setActive(prev => (!prev ? prev : false))
    }

    const { emailTitle, options, successTitle, displayDelay = 0 } = subscribe || {}
    const emailsInterestedInNotSetupMessage = subscribe?.emailsInterestedInNotSetupMessage
    const images = subscribe?.images?.map((img: GatsbyCustomImage) => {
      const image = getFluidImage(img, { layout: "fullWidth" })

      return image
    })
    const privacy = linkResolver(subscribe?.privacy)
    const successButton = linkResolver(subscribe?.successCTA)
    const successMessage = parseContent(subscribe?.successMessage)
    const terms = parseContent(subscribe?.emailTerms)
    const description = parseContent(subscribe?.emailContent)

    useEffect(() => {
      if (subscribe && subscribe?.popupSubscribeEnabled) {
        const previouslyLoggedIn = storage.get(keys?.customer)
        const isLoggedIn = customer
        const isAcceptMarketing = customer?.acceptsMarketing
        const isClosed = storage.get(keys?.subscribe) === "hidden"
        const isProductPage = path?.startsWith("/products")
        const isAllowedToVisitSite = geofencing?.isAllowed

        if (
          !hasClosed &&
          isAllowedToVisitSite &&
          !isProductPage &&
          !isClosed &&
          ((!isLoggedIn && !previouslyLoggedIn) || (isLoggedIn && !isAcceptMarketing))
        ) {
          setTimeout(() => setActive(true), displayDelay)
        } else {
          setActive(false)
        }
      } else {
        setActive(false)
      }
    }, [subscribe, customer, path, geofencing])

    useEffect(() => {
      return globalHistory.listen(({ action }) => {
        if (action === "PUSH") {
          setSuccess(false)
          setErrors([])
        }
      })
    }, [setSuccess, setErrors])

    Component.displayName = name

    return (
      <Component
        active={active}
        data={data}
        emailsInterestedInNotSetupMessage={emailsInterestedInNotSetupMessage}
        emailTitle={emailTitle}
        description={description}
        errorInterestedInIsEmpty={errorInterestedInIsEmpty}
        errors={errors}
        handleChange={handleChange}
        handleInvalid={handleInvalid}
        handleModalToggle={handleModalToggle}
        handleSubmit={handleSubmit}
        handleSuccessButtonClick={handleSuccessButtonClick}
        images={images}
        loading={loading}
        options={options}
        privacy={privacy}
        requiredFields={requiredFields}
        success={success}
        successButton={successButton}
        successMessage={successMessage}
        successTitle={successTitle}
        tags={tags}
        terms={terms}
        toggleTag={toggleTag}
      />
    )
  })
