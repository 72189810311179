import { withFocusRing } from "@components/Accessibility/FocusRing/FocusRing"
import { useLocalisation } from "@hooks/useLocalisation"
import { Link as GatsbyLink, type GatsbyLinkProps } from "gatsby"
import React, { type PropsWithoutRef } from "react"
import tw from "twin.macro"

export type LocalisedInputProps<T> = PropsWithoutRef<GatsbyLinkProps<T>>

const ExternalLink = withFocusRing(tw.a`hover:text-secondary`)
const StyledGatsbyLink = withFocusRing(tw(GatsbyLink)`hover:text-secondary`)

export const Link = <T,>({ to, children, ...rest }: LocalisedInputProps<T>) => {
  const { routeMap, currentLocale } = useLocalisation()
  const { languageCode } = currentLocale || {}
  const path = `${languageCode ? routeMap?.[languageCode] : ""}${to}`

  const isExternalLink = (path: string) => {
    return path?.startsWith("http://") || path?.startsWith("https://") || path?.startsWith("//")
  }

  if (!to) {
    return <span {...rest}>{children}</span>
  }

  if (isExternalLink(to)) {
    return (
      <ExternalLink {...rest} href={to} rel="noopener noreferrer">
        {children}
      </ExternalLink>
    )
  }

  return (
    <StyledGatsbyLink to={path} {...rest}>
      {children}
    </StyledGatsbyLink>
  )
}
