import React from "react"
import { Helmet } from "react-helmet"

import { withMeta } from "./withMeta"

export const Meta = withMeta(({ languages, metadata, opengraph, canonical, metatags, schemas, mobileImage, desktopImage }) => (
  <Helmet title={metadata?.title} meta={metatags}>
    {mobileImage && <link rel="preload" href={mobileImage} as="image" media="(max-width: 767px)" />}
    {desktopImage && <link rel="preload" href={desktopImage} as="image" media="(min-width: 768px)" />}
    <meta name="description" content={metadata?.description} />
    <meta name="robots" content={metadata?.robots} />
    <meta name="google-site-verification" content={metadata?.googleSiteVerify} />
    <meta property="og:title" content={opengraph?.title} />
    <meta property="og:image" content={opengraph?.image?.url} />
    <meta property="og:image:alt" content={opengraph?.image?.description} />
    <meta property="og:description" content={opengraph?.description} />
    <meta property="og:url" content={opengraph?.url} />
    <meta property="og:type" content="website" />
    <meta property="fb:app_id" content={opengraph?.facebookAppId} />
    <link key="canonical" href={canonical?.href} rel={canonical?.rel} data-testid={"canonical"} />
    {languages?.map(({ href, hrefLang, rel }) => <link key={hrefLang} href={href} hrefLang={hrefLang} rel={rel} />)}
    {schemas?.map((schema, index) => (
      <script key={index} type="application/ld+json">
        {JSON.stringify(schema)}
      </script>
    ))}
  </Helmet>
))
