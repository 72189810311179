import { type GatsbyCustomImage, type ResolvedLink } from "@ts/components"
import React, { type ReactNode } from "react"
import tw from "twin.macro"
import { Image } from "./Image/Image"
import { styledWithInvalidProps } from "./Styled/StyledWithInvalidProps"

type ImageContentProps = {
  children?: ReactNode
  image?: GatsbyCustomImage
  images?: GatsbyCustomImage[]
  imageLink?: ResolvedLink
  mobileHidden?: boolean
  mobileReverse?: boolean
  onClickImage?: (imageLink?: ResolvedLink) => void
  oneThird?: boolean
  reverse?: boolean
  width?: string
}

const styles = {
  width: {
    image: {
      225: tw`w-full h-peta md:w-[220px] md:h-auto`,
      300: tw`w-full h-peta md:w-[300px] md:h-auto`,
    },
    content: {
      225: tw`w-full md:w-[420px]`,
      300: tw`w-full md:w-auto `,
    },
  },
}

const Wrapper = styledWithInvalidProps("div")(({ reverse, mobileReverse }) => [
  tw`flex w-full`,
  reverse ? tw`md:flex-row-reverse` : tw`md:flex-row`,
  mobileReverse ? tw`flex-col-reverse` : tw`flex-col`,
])

const ImageWrapper = styledWithInvalidProps("div")(({ mobileHidden, oneThird, width }) => [
  tw`relative w-full`,
  mobileHidden ? tw`hidden md:block` : null,
  width && styles.width.image[width] ? styles.width.image[width] : oneThird ? tw`md:w-2/5` : tw`flex-1 md:min-w-1/2 md:w-1/2`,
])

const ImageInner = tw.div`
  absolute inset-0  flex flex-row md:flex-col
`
const ImageGrid = tw.div`
w-full md:h-1/2
`
const ContentWrapper = styledWithInvalidProps("div")(({ oneThird, width }) => [
  tw`w-full`,
  width && styles.width.content[width] ? styles.width.content[width] : oneThird ? tw`md:w-3/5` : tw`flex-1 md:min-w-1/2 md:w-1/2`,
])

const ImageContent = ({
  image,
  images,
  children,
  reverse,
  mobileReverse,
  mobileHidden,
  oneThird,
  width,
  imageLink,
  onClickImage,
}: ImageContentProps) => {
  return (
    <Wrapper reverse={reverse} mobileReverse={mobileReverse}>
      {image ? (
        <ImageWrapper mobileHidden={mobileHidden} oneThird={oneThird} width={width}>
          <Image image={image} onClick={onClickImage} imageLink={imageLink} alt={image?.alt} fullHeight />
        </ImageWrapper>
      ) : images ? (
        <ImageWrapper mobileHidden={mobileHidden} oneThird={oneThird} width={width}>
          <ImageInner>
            {images.map((img, index) => (
              <ImageGrid key={`${index}_${img?.alt}`}>
                <Image image={img} fullHeight />
              </ImageGrid>
            ))}
          </ImageInner>
        </ImageWrapper>
      ) : null}
      <ContentWrapper oneThird={oneThird} width={width}>
        {children}
      </ContentWrapper>
    </Wrapper>
  )
}

export default ImageContent
