import { withFocusRing } from "@components/Accessibility/FocusRing/FocusRing"
import { VisuallyHidden } from "@components/Accessibility/VisuallyHidden/VisuallyHidden"
import FormInput from "@components/Form/FormInput"
import { Icon } from "@components/Icon"
import { Link } from "@components/Link/Link"
import ModalsPopup from "@components/Modals/ModalsPopup"
import { StyledButton } from "@components/Styled/Button"
import { UnderlineTextSmall as StyledUnderlineTextSmall } from "@components/Styled/Text"
import React from "react"
import tw, { styled } from "twin.macro"
import ImageContent from "../ImageContent"
import { SubscribeFormOutputProps, withSubscribeForm } from "./withSubscribeForm"

const Container = tw.div`
  flex flex-col items-center justify-between pt-maxi pb-mini px-mini md:px-maxi
`
const SuccessContent = tw.div`
  min-h-[360px] flex flex-col justify-center items-center md:min-h-[436px]
`
const Title = styled.h3`
  font-display max-w-exa text-h3 leading-h3 text-center md:text-[32px] md:mb-mini md:max-w-full md:max-w-[250px]
`
const Description = styled.div`
  ${tw`mb-micro`}
  .rte {
    p {
      ${tw`pb-0 text-center text-hint md:text-body`}
    }
  }
`
const OptionsWrapper = tw.div`
  flex items-center flex-col mb-midi md:mb-maxi
`
const OptionsTitle = tw.div`
  text-hint flex leading-body mb-mini md:text-body
`
const Options = tw.div`
  flex flex-row text-hint leading-h1 md:text-body
`
const Option = withFocusRing(tw.button`
  mx-micro flex flex-row items-center
`)
const OptionTitle = tw.span`
  pl-pico text-hint leading-h1 pb-nano mt-nano ml-nano md:text-body
`
const Form = tw.form`
  flex flex-col items-start justify-between px-mini w-full mb-mini
`
const Input = withFocusRing(styled(FormInput)`
  ${tw`flex-1 w-full p-mini mb-mini`} ::placeholder {
    ${tw`text-primary`}
  }
`)
const ErrorMessage = tw.div`
  text-caption mb-mini text-warning
`
const ErrorInterestedInIsEmpty = tw.div`
  text-caption mb-mini text-warning text-center
`
const Terms = styled.div`
  ${tw`max-w-[200px] w-full text-center text-caption text-primary leading-caption md:max-w-[272px]`}
  a {
    text-decoration: underline;
  }
`
const Button = styled(StyledButton)`
  ${tw`w-full`}
`
const Footer = tw.div`
  text-primary text-[13px] h-[56px] w-full border-t border-grey hidden justify-center items-center md:flex hover:text-secondary
`
const FooterText = tw.div`
  cursor-pointer
`
const UnderlineTextSmall = tw(StyledUnderlineTextSmall)`
  ml-mini
`
const Required = tw.div`
  text-warning pl-micro
`

const SubscribeFormPopup = withSubscribeForm(
  ({
    active,
    data,
    emailContent,
    emailsInterestedInNotSetupMessage,
    emailTitle,
    errorInterestedInIsEmpty,
    errors,
    handleChange,
    handleModalToggle,
    handleSubmit,
    handleSuccessButtonClick,
    images,
    loading,
    options,
    success,
    successButton,
    successMessage,
    successTitle,
    tags,
    terms,
    toggleTag,
  }: SubscribeFormOutputProps) => (
    <ModalsPopup layout="subscribe" active={active} setActive={handleModalToggle}>
      <ImageContent images={images} width={"225"} mobileReverse mobileHidden={!success}>
        {!success ? (
          <>
            <Container>
              <Title>{emailTitle}</Title>
              <Description>
                <div className="rte">{emailContent}</div>
              </Description>
              {options && (
                <OptionsWrapper>
                  <OptionsTitle>
                    I am interested in<Required aria-hidden="true">*</Required>
                    <VisuallyHidden>required</VisuallyHidden>
                  </OptionsTitle>
                  <Options>
                    {options?.map((option, index) => (
                      <Option
                        aria-label={`I am interested in: ${option?.title}`}
                        key={`${option?.title}-${index}`}
                        onClick={() => toggleTag(option?.tag)}
                      >
                        {tags.indexOf(option?.tag) === -1 ? <Icon name="tickedBoxInactive" size="xxs" /> : <Icon name="tickedBoxActive" size="xxs" />}
                        <OptionTitle>{option?.title}</OptionTitle>
                      </Option>
                    ))}
                  </Options>
                </OptionsWrapper>
              )}
              {errorInterestedInIsEmpty && <ErrorInterestedInIsEmpty>{emailsInterestedInNotSetupMessage}</ErrorInterestedInIsEmpty>}
              <Form onSubmit={handleSubmit}>
                <Input name="email" label="Email" id="email-popup" type="email" handleChange={handleChange} required data-testid={"email-input"} />
                <Input name="phone" label="Mobile" id="phone-popup" type="phone" value={data?.phone} handleChange={handleChange} optional />
                <Button colour="primary" layout="primary" height="56" textSize="16" type="submit">
                  {!loading ? "Subscribe" : "Subscribing..."}
                </Button>
              </Form>
              {errors?.length > 0 &&
                errors?.map(({ code, message }) => (
                  <ErrorMessage key={code}>
                    {message === "Email has already been taken" ? "This email address is already subscribed." : message}
                  </ErrorMessage>
                ))}
              <Terms>{terms}</Terms>
            </Container>
            <Footer>
              <UnderlineTextSmall>
                <FooterText onClick={handleModalToggle}>{`No thanks, continue shopping.`}</FooterText>
              </UnderlineTextSmall>
            </Footer>
          </>
        ) : (
          <Container>
            <SuccessContent>
              <Title>{successTitle}</Title>
              <Description>
                <div className="rte">{successMessage}</div>
              </Description>
              <Button
                colour="primary"
                layout="primary"
                height="56"
                textSize="16"
                as={successButton?.type ? Link : null}
                to={successButton?.url !== "" ? successButton?.url : "/"}
                onClick={handleSuccessButtonClick}
              >
                {successButton?.title}
              </Button>
            </SuccessContent>
          </Container>
        )}
      </ImageContent>
    </ModalsPopup>
  ),
  {
    trackingName: "Popup",
  }
)

export default SubscribeFormPopup
