import React, { useState, useEffect, useMemo, memo, Dispatch, SetStateAction, FormEvent, FC } from "react"

import { useCore } from "@hooks/useCore"
import { useCustomerContext } from "@hooks/useCustomer"
import { useFunctions } from "@hooks/useFunctions"
import { useFunctionsGatsby } from "@hooks/useFunctionsGatsby"
import { useShopify } from "@hooks/useShopify"
import { ComponentProps } from "@ts/components"
import { ProductVariant, UserError } from "@ts/shopify-storefront"

export type BackInStockInputProps = ComponentProps & {
  selectedVariant?: ProductVariant
  active: boolean
  setActive: Dispatch<SetStateAction<boolean>>
}

export type BackInStockOutputProps = BackInStockInputProps & {
  loading: boolean
  sent: boolean
  errors: UserError[] | string[]
  handleSubmit: (event: FormEvent) => Promise<void>
  email: string
  setEmail: Dispatch<SetStateAction<string>>
}

export const withBackInStock = (Component: FC<BackInStockOutputProps>) =>
  memo(({ name = "BackInStock", selectedVariant, active, setActive }: BackInStockInputProps) => {
    const { customerSubscribe } = useFunctions()
    const { backInStock, loading, errors, setErrors } = useFunctionsGatsby()
    const { decodeVariantId } = useShopify()
    const { customer } = useCustomerContext()
    const {
      helpers: { decodeBase64 },
    } = useCore()
    const [email, setEmail] = useState("")
    const [sent, setSent] = useState(false)

    const handleSubmit = async (event: FormEvent) => {
      event.preventDefault()
      const { status, body } = await backInStock(email, decodeVariantId(decodeBase64(selectedVariant?.id)))

      if (status === "success") {
        await customerSubscribe(
          email,
          ["BIS"],
          true,
          customer?.smsMarketingConsent,
          customer?.id ? decodeBase64(customer?.id) : null,
          customer?.email
        )
        setSent(true)
      } else {
        setErrors([body])
        setSent(false)
      }
    }

    useEffect(() => {
      if (customer && !email) {
        setEmail(customer.email)
      }
    }, [customer])

    Component.displayName = name

    return useMemo(
      () => (
        <Component
          loading={loading}
          sent={sent}
          errors={errors}
          email={email}
          setEmail={setEmail}
          handleSubmit={handleSubmit}
          active={active}
          setActive={setActive}
        />
      ),
      [active, customer, email, errors, sent]
    )
  })
