import React, { FC, memo } from "react"
import { useSettings } from "../../hooks/useSettings"
import { ComponentProps } from "../../types/components"
import { SettingSocial } from "../../types/sanity"

export type FooterSocialLinksInputProps = ComponentProps
export type FooterSocialLinksOutputProps = SettingSocial

export const withFooterSocialLinks = (Component: FC<FooterSocialLinksOutputProps>) =>
  memo(({ name = "FooterSocialLinks" }: FooterSocialLinksInputProps) => {
    const { social } = useSettings()

    Component.displayName = name
    return <Component {...social} />
  })
