import { withFocusRing } from "@components/Accessibility/FocusRing/FocusRing"
import { SearchResultsArticles } from "@components/Search/Articles/SearchResultsArticles"
import { SearchResultsPages } from "@components/Search/Pages/SearchResultsPages"
import { SearchResultSanity } from "@ts/search"
import { Results as ReactifySearchResults, Stats } from "@usereactify/search"
import React from "react"
import ScrollLock, { TouchScrollable } from "react-scrolllock"
import tw, { styled, theme } from "twin.macro"
import { SearchFormEmptyResults } from "./SearchFormEmptyResults"
import { SearchFormResultsProduct } from "./SearchFormResultsProduct"
import { withSearchFormResults } from "./withSearchFormResults"

const BgContainer = tw.div`
  fixed top-9 left-0 right-0 bottom-0 bg-secondary opacity-50 cursor-pointer md:top-[122px]
`
const Container = styled.div`
  ${tw`fixed bottom-0 left-0 flex flex-row w-full overflow-x-hidden overflow-y-auto bg-white border-t opacity-100 z-100 touch-pan-y top-9 text-primary shadow-header border-tertiary md:min-h-auto md:top-[122px]`}
  min-height: calc(100% - ${theme("spacing.11")});
`
const Wrapper = tw.div`
  flex flex-col w-full bg-white
`
const WrapperInner = tw.div`
  flex flex-row w-full max-w-2xl mx-auto
`
const Results = tw.div`
  w-full md:w-full
`
const HeadingWrapper = tw.div`
  flex px-midi pb-midi justify-between pt-midi md:pt-giga md:px-maxi
`
const Heading = tw.div`
  font-body text-[13px] leading-double tracking-heading uppercase
`
const SeeAll = withFocusRing(tw.button`leading-h4 underline cursor-pointer hover:text-secondary`)
const SanityWrapper = tw.div`
  w-full h-full bg-neutral md:h-auto
`
const SanityContainer = tw.div`
  flex flex-col bg-neutral w-full items-start pb-mega md:flex-row md:px-maxi md:pt-6
`
const PagesWrapper = tw.div`
  w-full p-midi md:p-0 md:pr-midi md:w-1/4
`
const ArticlesWrapper = tw.div`
  w-full md:w-3/4
`

export const SearchFormResultsSanity = ({ sanitySearchResults }: { sanitySearchResults: SearchResultSanity[] }) => {
  if (!sanitySearchResults || sanitySearchResults.length === 0) {
    return null
  }

  return (
    <SanityWrapper>
      <WrapperInner>
        <SanityContainer>
          <SearchResultsPages data={sanitySearchResults} wrapper={PagesWrapper} />
          <SearchResultsArticles data={sanitySearchResults} wrapper={ArticlesWrapper} />
        </SanityContainer>
      </WrapperInner>
    </SanityWrapper>
  )
}

export const SearchFormResults = withSearchFormResults(
  ({ searchTerm, handleClick, active, handleSearchClose, additionalTopRelatedProducts, sanitySearchResults }) => {
    if (!active) {
      return null
    }

    return (
      <>
        <BgContainer onClick={handleSearchClose} />
        {searchTerm ? (
          <Container>
            <ScrollLock />
            <TouchScrollable>
              <Wrapper>
                <WrapperInner>
                  <Results>
                    <Stats
                      render={renderProps =>
                        renderProps.resultStats.numberOfResults ? (
                          <HeadingWrapper>
                            <Heading>{additionalTopRelatedProducts}</Heading>
                            <SeeAll onClick={handleClick}>{`View all`}</SeeAll>
                          </HeadingWrapper>
                        ) : null
                      }
                    />
                    <ReactifySearchResults
                      includeFields={["*"]}
                      pageSize={6}
                      listStyle={{}}
                      listClassName="flex flex-wrap px-midi md:px-maxi pb-midi -mx-mini md:pb-mini"
                      renderLoading={() => null}
                      renderNoResults={SearchFormEmptyResults}
                      renderResultCardCallout={() => null}
                      renderResultCardProduct={renderProps => (
                        <SearchFormResultsProduct
                          data-testid="search-results-product"
                          index={renderProps.pagePosition}
                          item={renderProps.product}
                          handleSearchClose={handleSearchClose}
                        />
                      )}
                    />
                  </Results>
                </WrapperInner>
                <SearchFormResultsSanity sanitySearchResults={sanitySearchResults} />
              </Wrapper>
            </TouchScrollable>
          </Container>
        ) : null}
      </>
    )
  }
)
