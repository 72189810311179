import { graphql, useStaticQuery } from "gatsby"
import {
  PageAccountAddresses,
  PageAccountDashboard,
  PageAccountGiftcard,
  PageAccountLogin,
  PageAccountLoyalty,
  PageAccountOrders,
  PageAccountPreferences,
  PageAccountRegister,
  PageAccountWishlist,
  PageCart,
  PageContact,
  PageSearch,
} from "../types/sanity"
import { useLocalisation } from "./useLocalisation"

export type UsePage = {
  addresses?: PageAccountAddresses
  cart?: PageCart
  details?: PageAccountDashboard
  giftcard?: PageAccountGiftcard
  orders?: PageAccountOrders
  preferences?: PageAccountPreferences
  login?: PageAccountLogin
  loyalty?: PageAccountLoyalty
  register?: PageAccountRegister
  wishlist?: PageAccountWishlist
  contactPage?: PageContact
  search?: PageSearch
}

export const usePage = (): UsePage => {
  const { addresses, allCart, details, giftcard, orders, preferences, register, login, wishlist, contactPage, search, loyalty } = useStaticQuery(
    graphql`
      query PAGE {
        addresses: sanityPageAccountAddresses {
          title
          additionalAddAddressButton
        }
        allCart: allSanityPageCart {
          edges {
            node {
              i18n_lang
              i18n_base {
                id
              }
              title
              emptyTitle
              freeShippingThreshold
              keepShopping {
                ...InternalLink
              }
              additionalCustomerNotePlaceholder
              noteTitle
              noteDescription
              noteOption
              donationEnabled
              donationProducts: _rawDonationProducts(resolveReferences: { maxDepth: 1 })
              saleItemReturnPolicy
              finalSaleItemReturnPolicy
              upsellsEnabled
              upsellsProducts {
                products {
                  shopify {
                    shopifyActive
                    shopifyHandle
                  }
                }
                tags {
                  _key
                  _type
                  value
                  label
                }
              }
              upsellsTitle
              upsellsMaximum
            }
          }
        }
        details: sanityPageAccountDashboard {
          title
          referenceList: _rawReferenceList(resolveReferences: { maxDepth: 2 })
          healthProfessionalKey
          referringByHealthProfessionalKey
          content: _rawContent(resolveReferences: { maxDepth: 6 })
          preferencesList {
            value
            label
          }
          emailFrequency: _rawEmailFrequency(resolveReferences: { maxDepth: 6 })
          frequencyList {
            value
            label
          }
          unsubscribeContent: _rawUnsubscribeContent(resolveReferences: { maxDepth: 6 })
          unsubscribeOption
          updatePreferencesButton
          additionalEditButton
          additionalCancelButton
          additionalSaveButton
          additionalSavingButton
          additionalFirstName
          additionalLastName
          additionalEmail
          additionalPhone
          additionalPassword
          additionalBirthdayDay
          additionalBirthdayMonth
          additionalBirthdayYear
          additionalReference
          additionalReferringHealthProfessional
          additionalReferringClinic
          additionalProfessional
          additionalProfessionalType
          additionalProfessionalClinic
          additionalProfessionalAddress
          additionalProfessionalCity
          additionalProfessionalState
          additionalProfessionalPostcode
          additionalProfessionalCountry
          additionalProfessionalAccreditation
          additionalLoginAgainButton
          additionalPasswordSuccessTitle
          additionalPasswordSuccessMessage
          membershipValidationRules {
            country
            name
            validationExpression
            errorMessage
          }
        }
        giftcard: sanityPageAccountGiftcard {
          title
          additionalFormTitle
          additionalFormDescription
        }
        orders: sanityPageAccountOrders {
          title
          ordersDescription: _rawOrdersDescription(resolveReferences: { maxDepth: 1 })
          returnInfo: _rawReturnInfo(resolveReferences: { maxDepth: 1 })
        }
        preferences: sanityPageAccountPreferences {
          title
          additionalCurrentStatus
          additionalIsVip
          additionalNotVip
          additionalTimeFrame
          additionalMinimumSpent
          additionalLoading
          additionalMinimumSpentMaintain
          additionalText: _rawAdditionalText(resolveReferences: { maxDepth: 3 })
        }
        login: sanityPageAccountLogin {
          title
          additionalSubmitButton
          additionalPasswordErrorMessage
          forgot: _rawForgot(resolveReferences: { maxDepth: 1 })
        }
        register: sanityPageAccountRegister {
          title
          additionalSubmitButton
          additionalMarketing
          additionalSMS
          additionalSMSTerms: _rawAdditionalSmsTerms(resolveReferences: { maxDepth: 2 })
          additionalEmailAddressErrorMessage
          terms: _rawTerms(resolveReferences: { maxDepth: 2 })
          privacy: _rawPrivacy(resolveReferences: { maxDepth: 2 })
          additionalTermsAndConditions
        }
        wishlist: sanityPageAccountWishlist {
          title
          flexibleSections: _rawFlexibleSections(resolveReferences: { maxDepth: 12 })
          termsAndConditions: _rawTermsAndConditions(resolveReferences: { maxDepth: 12 })
        }
        contactPage: sanityPageContact {
          additionalSubmitButton
          additionalSubmitingButton
          additionalSuccessMessage
        }
        search: sanityPageSearch {
          trending: _rawTrending(resolveReferences: { maxDepth: 4 })
          popularCollection: _rawPopularCollection(resolveReferences: { maxDepth: 4 })
          additionalTrending
          additionalTopSuggestions
          additionalTopRelatedProducts
          articleAnchorLinkText
          articleCategoryFallbackText
          articleResultsHeading
          noResultsFound: _rawNoResultsFound(resolveReferences: { maxDepth: 4 })
          pageAnchorLinkText
          pageResultsHeading
        }
        loyalty: sanityPageAccountLoyalty {
          terms: _rawTerms(resolveReferences: { maxDepth: 4 })
          description: _rawDescription(resolveReferences: { maxDepth: 4 })
          rewardsDescription: _rawRewardsDescription(resolveReferences: { maxDepth: 4 })
          shareButtonText
          rewardsTitle
          footerImage {
            asset {
              url
            }
          }
        }
      }
    `
  )

  const { findNodeByLocale } = useLocalisation()
  const cart = findNodeByLocale<PageCart>(allCart)

  return {
    addresses,
    cart,
    details,
    giftcard,
    orders,
    preferences,
    register,
    login,
    wishlist,
    contactPage,
    search,
    loyalty,
  }
}
