import React, { FC } from "react"
import { useRoutes } from "../../hooks/useRoutes"
import { useImage } from "../../hooks/useImage"
import { ComponentProps, GatsbyCustomImage, ResolvedLink } from "../../types/components"
import { ResponsiveImages } from "../../types/sanity"
import { ThemeAspectRatio } from "../../types/theme"

export type NavigationHeaderSubImageInputProps = ComponentProps & {
  image?: ResponsiveImages
}

export type NavigationHeaderSubImageOutputProps = NavigationHeaderSubImageInputProps & {
  desktopImage?: GatsbyCustomImage
  desktopAspectRatio?: "none" | Extract<keyof ThemeAspectRatio, string>
  desktopImageLink?: ResolvedLink
  mobileImage?: GatsbyCustomImage
  mobileAspectRatio?: "none" | Extract<keyof ThemeAspectRatio, string>
  mobileImageLink?: ResolvedLink
  dataTestId?: string
  title?: string
  heading?: string
}

export const withNavigationHeaderSubImage =
  (Component: FC<NavigationHeaderSubImageOutputProps>) =>
  ({ name = "NavigationHeaderSubImages", image, "data-testid": dataTestId }: NavigationHeaderSubImageInputProps) => {
    const { linkResolver } = useRoutes()
    const { getFluidImage } = useImage()

    const desktopImage = getFluidImage(image?.desktopImage?.image, { width: 500 })
    const mobileImage = getFluidImage(image?.mobileImage?.image, { width: 500 })
    const desktopAspectRatio = image?.desktopAspectRatio?.aspectRatio
    const mobileAspectRatio = image?.mobileAspectRatio?.aspectRatio
    const desktopImageLink = linkResolver(image?.desktopImage?.link)
    const mobileImageLink = linkResolver(image?.mobileImage?.link)

    Component.displayName = name
    return (
      <Component
        title={image?.title}
        heading={image?.heading}
        desktopAspectRatio={desktopAspectRatio}
        desktopImage={desktopImage}
        desktopImageLink={desktopImageLink}
        mobileAspectRatio={mobileAspectRatio}
        mobileImage={mobileImage}
        mobileImageLink={mobileImageLink}
        dataTestId={dataTestId}
      />
    )
  }
