import { useNavigation, type NormalisedNavigation } from "@hooks/useNavigation"
import { type ComponentProps } from "@ts/components"
import React, { useMemo, type FC } from "react"

type NavigationTopBarOutputProps = {
  items: NormalisedNavigation[]
}

export const withNavigationTopBar = (Component: FC<NavigationTopBarOutputProps>) =>
  React.memo(({ name = "NavigationTopBar" }: ComponentProps) => {
    const {
      topBar: { items },
    } = useNavigation()

    Component.displayName = name
    return useMemo(() => <Component items={items} />, [items])
  })
