import * as Sentry from "@sentry/gatsby"

Sentry.init({
  dsn: "https://b89990190cb64ea1ade61f2d3848c82f@o471455.ingest.sentry.io/6592977",
  environment: process.env.GATSBY_SENTRY_ENVIRONMENT || "development",
  tracesSampleRate: 0.2,
  replaysSessionSampleRate: 0.05,
  replaysOnErrorSampleRate: 0.05,
  sampleRate: 0.5,
  integrations: [
    new Sentry.Replay({
      maskAllInputs: true,
      maskAllText: false,
    }),
  ],
})
