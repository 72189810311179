import React from "react"
import tw from "twin.macro"

const Container = tw.div`
  flex
`
const Wrapper = tw.div`
  flex flex-col md:flex-row w-full bg-white
`
const Title = tw.div`
  text-body capitalize cursor-pointer
`
const Results = tw.div`
  w-full md:w-5/6 md:pb-4
`
const HeadingWrapper = tw.div`
  flex px-midi md:px-maxi pt-midi md:pt-giga pb-midi justify-between
`

export const SearchFormEmptyResults = () => (
  <Container data-testid="header-search-desktop-no-results">
    <Wrapper>
      <Results>
        <HeadingWrapper>
          <Title>{`No products found`}</Title>
        </HeadingWrapper>
      </Results>
    </Wrapper>
  </Container>
)
