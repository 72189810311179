import { withFocusRing } from "@components/Accessibility/FocusRing/FocusRing"
import FormDropdown from "@components/Form/FormDropdown"
import { Icon } from "@components/Icon"
import { Image } from "@components/Image/Image"
import { Link } from "@components/Link/Link"
import Price from "@components/Price/Price"
import AddToCart from "@components/Product/AddToCart/AddToCart"
import { styledWithInvalidProps } from "@components/Styled/StyledWithInvalidProps"
import React from "react"
import tw, { styled } from "twin.macro"
import { CartUpsellsCardOutputProps, withCartUpsellsCard } from "./withCartUpsellsCard"

const Wrapper = styledWithInvalidProps("div")(({ isLast }) => [tw`flex px-mini py-midi lg:p-mini`, isLast ? null : tw`border-b border-tertiary`])

const ImageWrapper = tw.div`
  flex w-[90px] h-[96px] mr-mini
`
const InfoWrapper = tw.div`
  flex w-full justify-between mb-mini
`
const Body = tw.div`
  flex-1 flex flex-col w-full
`
const Title = withFocusRing(tw.a`inline cursor-pointer md:pr-mega`)
const Text = tw.span`
  inline capitalize leading-caption text-[13px]
`
const Bold = tw(Text)`
  font-medium
`
const ItemPrice = tw(Price)`leading-caption`
const Selectors = tw.div`
  w-full flex flex-row items-end mb-mini
`
const Selector = tw.div`
  min-w-9 flex flex-col
`
const SizeSelector = styled(FormDropdown)`
  ${tw`cursor-pointer`}
`
const QuantityAmount = tw.p`
  text-hint leading-caption
`
const QuantityButton = withFocusRing(tw.button`
  p-micro w-midi justify-center bg-primary flex items-center transition-opacity hover:opacity-50
`)
const StyledIcon = tw(Icon)`text-white`
const QuantitySelector = tw.div`
  border border-quaternary flex overflow-hidden justify-between items-center w-[106px] h-midi md:w-[96px]
`

export const CartUpsellsCard = withCartUpsellsCard(
  ({
    compareAtPrice,
    image,
    link,
    onChangeVariant,
    onUpdateQuantity,
    options,
    price,
    tags,
    quantity,
    selectedVariant,
    singleVariant,
    subtitle,
    title,
    isLast,
  }: CartUpsellsCardOutputProps) => (
    <Wrapper isLast={isLast}>
      <ImageWrapper>
        <Image alt={title} image={image} ratio="11/12" maxWidth={150} />
      </ImageWrapper>
      <Body>
        <InfoWrapper>
          <Title as={Link} to={link}>
            <Bold>{title}</Bold>
            <Text>{subtitle}</Text>
          </Title>
          <ItemPrice price={price} compareAtPrice={compareAtPrice} layout="cart" />
        </InfoWrapper>
        <Selectors>
          <Selector>
            <QuantitySelector>
              <QuantityButton data-testid="cart-upsells-quantity-minus" disabled={quantity <= 1} onClick={onUpdateQuantity(-1)}>
                <StyledIcon name="minusSmall" size="none" height="24" width="8" />
              </QuantityButton>
              <QuantityAmount data-testid="cart-upsells-quantity-amount">{quantity}</QuantityAmount>
              <QuantityButton data-testid="cart-upsells-quantity-plus" onClick={onUpdateQuantity(1)}>
                <StyledIcon name="plusSmall" size="none" height="24" width="8" />
              </QuantityButton>
            </QuantitySelector>
          </Selector>
          {!singleVariant && (
            <Selector>
              <SizeSelector name={"upsells-sizeSelector"} options={options} handleChange={onChangeVariant} layout="upsell" placeholder="Size" />
            </Selector>
          )}
        </Selectors>
        <AddToCart data-testid="add-to-cart-upsell" tags={tags} selectedQuantity={quantity} selectedVariant={selectedVariant} cart={true} />
      </Body>
    </Wrapper>
  )
)
