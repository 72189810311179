import { withFocusRing } from "@components/Accessibility/FocusRing/FocusRing"
import { Link } from "@components/Link/Link"
import { styledWithInvalidProps } from "@components/Styled/StyledWithInvalidProps"
import React from "react"
import tw, { styled } from "twin.macro"
import { Icon } from "../Icon"
import { AccordionAnimation } from "./AccordionAnimation"
import { AccordionOutputProps, withAccordion } from "./withAccordion"

const styles = {
  border: {
    header: tw`border-t border-tertiary px-midi`,
    footer: tw`border-b border-grey px-midi`,
    account: tw`border-t border-grey px-midi`,
    accountNavigation: tw``,
    filter: tw`border-b border-grey`,
    pdp: tw`border-b border-grey pr-mini`,
    product: tw``,
    blog: tw``,
    minicart: tw``,
  },
  layout: {
    header: tw`h-tera justify-between`,
    footer: tw`h-tera justify-between`,
    account: tw`h-tera justify-between`,
    accountNavigation: tw`h-mega justify-between`,
    filter: tw`h-giga justify-between items-center`,
    pdp: tw`h-giga justify-between items-center`,
    product: tw`h-mega justify-start`,
    blog: tw`h-mega justify-start`,
    minicart: tw`h-giga justify-between px-midi bg-primary bg-opacity-10`,
  },
  size: {
    header: tw`text-h2 leading-h3 font-medium font-display text-secondary`,
    footer: tw`text-hint leading-body uppercase !font-medium tracking-heading`,
    account: tw`text-body`,
    accountNavigation: tw`text-body text-left text-primary xl:text-[18px]`,
    filter: tw`text-body`,
    pdp: tw`text-body text-primary`,
    product: tw`text-body leading-triple font-medium w-full text-left`,
    blog: tw`text-body leading-triple font-medium w-full text-primary`,
    minicart: tw`text-[18px] leading-h3 font-medium font-display text-primary`,
  },
  list: {
    account: tw`pb-mega`,
    minitcart: tw`bg-neutral bg-opacity-60`,
  },
}

const Wrapper = styled.div`
  ${tw`w-full`}
  ${({ size }) => (size ? styles.border[size] : null)}
`
const TitleWrapper = withFocusRing(styled.button`
  ${tw`w-full flex items-center`}
  ${({ size }) => (size ? styles.layout[size] : null)}
`)
const Title = styledWithInvalidProps("h3")(({ active, size, highlight }) => [
  size ? styles.size[size] : null,
  size === "header" && (active || highlight) ? tw`text-primary` : null,
  size === "pdp" && active ? tw`font-medium` : tw`font-normal`,
])

const List = styled.div`
  ${({ size }) => (size ? styles.list[size] : null)}
`

const StyledIcon = tw(Icon)`text-primary`

export const Accordion = withAccordion(
  ({
    active,
    hightlight,
    title,
    children,
    size,
    link,
    icon,
    handleActive,
    handleRedirect,
    hash,
    "data-testid": dataTestId,
  }: AccordionOutputProps) => {
    return (
      <Wrapper size={size}>
        <TitleWrapper size={size} onClick={handleActive} data-testid={dataTestId} aria-controls={hash} aria-expanded={active} id={`trigger-${hash}`}>
          {size === `product` || size === `blog` ? (
            <Title size={size}>{active ? `${title} -` : `${title} +`}</Title>
          ) : icon ? (
            <>
              <Title size={size} active={active} highlight={hightlight}>
                {title}
              </Title>
              {!active ? <StyledIcon name="plus" size="xxs" /> : <StyledIcon name="minus" size="xxs" />}
            </>
          ) : (
            <Title as={Link} size={size} to={link} onClick={handleRedirect} highlight={hightlight}>
              {title}
            </Title>
          )}
        </TitleWrapper>
        <AccordionAnimation active={active} aria-hidden={!active} id={hash}>
          <List size={size}>{children}</List>
        </AccordionAnimation>
      </Wrapper>
    )
  }
)
