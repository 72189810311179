import { withFocusRing } from "@components/Accessibility/FocusRing/FocusRing"
import { Icon } from "@components/Icon"
import { SocialMedia } from "@ts/sanity"
import React from "react"
import tw from "twin.macro"
import { FooterSocialLinksOutputProps, withFooterSocialLinks } from "./withFooterSocialLinks"

const List = tw.div`
  w-full pt-0 md:pt-midi md:relative
`
const ListItem = tw.div`
  w-full flex flex-row flex-nowrap justify-center md:-ml-micro md:justify-start md:relative md:bottom-midi
`
const IconWrapper = withFocusRing(tw.a`
  flex flex-nowrap items-center -ml-micro justify-center block w-[52px] h-tera focus-visible:!outline-offset-[-5px]
`)
const StyledIcon = tw(Icon)`transition-presentation hover:text-secondary`

const RenderLinks = (items: SocialMedia[]) => (
  <ListItem>
    {items?.map(item => (
      <IconWrapper href={item?.url} key={item?.title} target={"_blank"} title={item?.title} rel="noopener">
        <StyledIcon name={item?.title.toLowerCase()} />
      </IconWrapper>
    ))}
  </ListItem>
)

export const FooterSocialLinks = withFooterSocialLinks(({ channels }: FooterSocialLinksOutputProps) => <List>{RenderLinks(channels)}</List>)
