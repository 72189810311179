import { Global, css } from "@emotion/react"
import React, { useCallback, useMemo } from "react"
import { ErrorBoundary as BaseErrorBoundary } from "react-error-boundary"
import tw from "twin.macro"

import { Icon } from "@components/Icon"
import { useFeatureToggle } from "@hooks/useFeatureToggle"

const Container = tw.div`flex flex-col items-center justify-center p-midi md:p-mega md:h-full-vh`

const Heading = tw.h1`mt-midi font-display text-h4 leading-h1 md:text-[32px] text-primary text-center`

const Content = tw.p`mt-micro mb-midi text-primary font-light text-hint text-center`

const Button = tw.button`py-mini px-mega bg-primary text-neutral text-hint font-light hover:opacity-80 transition-layout duration-slow focus-visible:!outline-warning`

const ErrorBoundaryContent: React.FC = () => {
  const handleRefresh = useCallback(async () => {
    // Unregister service workers.
    const serviceWorkerRegistrations = await navigator.serviceWorker.getRegistrations()
    await Promise.all(serviceWorkerRegistrations.map(serviceWorkerRegistration => serviceWorkerRegistration.unregister()))

    // Clear browser cache keys.
    const cacheKeys = await caches.keys()
    await Promise.all(cacheKeys.map(cacheKey => caches.delete(cacheKey)))

    // Reload the page.
    window.location.reload()
  }, [])

  return (
    <Container>
      <Global
        styles={css`
          body {
            background: rgb(244, 241, 238);
          }
        `}
      />
      <Icon name="logo" size="large" />
      <Heading>Sorry, there was a problem loading the page.</Heading>
      <Content>Please try refreshing your browser using the button below.</Content>
      <Button onClick={handleRefresh}>Refresh page</Button>
    </Container>
  )
}

export const ErrorBoundary: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { percentageToggle } = useFeatureToggle()

  // enable error boundary for % of users
  const enableBoundary = useMemo(() => percentageToggle("error-boundary", process.env.GATSBY_FEATURE_ERROR_BOUNDARY), [percentageToggle])
  if (!enableBoundary) return <>{children}</>

  return <BaseErrorBoundary FallbackComponent={ErrorBoundaryContent}>{children}</BaseErrorBoundary>
}
