import { withFocusRing } from "@components/Accessibility/FocusRing/FocusRing"
import { ThemeColors } from "@ts/theme"
import tw, { TwStyle } from "twin.macro"
import { styledWithInvalidProps } from "./StyledWithInvalidProps"

export type StyledButtonProps = {
  textSize?: "14" | "16" | "16-14" | "20" | "28-20" | "32"
  height?: "32" | "48-32" | "40" | "48" | "48-40" | "56" | "56-40" | "56-34" | "64-56" | "72-56" | "88" | "88-56"
  colour?: Extract<
    keyof ThemeColors,
    "transparent" | "light" | "orange" | "green" | "charcoal" | "primary" | "tertiary" | "secondary" | "lightest" | "white"
  >
  layout?: "default" | "secondary"
  icon?: boolean
  disabled?: boolean
  border?: boolean
}

type StyleKeyValuePair = {
  [key: string]: TwStyle
}

type Styles = {
  textSize: StyleKeyValuePair
  height: StyleKeyValuePair
  colour: {
    default: StyleKeyValuePair
    secondary: StyleKeyValuePair
  }
  disabled: TwStyle
  icon: TwStyle
  border: TwStyle
}

const styles: Styles = {
  textSize: {
    "14": tw`text-hint`,
    "16": tw`text-body`,
    "16-14": tw`text-hint sm:text-body`,
    "20": tw`text-h4`,
    "28-20": tw`text-h4 md:text-h2 font-display leading-input`,
    "32": tw`text-h4 sm:text-[32px] font-display`,
  },
  height: {
    "32": tw`h-maxi`,
    "48-32": tw`h-maxi sm:h-mega`,
    "40": tw`h-[40px]`,
    "48": tw`h-mega`,
    "48-40": tw`h-[40px] md:h-mega`,
    "50": tw`h-[50px]`,
    "56": tw`h-[56px]`,
    "56-40": tw`h-mega sm:h-[56px]`,
    "56-34": tw`h-[34px] sm:h-[56px]`,
    "64-56": tw`h-[56px] md:h-giga`,
    "72-56": tw`h-[56px] sm:h-[72px]`,
    "72": tw`h-[72px]`,
    "88": tw`h-[88px]`,
    "88-56": tw`h-[56px] md:h-[88px]`,
  },
  colour: {
    default: {
      primary: tw`bg-primary text-neutral hover:bg-secondary hover:opacity-100 transition-layout duration-slow focus-visible:!outline-primary`,
      secondary: tw`bg-secondary text-neutral hover:opacity-80 transition-layout duration-slow focus-visible:!outline-secondary`,
      tertiary: tw`bg-tertiary text-primary hover:opacity-80 transition-layout duration-slow focus-visible:!outline-tertiary`,
      transparent: tw`bg-transparent text-primary hover:text-neutral hover:bg-secondary transition-presentation`,
      warning: tw`bg-warning text-neutral hover:opacity-80 transition-layout duration-slow focus-visible:!outline-warning`,
      // Deprecated
      light: tw`bg-neutral text-primary sm:hover:text-neutral sm:hover:bg-secondary transition-presentation duration-slow`,
      orange: tw`bg-warning text-neutral hover:opacity-80 transition-layout duration-slow focus-visible:!outline-warning`,
      lightest: tw`bg-white text-primary hover:text-neutral border-secondary hover:bg-secondary transition-presentation focus-visible:!outline-white`,
      white: tw`bg-white text-primary hover:text-neutral border border-secondary transition-presentation hover:bg-secondary hover:border-white focus-visible:!outline-white`,
      charcoal: tw`bg-primary text-neutral hover:opacity-80 transition-layout duration-slow focus-visible:!outline-primary`,
      green: tw`bg-secondary text-neutral hover:opacity-80 transition-layout duration-slow focus-visible:!outline-secondary`,
      beige: tw`bg-tertiary text-primary hover:opacity-80 transition-layout duration-slow focus-visible:!outline-tertiary`,
    },
    secondary: {
      primary: tw`bg-transparent border border-primary text-primary hover:bg-secondary hover:text-white hover:border-secondary transition-layout duration-slow`,
      secondary: tw`bg-transparent border text-secondary hover:opacity-80 border-secondary transition-layout duration-slow`,
      tertiary: tw`bg-transparent border text-tertiary hover:opacity-80 transition-layout duration-slow`,
      transparent: tw`bg-transparent text-primary hover:text-neutral transition-presentation duration-slow`,
      warning: tw`bg-transparent border text-warning hover:opacity-80 border-warning transition-layout duration-slow`,
      // Deprecated
      orange: tw`bg-transparent border text-warning hover:opacity-80 border-warning transition-layout duration-slow`,
      light: tw`text-white bg-transparent border border-white hover:opacity-80 transition-layout duration-slow`,
      lightest: tw`text-white bg-transparent border border-white hover:opacity-80 transition-layout duration-slow`,
      white: tw`text-white bg-transparent border border-white hover:opacity-80 transition-layout duration-slow`,
      charcoal: tw`bg-transparent border text-primary hover:opacity-80 border-primary transition-layout duration-slow`,
      green: tw`bg-transparent border text-secondary hover:opacity-80 border-secondary transition-layout duration-slow`,
      beige: tw`bg-transparent border text-tertiary hover:opacity-80 transition-layout duration-slow`,
    },
  },
  disabled: tw`cursor-not-allowed pointer-events-none opacity-50`,
  icon: tw`justify-start sm:justify-between pl-midi sm:pl-mega pr-maxi`,
  border: tw`border`,
}

export const StyledButton = withFocusRing(
  styledWithInvalidProps<StyledButtonProps>("button")(({ colour, layout, icon, disabled, height, textSize, border }) => [
    tw`flex flex-row items-center justify-center cursor-pointer transition-presentation`,
    colour && layout && styles.colour[layout] && styles.colour[layout][colour]
      ? styles.colour[layout][colour]
      : colour && styles.colour.default[colour]
        ? styles.colour.default[colour]
        : null,
    icon && styles.icon,
    disabled && styles.disabled,
    height && styles.height[height],
    textSize && styles.textSize[textSize],
    border && styles.border,
  ])
)
