import { useKlaviyo } from "@hooks/useKlaviyo"
import { Customer } from "@ts/shopify-storefront"
import React, { useEffect, useMemo, useState } from "react"

export const CustomerContext: any = React.createContext({})

export const CustomerProvider = ({ children }) => {
  const { identify, track } = useKlaviyo()
  const [customer, setCustomer] = useState<Customer | null>(null)

  const values = useMemo(
    () => ({
      customer,
      setCustomer,
    }),
    [customer, setCustomer]
  )

  useEffect(() => {
    if (customer?.email) {
      identify(customer)
      track("Account Login", customer)
    }
  }, [customer])

  return <CustomerContext.Provider value={values}>{children}</CustomerContext.Provider>
}
