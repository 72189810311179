import * as Sentry from "@sentry/gatsby"
import { type NormalisedProductVariant } from "@ts/components"
import { type Attribute, type CheckoutLineItem } from "@ts/shopify-storefront"
import { useCallback, useState } from "react"
import { useAnalytics } from "./useAnalytics"
import { useApp } from "./useApp"
import { useCheckout, useCheckoutContext } from "./useCheckout"
import { useCore } from "./useCore"
import { useLocalisationContext } from "./useLocalisation"
import { useShopify } from "./useShopify"

export const useCart = () => {
  const {
    helpers: { storage },
    graphql: {
      mutations: { CHECKOUT_LINE_ITEMS_REPLACE, CHECKOUT_LINE_ITEM_ADD, CHECKOUT_LINE_ITEMS_REMOVE },
    },
  } = useCore()
  const {
    config: {
      settings: { keys },
    },
  } = useApp()
  const { contextCountry } = useLocalisationContext()
  const { checkout, rawCheckout } = useCheckoutContext()
  const { setCheckout } = useCheckout()
  const { useMutation, checkoutNormaliser } = useShopify()
  const { trackCartUpdate } = useAnalytics()
  const [loading, setLoading] = useState(false)
  const [loadingAttributes, setLoadingAttributes] = useState(false)
  const [errors, setErrors] = useState([])
  const checkoutId = checkout?.id || storage.get(keys?.checkout)

  const [lineItemsReplace] = useMutation(CHECKOUT_LINE_ITEMS_REPLACE)
  const [lineItemAdd] = useMutation(CHECKOUT_LINE_ITEM_ADD)
  const [lineItemsRemove] = useMutation(CHECKOUT_LINE_ITEMS_REMOVE)

  const shouldIncreaseQuantityOfLineItem = (lineItem: CheckoutLineItem, variantId: string) => {
    if (lineItem?.variant?.product?.productType === "Gift Cards") {
      return false
    }

    return lineItem?.variant?.id === variantId
  }

  const addToCart = useCallback(
    async (variantId: string, accessories: NormalisedProductVariant[] = [], quantity: number = 1, customAttributes: Attribute[] = []) => {
      setLoading(true)

      let alreadyInCart = false

      const lineItemsProducts =
        checkout?.lineItems?.map(lineItem => {
          if (shouldIncreaseQuantityOfLineItem(lineItem, variantId)) {
            alreadyInCart = true

            return {
              customAttributes: [
                ...(lineItem?.customAttributes?.map(({ key, value }) => ({
                  key,
                  value,
                })) ?? []),
                ...(customAttributes ?? []),
              ],
              quantity: lineItem?.quantity + quantity,
              variantId: variantId,
            }
          }
          return {
            customAttributes: lineItem?.customAttributes?.map(({ key, value }) => ({
              key,
              value,
            })),
            quantity: lineItem?.quantity,
            variantId: lineItem?.variant?.id,
          }
        }) ?? []

      const lineItemsAccessories = accessories?.map(({ id }) => ({
        customAttributes: [
          {
            key: keys.accessory_line_item_link,
            value: variantId,
          },
        ],
        quantity,
        variantId: id,
      }))

      const lineItemsToReplace = [
        ...(alreadyInCart ? lineItemsProducts : [...lineItemsProducts, { quantity, variantId, customAttributes }, ...lineItemsAccessories]),
      ]

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          countryCode: contextCountry,
          checkoutId,
          lineItems: lineItemsToReplace,
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) setCheckout(data?.checkout)
      setLoading(false)
      trackCartUpdate("add", variantId, quantity, checkoutNormaliser(data?.checkout)?.lineItems)
    },
    [checkout, contextCountry, lineItemsReplace, setErrors, setCheckout, setLoading]
  )

  const addGiftCardToCart = useCallback(
    async (variantId, quantity = 1, customAttributes = []) => {
      setLoading(true)

      const {
        data: { checkoutLineItemsAdd: data, userErrors: errors },
      } = await lineItemAdd({
        variables: {
          countryCode: contextCountry,
          checkoutId,
          lineItems: [{ quantity, variantId, customAttributes }],
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) setCheckout(data?.checkout)
      setLoading(false)
      trackCartUpdate("add", variantId, quantity, checkoutNormaliser(data?.checkout)?.lineItems)
    },
    [lineItemAdd, setErrors, setCheckout, setLoading, checkout]
  )

  const removeLineItemFromCart = useCallback(
    async lineItemId => {
      setLoading(true)
      const { lineItems } = checkout || {}
      const quantity = lineItems?.find(lineItem => lineItem?.id === lineItemId)?.quantity
      const variantId = lineItems?.find(lineItem => lineItem?.id === lineItemId)?.variant?.id
      const accessoryLineItemIds = lineItems?.filter(lineItem => isChildLineItem(lineItem, variantId)).map(({ id }) => id) ?? []

      trackCartUpdate("remove", variantId, quantity, lineItems)

      const lineItemIds = [lineItemId, ...accessoryLineItemIds]
      const {
        data: { checkoutLineItemsRemove: data, userErrors: errors },
      } = await lineItemsRemove({
        variables: {
          countryCode: contextCountry,
          checkoutId,
          lineItemIds,
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) setCheckout(data?.checkout)
      setLoading(false)
    },
    [checkout, contextCountry, lineItemsReplace, setErrors, setCheckout, setLoading]
  )

  const isChildLineItem = (lineItem: CheckoutLineItem, variantId: string) => {
    return !!lineItem?.customAttributes?.find(({ key, value }) => key === keys.accessory_line_item_link && value === variantId)
  }

  const updateQuantity = useCallback(
    async (variantId, quantity) => {
      const lineItems = checkout?.lineItems
      const lineItemsInput = lineItems?.map(lineItem => {
        const isUpdateableItem = lineItem.variant.id === variantId || isChildLineItem(lineItem, variantId)
        return {
          ...(lineItem.customAttributes && {
            customAttributes: lineItem.customAttributes.map(({ key, value }) => ({ key, value })),
          }),
          quantity: isUpdateableItem ? quantity : lineItem.quantity,
          variantId: lineItem.variant.id,
        }
      })

      setLoading(true)
      try {
        const {
          data: { checkoutLineItemsReplace: data, userErrors: errors },
        } = await lineItemsReplace({
          variables: {
            countryCode: contextCountry,
            checkoutId,
            lineItems: lineItemsInput,
          },
        })

        if (errors?.length) {
          setErrors(errors)
          setCheckout(checkout)
        }

        if (data) {
          setCheckout(data?.checkout)
          trackCartUpdate(`change`, variantId, quantity, checkoutNormaliser(data?.checkout)?.lineItems)
        } else {
          setCheckout(rawCheckout)
        }
      } catch (error) {
        Sentry.captureException(error)
        setCheckout(rawCheckout)
      } finally {
        setLoading(false)
      }
    },
    [lineItemsReplace, setErrors, setCheckout, setLoading, checkout, rawCheckout]
  )

  const updateVariant = useCallback(
    async (prevVariantId, variantId, accessories: NormalisedProductVariant[] = []) => {
      setLoading(true)

      const updatingLineItem = checkout?.lineItems?.find(lineItem => {
        return lineItem.variant.id === prevVariantId
      })

      const accessoriesAsLineItems =
        accessories?.map(({ id }) => ({
          customAttributes: [
            {
              key: keys.accessory_line_item_link,
              value: variantId,
            },
          ],
          quantity: updatingLineItem.quantity,
          variantId: id,
        })) ?? []

      const nonUpdateableLineItems = checkout?.lineItems?.filter(lineItem => {
        return lineItem.variant.id !== prevVariantId && !isChildLineItem(lineItem, prevVariantId)
      })

      const lineItems = [
        updatingLineItem
          ? {
              ...(updatingLineItem.customAttributes && {
                customAttributes: updatingLineItem.customAttributes.map(({ key, value }) => ({ key, value })),
              }),
              quantity: updatingLineItem.quantity,
              variantId: variantId,
            }
          : undefined,
        ...accessoriesAsLineItems,
        ...nonUpdateableLineItems.map(lineItem => ({
          ...(lineItem.customAttributes && {
            customAttributes: lineItem.customAttributes.map(({ key, value }) => ({ key, value })),
          }),
          quantity: lineItem.quantity,
          variantId: lineItem.variant.id,
        })),
      ]

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          countryCode: contextCountry,
          checkoutId,
          lineItems,
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) setCheckout(data?.checkout)
      setLoading(false)
    },
    [lineItemsReplace, setErrors, setCheckout, setLoading, checkout]
  )

  const updateAttributes = useCallback(
    async (variantId, customAttributes) => {
      setLoadingAttributes(true)
      const lineItems = checkout?.lineItems?.map(lineItem =>
        lineItem.variant.id === variantId
          ? {
              customAttributes: [
                ...new Map(
                  [
                    ...(lineItem?.customAttributes?.map(({ key, value }) => ({
                      key,
                      value,
                    })) ?? []),
                    ...(Object.entries(customAttributes)?.map(attr => ({
                      key: attr[0],
                      value: attr[1],
                    })) ?? []),
                  ].map(item => [item?.key, item])
                ).values(),
              ],
              variantId,
              quantity: lineItem.quantity,
            }
          : {
              ...(lineItem?.customAttributes && {
                customAttributes: lineItem.customAttributes.map(({ key, value }) => ({
                  key,
                  value,
                })),
              }),
              quantity: lineItem.quantity,
              variantId: lineItem.variant.id,
            }
      )
      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          countryCode: contextCountry,
          checkoutId,
          lineItems,
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) setCheckout(data?.checkout)
      setLoadingAttributes(false)
    },
    [lineItemsReplace, setErrors, setCheckout, setLoadingAttributes, checkout]
  )

  const updateAllAttributes = useCallback(
    async customAttributes => {
      setLoadingAttributes(true)
      const lineItems = checkout?.lineItems?.map(lineItem => ({
        customAttributes: [
          ...new Map(
            [
              ...(lineItem?.customAttributes?.map(({ key, value }) => ({
                key,
                value,
              })) ?? []),
              ...(Object.entries(customAttributes)?.map(attr => ({
                key: attr[0],
                value: attr[1],
              })) ?? []),
            ].map(item => [item?.key, item])
          ).values(),
        ],
        quantity: lineItem.quantity,
        variantId: lineItem.variant.id,
      }))
      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          countryCode: contextCountry,
          checkoutId,
          lineItems,
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) setCheckout(data?.checkout)
      setLoadingAttributes(false)
    },
    [lineItemsReplace, setErrors, setCheckout, setLoadingAttributes, checkout]
  )

  const updateItem = useCallback(
    async (variantId, quantity, customAttributes) => {
      setLoading(true)
      const lineItems = checkout?.lineItems?.map(lineItem =>
        lineItem.variant.id === variantId
          ? {
              customAttributes: [
                ...new Map(
                  [
                    ...(lineItem?.customAttributes?.map(({ key, value }) => ({
                      key,
                      value,
                    })) ?? []),
                    ...(Object.entries(customAttributes)?.map(attr => ({
                      key: attr[0],
                      value: attr[1],
                    })) ?? []),
                  ].map(item => [item?.key, item])
                ).values(),
              ],
              variantId,
              quantity,
            }
          : {
              ...(lineItem?.customAttributes && {
                customAttributes: lineItem.customAttributes.map(({ key, value }) => ({
                  key,
                  value,
                })),
              }),
              quantity: lineItem.quantity,
              variantId: lineItem.variant.id,
            }
      )

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          countryCode: contextCountry,
          checkoutId,
          lineItems,
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) setCheckout(data?.checkout)
      setLoading(false)
    },
    [lineItemsReplace, setErrors, setCheckout, setLoading, checkout]
  )

  const clearCart = useCallback(async () => {
    setLoading(true)

    if (checkout?.lineItems?.length > 0) {
      checkout.lineItems.map(({ variant, quantity }) => trackCartUpdate("remove", variant?.id, quantity, checkout?.lineItems))
    }

    const {
      data: { checkoutLineItemsReplace: data, userErrors: errors },
    } = await lineItemsReplace({
      variables: {
        countryCode: contextCountry,
        checkoutId,
        lineItems: [],
      },
    })

    if (errors?.length) setErrors(errors)
    if (data) setCheckout(data?.checkout)
    setLoading(false)
  }, [lineItemsReplace, setErrors, setCheckout, setLoading])

  const addAttributes = (selectedVariant?: NormalisedProductVariant): Attribute[] => {
    const { isPreOrder, isFinalSale } = selectedVariant || {}
    const attributes = [{
      key: keys?.cart_added_at, value: new Date().getTime().toString(),
    }]

    if (isPreOrder) {
      attributes.push({ key: keys?.cart_preorder, value: "Yes" })
    }
    if (isFinalSale) {
      attributes.push({ key: keys?.cart_finalsale, value: "Yes" })
    }

    return attributes
  }

  return {
    addAttributes,
    addGiftCardToCart,
    addToCart,
    clearCart,
    errors,
    loading,
    loadingAttributes,
    removeLineItemFromCart,
    setLoading,
    updateAllAttributes,
    updateAttributes,
    updateItem,
    updateQuantity,
    updateVariant,
  }
}
