import React from "react"
import tw from "twin.macro"
import { FooterAcknowledgementOfCountryOutputProps, withFooterAcknowledgementOfCountry } from "./withFooterFooterAcknowledgementOfCountry"

const Container = tw.div`
  flex flex-col px-midi flex-wrap justify-start text-left md:px-0 md:border-none md:flex-nowrap md:text-left md:items-start
`
const Acknowledgement = tw.div`
  text-[13px] leading-caption
`

export const FooterAcknowledgementOfCountry = withFooterAcknowledgementOfCountry(({ text }: FooterAcknowledgementOfCountryOutputProps) => (
  <Container>
    <Acknowledgement>{text}</Acknowledgement>
  </Container>
))
