import { useLocalisation } from "@hooks/useLocalisation"
import { CustomComponent } from "@usereactify/search"
import React from "react"

export const CustomSensor = () => {
  const { currentLocale } = useLocalisation()
  const { defaultCountryCode } = currentLocale || {}

  return (
    <CustomComponent
      componentId="CustomSensor"
      customQuery={() => ({
        query: { bool: { should: [{ match: { tags_country: defaultCountryCode } }, { match: { type: "callout" } }] } },
      })}
    />
  )
}
