import React from "react"

import { useLocation } from "@reach/router"
import { parseGid } from "@shopify/hydrogen-react"

import { useApp } from "@hooks/useApp"
import { useCore } from "@hooks/useCore"
import { useSettings } from "@hooks/useSettings"
import { useCheckoutContext } from "@hooks/useCheckout"
import { useCustomerContext, useCustomerSession } from "@hooks/useCustomer"
import { useLocation as useLocale } from "@hooks/useLocation"

/**
 * Computes and attaches a Salesforce data layer object to the window.
 * @todo Remove optional chaining and optional DataLayer attributes when upstream types are enforced.
 */
export const LiveChatDataLayer: React.FC = () => {
  const { liveChat } = useSettings()

  const { checkout } = useCheckoutContext()
  const { customer } = useCustomerContext()
  const { getSessionId } = useCustomerSession()

  const { locationCurrency } = useLocale()

  const location = useLocation()

  // @todo Replace all this with a proper hooks, rather than using keys directly.
  const {
    helpers: { storage },
  } = useCore()
  const {
    config: {
      settings: { keys },
    },
  } = useApp()
  // @ts-expect-error Merged keys are not properly typed.
  const customerAccessToken = React.useMemo(() => storage.get(keys?.customer)?.accessToken, [storage, keys])
  // @ts-expect-error Merged keys are not properly typed.
  const customerIsVip = React.useMemo(() => (customer ? customer.tags.includes(keys?.vip_tag) : false), [customer, keys])

  const pageType = React.useMemo<PageType>(() => {
    if (pathRegexArticle.test(location.pathname)) return "article"
    if (pathRegexProduct.test(location.pathname)) return "product"
    if (pathRegexCollection.test(location.pathname)) return "collection"
    return "page"
  }, [location])

  const cartData = React.useMemo<Cart | undefined>(
    () =>
      checkout
        ? {
            id: checkout.id ?? "",
            total: checkout.totalPriceV2?.amount ? Number(checkout.totalPriceV2.amount) : 0,
            items: checkout.lineItems
              ? checkout.lineItems.map(({ quantity, variant }) => ({
                  qty: quantity,
                  name: variant?.title,
                  sku: variant?.sku || undefined,
                  price: variant?.priceV2?.amount ? Number(variant.priceV2.amount) : undefined,
                  itemurl: variant?.product?.handle ? `${window.location.origin}/products/${variant?.product?.handle}` : undefined,
                }))
              : [],
          }
        : undefined,
    [checkout]
  )

  const userData = React.useMemo<User | undefined>(() => {
    const sessionData = {
      session_id: getSessionId(),
    }

    if (!customer) {
      return sessionData
    }

    return {
      ...sessionData,
      email: customer?.email,
      phone: customer?.phone,
      customer_is_vip: customerIsVip,
      customer_id: parseGid(customer?.id).id,
      customer_access_token: customerAccessToken,
      name: `${customer?.firstName} ${customer?.lastName}`,
    }
  }, [customer, customerAccessToken, customerIsVip])

  const dataLayer = React.useMemo<DataLayer>(
    () => ({
      cart: cartData,
      user: userData,
      page: { type: pageType },
      website: { currency: locationCurrency },
    }),
    [pageType, cartData, userData, locationCurrency]
  )

  React.useEffect(() => {
    if (!liveChat) return

    if ("salesforce" === liveChat.type) {
      window["_salesforceData"] = {
        ...window["_salesforceData"],
        ...dataLayer,
      }
    }

    if ("inside" === liveChat.type) {
      window["_insideData"] = {
        ...window["_insideData"],
        ...dataLayer,
      }
    }
  }, [liveChat, dataLayer])

  return null
}

const pathRegexProduct = /\/products\//
const pathRegexArticle = /\/articles\//
const pathRegexCollection = /\/collections\//

export type DataLayer = {
  cart?: Cart
  user?: User
  product?: Product
  page: { type: PageType }
  website: { currency?: string }
}

type PageType = "page" | "product" | "collection" | "article"

interface Cart {
  /** @example gid://shopify/Checkout/b783ee16ab0ba8de591199d33ba9beed?key=5948537b83d40836a31852d5bdb52360 */
  id: string
  total: number
  items: CartItem[]
}

interface CartItem {
  qty: number
  sku?: string
  name?: string
  price?: number
  itemurl?: string
}

interface Product {
  sku?: string
  name?: string
  price: number
  image?: string
  variantId?: string
}

interface User {
  name?: string
  email?: string
  phone?: string
  customer_id?: string
  customer_is_vip: boolean
  customer_access_token?: string
}

declare global {
  interface Window {
    _insideData?: DataLayer
    _salesforceData?: DataLayer
  }
}
