import React from "react"
import tw from "twin.macro"
import NavigationFooter from "../Navigation/NavigationFooter"
import { BackgroundContainer, StyledContainer } from "../Styled/Container"
import { FooterAchievements } from "./FooterAchievements"
import { FooterAcknowledgementOfCountry } from "./FooterAcknowledgementOfCountry"
import { FooterCopyright } from "./FooterCopyright"
import { FooterTerms } from "./FooterTerms"

const FooterContainer = tw(BackgroundContainer)`bg-primary bg-opacity-10`

const Container = tw(StyledContainer)`
  max-w-2xl mx-auto md:pb-midi md:p-mega xl:px-tera 
`
const Row = tw.div`
  flex flex-col justify-between w-full flex-nowrap xl:flex-row
`
const ReverseRow = tw.div`
  flex flex-col max-w-xl md:flex-row
`
const Column = tw.div`
  flex flex-col flex-nowrap pl-midi py-midi md:p-0 pb-zero max-w-[300px] lg:py-0
`

const FooterContent = () => (
  <FooterContainer>
    <Container width="full">
      <Row>
        <NavigationFooter />
        <Column>
          <Row>
            <FooterAchievements />
          </Row>
        </Column>
      </Row>
      <FooterAcknowledgementOfCountry />
      <ReverseRow>
        <FooterTerms />
        <FooterCopyright />
      </ReverseRow>
    </Container>
  </FooterContainer>
)

export default FooterContent
