import * as Sentry from "@sentry/gatsby"
import { useRichText } from "@hooks/useRichText"
import { useGeofencing } from "@hooks/useGeofencing"
import { useLocalisation, useLocalisationContext } from "@hooks/useLocalisation"
import { useMounted } from "@hooks/useMounted"
import { useSettings } from "@hooks/useSettings"
import { ComponentProps } from "@ts/components"
import { SettingGeofencing } from "@ts/sanity"
import React, { useEffect, useState, type Dispatch, type FC, type SetStateAction } from "react"

export type GeofencingOutputProps = ComponentProps & {
  active: boolean
  content: string
  continueCTA: string
  onClose: () => void
  onRedirect: () => void
  redirectCTA: string
  redirectCTAColour: "green" | "charcoal" | "beige" | "lightest"
  redirectUrl: string
  setActive: Dispatch<SetStateAction<boolean>>
}

export const withGeofencing =
  (Component: FC<GeofencingOutputProps>) =>
  ({ name = "Geofencing", children, ...props }: ComponentProps) => {
    const [active, setActive] = useState(false)
    const { hasMounted } = useMounted()
    const { allGeofencing } = useSettings()
    const { isAllowed, redirectLocale, savePreferredLocale } = useGeofencing()
    const { currentLocale, isResolvingPreferredLocale, preferredLocaleBasePath, enableGeolocation } = useLocalisationContext()
    const { findNodeByLocale } = useLocalisation()
    const { parseContent } = useRichText()
    const { languageCode: currentLanguageCode } = currentLocale
    const { languageCode: targetLanguageCode, baseRoute, isDefault } = redirectLocale || {}
    const targetGeofencingContent = findNodeByLocale<SettingGeofencing>(allGeofencing, targetLanguageCode)
    const currentGeofencingContent = findNodeByLocale<SettingGeofencing>(allGeofencing, currentLanguageCode)
    const { content: rawContent, redirectCTAColour, redirectCTA } = targetGeofencingContent || {}
    const { enabled, continueCTA } = currentGeofencingContent || {}
    const content = parseContent(rawContent)
    const redirectUrl = isDefault ? "/" : baseRoute

    const handleClose = () => {
      setActive(false)

      Sentry.addBreadcrumb({
        type: "info",
        category: "components/Geofencing",
        data: {
          action: "StayOnSite",
          pathname: window.location.pathname,
          search: window.location.search,
          isAllowed,
        },
      })
    }

    const handleRedirect = () => {
      setActive(false)
      savePreferredLocale()

      Sentry.addBreadcrumb({
        type: "info",
        category: "components/Geofencing",
        data: {
          action: "SavePreferredLocale",
          pathname: window.location.pathname,
          search: window.location.search,
          isAllowed,
        },
      })
    }

    useEffect(() => {
      if (!enableGeolocation || !enabled || isResolvingPreferredLocale || preferredLocaleBasePath) {
        return
      }

      Sentry.addBreadcrumb({
        type: "info",
        category: "components/Geofencing",
        data: {
          action: "DisplayModal",
          pathname: window.location.pathname,
          search: window.location.search,
          isAllowed,
        },
      })

      setActive(!isAllowed)
    }, [enabled, isAllowed, isResolvingPreferredLocale, preferredLocaleBasePath, enableGeolocation])

    Component.displayName = name

    if (!enableGeolocation) return null

    if (!hasMounted) {
      return <>{children}</>
    }

    return (
      <Component
        {...props}
        active={active}
        content={content}
        continueCTA={continueCTA}
        onClose={handleClose}
        onRedirect={handleRedirect}
        redirectCTA={redirectCTA}
        redirectCTAColour={redirectCTAColour}
        redirectUrl={redirectUrl}
        setActive={setActive}
      >
        {children}
      </Component>
    )
  }
