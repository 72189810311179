import { withFocusRing } from "@components/Accessibility/FocusRing/FocusRing"
import tw, { styled } from "twin.macro"

export const ImageWrapper = withFocusRing(styled.div`
  ${tw`relative`}
  ${({ fullWidth }) => (fullWidth ? tw`w-full-vw` : tw`w-full`)}
  ${({ onClick }) => (onClick ? tw`cursor-pointer` : null)}
`)

export const ImageRatio = withFocusRing(styled.div`
  padding-bottom: ${({ ratio }) => (ratio.split("/")[1] / ratio.split("/")[0]) * 100}%;
`)

export const StyledImage = withFocusRing(styled.img`
  ${({ cover }) => (cover ? tw`absolute inset-0 object-cover object-center w-full h-full` : null)}
  ${({ hover }) => (hover ? tw`transition-layout opacity-0 pointer-events-none md:group-hover:opacity-100 md:group-hover:pointer-events-auto` : null)}
`)

export const ClickableImageWrapper = withFocusRing(styled.div`
  ${tw`h-full`}
  ${({ onClick }) => (onClick ? tw`cursor-pointer` : null)}
`)
