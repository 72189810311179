import { useDebounce } from "@react-hooks-library/core"
import * as Sentry from "@sentry/gatsby"
import { SearchResultSanity } from "@ts/search"
import { useSearch } from "@usereactify/search"
import { useEffect, useState } from "react"
import { useFunctions } from "./useFunctions"
import { useLocalisation } from "./useLocalisation"

export const useSanitySearch = () => {
  const { searchTerm } = useSearch()
  const debouncedSearchTerm = useDebounce(searchTerm, 200)
  const { searchSanity } = useFunctions()
  const { filterNodesByLocale } = useLocalisation()
  const [sanitySearchResults, setSanitySearchResults] = useState<SearchResultSanity[]>([])

  const getSanitySearchResults = async (query: string) => {
    Sentry.addBreadcrumb({
      level: "info",
      category: "hooks/useSanitySearch",
      data: {
        query,
        searchTerm: debouncedSearchTerm,
      },
    })
    const { status, body } = await searchSanity<SearchResultSanity[]>(query)
    if (status === "success") {
      if (body && typeof body !== "string") {
        const localisedSearchResults = filterNodesByLocale<SearchResultSanity>(body, {
          ignoreParents: true,
        })
        setSanitySearchResults(localisedSearchResults)
      } else {
        Sentry.captureMessage("Sanity search did not return results", {
          level: "warning",
          extra: {
            query,
            status,
            body,
          },
        })
      }
    } else {
      Sentry.captureMessage("Sanity search failed", {
        level: "error",
        extra: {
          query,
          status,
          body,
        },
      })
    }
  }

  useEffect(() => {
    if (!debouncedSearchTerm) return
    getSanitySearchResults(debouncedSearchTerm)
  }, [debouncedSearchTerm])

  return {
    getSanitySearchResults,
    sanitySearchResults,
  }
}
