import React from "react"
import tw, { styled } from "twin.macro"
import DeliveryOptions from "../../DeliveryOptions/DeliveryOptions"
import { withCartDeliveryOptions } from "./withCartDeliveryOptions"
import { Accordion } from "../../Accordion/Accordion"
import { StyledH4 } from "../../Styled/Text"
import { GooglePlacesProvider } from "@providers/global/google-places"

export const styles = {
  cart: {
    container: tw`mt-maxi px-midi lg:px-0`,
    wrapper: tw`py-maxi px-mini bg-white`,
  },
  minicart: {
    container: tw`bg-primary bg-opacity-10`,
    wrapper: tw`py-midi`,
  },
}

const Container = styled.section`
  ${({ layout }) => styles[layout]?.container || styles.cart.container}
`

const Wrapper = styled.div`
  ${({ layout }) => styles[layout]?.wrapper || styles.cart.wrapper}
`

const Title = tw(StyledH4)`text-[18px] leading-h4 mb-midi md:mb-maxi font-medium font-display`

const DynamicWrapper = ({ layout, children }) => {
  const title = "Your delivery options"

  if (layout === "minicart") {
    return (
      <Accordion data-testid="cart-delivery-options-minicart-title" active={false} size={layout} icon title={title}>
        {children}
      </Accordion>
    )
  }

  return (
    <>
      <Title data-testid="cart-delivery-options-cart-title">{title}</Title>
      {children}
    </>
  )
}

export const CartDeliveryOptions = withCartDeliveryOptions(({ layout }) => (
  <Container layout={layout}>
    <DynamicWrapper layout={layout}>
      <Wrapper layout={layout}>
        <GooglePlacesProvider>
          <DeliveryOptions layout={layout} />
        </GooglePlacesProvider>
      </Wrapper>
    </DynamicWrapper>
  </Container>
))
