import { useApp } from "@hooks/useApp"
import { ComponentProps } from "@ts/components"
import React, { FC } from "react"

type HeaderBrandInputProps = ComponentProps

type HeaderBrandOutputProps = {
  title: string
  url: string
}

export const withHeaderBrand =
  (Component: FC<HeaderBrandOutputProps>) =>
  ({ name = "HeaderBrand" }: HeaderBrandInputProps) => {
    const {
      config: {
        app: { title },
        settings: { routes },
      },
    } = useApp()

    const url = `${routes[`HOMEPAGE`]}`

    Component.displayName = name
    return <Component title={title} url={url} />
  }
