import React, { FC } from "react"
import { useUpsell } from "../../../hooks/useUpsell"
import { ComponentProps } from "../../../types/components"
import { Product } from "../../../types/shopify-storefront"

export type CartUpsellsInputProps = ComponentProps

export type CartUpsellsOutputProps = CartUpsellsInputProps & {
  title?: string
  upsells?: Product[]
}

export const withCartUpsells =
  (Component: FC<CartUpsellsOutputProps>) =>
  ({ name = "CartUpsells" }) => {
    const { title, enabled, upsells } = useUpsell()

    if (!enabled || !upsells?.length) {
      return null
    }

    Component.displayName = name

    return <Component title={title} upsells={upsells} />
  }
