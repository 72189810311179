import * as Sentry from "@sentry/gatsby"
import { type UserError } from "@ts/shopify-storefront"
import { useCallback, useState } from "react"
import { useApp } from "./useApp"
import { useCore } from "./useCore"
import { useLocation } from "./useLocation"
import { InterestsAndSubscriptionsAPIResponse, InterestsAndSubscriptionsAPIErrorResponse } from "../api/customers/me/interests-and-subscriptions"
import { WishlistSharePOSTAPIRequest } from "../api/customers/me/wishlist/share"

type FunctionResponse<T = unknown> = {
  status?: "success" | "error"
  body?: string | T
}

export const useFunctionsGatsby = () => {
  const {
    helpers: { storage },
  } = useCore()
  const { shopifyStore } = useLocation()
  const {
    config: {
      settings: { keys },
    },
  } = useApp()

  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<UserError[] | string[]>([])
  const token = storage.get(keys?.customer)?.accessToken

  const request = useCallback(
    async (endpoint, data, overrides: RequestInit = {}) => {
      setLoading(true)
      setErrors([])

      return fetch(`/api/${endpoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-gatsby-customer-token": token,
        },
        ...overrides,
        ...(overrides.method !== "GET" && { body: JSON.stringify(data) }),
      })
        .then(res => res.json())
        .then(result => {
          setLoading(false)
          return result
        })
        .catch(error => {
          Sentry.captureException(error)
          setLoading(false)
          setErrors([error])
          return error
        })
    },
    [setLoading, setErrors, fetch]
  )

  const backInStock = useCallback<<T>(email: string, variant: string) => Promise<FunctionResponse<T>>>(
    async (email, variant) => {
      const { status, body } = await request("backinstock", { email, variant })
      return { status, body }
    },
    [request]
  )

  const getCustomerEmailInterestsAndFrequency = useCallback<
    <T>(email: string) => Promise<InterestsAndSubscriptionsAPIResponse | InterestsAndSubscriptionsAPIErrorResponse>
  >(
    async email => {
      const { status, body } = await request(`customers/me/interests-and-subscriptions?shop=${shopifyStore}&email=${email}`, {}, { method: "GET" })
      return { status, body }
    },
    [request]
  )

  const updateCustomerEmailInterestsAndFrequency = useCallback<
    <T>(
      email: string,
      emailInterests: string[],
      emailFrequency: string,
      tags?: string[],
      customerAccessToken?: string
    ) => Promise<FunctionResponse<T>>
  >(
    async (email, emailInterests, emailFrequency, tags, customerAccessToken) => {
      const customerToken = token || customerAccessToken
      const genderMap = { men: "Mens", women: "Womens" }
      const endpoint = `customers/me/interests-and-subscriptions?shop=${shopifyStore}&email=${email}`
      const method = "POST"

      if (tags?.length > 0) {
        const mappedTags = tags?.filter(item => Object.keys(genderMap)?.includes(item))?.map(tag => genderMap[tag])
        emailInterests.push(...mappedTags)
      }

      const { status, body } = await request(
        endpoint,
        { emailInterests, emailFrequency },
        {
          method,
          headers: {
            "Content-Type": "application/json",
            "x-gatsby-customer-token": customerToken,
          },
        }
      )
      return { status, body }
    },
    [request]
  )

  const subscribeCustomer = useCallback<<T>(email: string) => Promise<FunctionResponse<T>>>(
    async email => {
      const endpoint = `customers/me/subscribe?shop=${shopifyStore}&email=${email}`
      const { status } = await request(endpoint, {})
      return { status }
    },
    [request]
  )

  const unsubscribeCustomer = useCallback<<T>(email: string) => Promise<FunctionResponse<T>>>(
    async email => {
      const endpoint = `customers/me/subscribe?shop=${shopifyStore}&email=${email}`
      const method = "DELETE"
      const { status } = await request(endpoint, {}, { method })
      return { status }
    },
    [request]
  )

  const shareWishlistByEmail = useCallback<<T>(email: string, body: WishlistSharePOSTAPIRequest) => Promise<FunctionResponse<T>>>(
    async (email, body) => {
      const endpoint = `customers/me/wishlist/share?shop=${shopifyStore}&email=${email}`
      const { status } = await request(endpoint, body)
      return { status }
    },
    [request]
  )

  return {
    backInStock,
    getCustomerEmailInterestsAndFrequency,
    updateCustomerEmailInterestsAndFrequency,
    subscribeCustomer,
    unsubscribeCustomer,
    shareWishlistByEmail,
    errors,
    loading,
    setErrors,
  }
}
