import React, { FC } from "react"

import { useCurrency } from "../../hooks/useCurrency"
import { useLocation } from "../../hooks/useLocation"
import { ComponentProps } from "../../types/components"
import { MoneyV2 } from "../../types/shopify-storefront"

export type MoneyProps = ComponentProps & {
  price: MoneyV2
  displayZeroAsFree?: boolean
  refund?: boolean
}

export const Money: FC<MoneyProps> = ({ price = {}, className, displayZeroAsFree, refund }) => {
  const { formatCurrency } = useCurrency()
  const { checkoutCurrency } = useLocation()
  const amount = parseFloat(price?.amount)
  const currencyCode = price?.currencyCode
  const formatted = formatCurrency(Number(amount), currencyCode, 2)

  if (displayZeroAsFree && amount === 0) {
    return <p className={className}>Free</p>
  }

  return (
    <p className={className}>
      {refund ? `-` : ""}
      {currencyCode !== checkoutCurrency ? currencyCode : ""}
      {formatted}
    </p>
  )
}

export default Money
