import { Link } from "@components/Link/Link"
import React from "react"
import tw from "twin.macro"
import { Icon } from "../../Icon"
import { withWidget } from "./withWidget"

const StyledIcon = tw(Icon)`p-micro text-secondary hover:text-primary transition-presentation duration-slow md:px-mini md:py-maxi`

const StyledAccount = tw(Link)`
  flex
`

export const Widget = withWidget(({ handleClick, accountLink, iconName }) => (
  <StyledAccount onClick={handleClick} to={accountLink} title="My Account">
    <StyledIcon data-testid={iconName} name={iconName} size="xsmall" />
  </StyledAccount>
))
