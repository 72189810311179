import React from "react"
import tw, { styled } from "twin.macro"

import { withAddToCartErrorPopup } from "./withAddToCartErrorPopup"
import ModalsPopup from "../../Modals/ModalsPopup"
import { StyledButton } from "../../Styled/Button"

const Content = tw.div`
  relative w-full flex flex-col p-mega md:p-giga
`

const Title = tw.h4`
  text-h4 leading-h4 font-display mb-mini text-center md:text-[32px]
`

const SubTitle = tw.p`
  text-hint leading-h4 mb-mini text-center md:text-body
`

const Button = styled(StyledButton)`
  ${tw`w-full`}
`

const AddToCartErrorPopup = withAddToCartErrorPopup(
  ({ active, setActive, additionalErrorTitle, additionalErrorDescription, additionalErrorButton }) => {
    return (
      <ModalsPopup layout="notify" data-testid="add-to-cart-error-popup" active={active} setActive={setActive}>
        <Content>
          <Title>{additionalErrorTitle}</Title>
          <SubTitle>{additionalErrorDescription}</SubTitle>
          <Button height="56" textSize="16" colour="primary" onClick={() => setActive(false)}>
            {additionalErrorButton}
          </Button>
        </Content>
      </ModalsPopup>
    )
  }
)

export default AddToCartErrorPopup
