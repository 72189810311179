import { Link } from "@components/Link/Link"
import React from "react"
import tw, { styled } from "twin.macro"

import { AnnouncementOutputProps, withAnnouncement } from "./withAnnouncement"

const Wrapper = tw.div`absolute w-full left-0 top-0 text-center h-full`

const Item = styled.div`
  ${tw`absolute inset-0 flex items-center justify-center text-center transition-[layout,transform] hover:!text-white hover:opacity-80`}
  ${({ active }) => (active ? tw`visible translate-y-0 opacity-100` : tw`invisible -translate-y-6 opacity-0`)}
`

const Announcement = withAnnouncement(({ items, active }: AnnouncementOutputProps) => {
  return (
    <Wrapper>
      {items.map(({ title, link }, index) => (
        <Item
          key={index}
          active={active === index ? "true" : undefined}
          as={link?.url ? Link : "div"}
          title={link?.url && link?.title}
          to={link?.url}
        >
          {title}
        </Item>
      ))}
    </Wrapper>
  )
})

export default Announcement
