import React, { FC } from "react"
import { useSettings } from "../../hooks/useSettings"
import { ComponentProps } from "../../types/components"

export type FooterAchievementsInputProps = ComponentProps

export const withFooterAchievements =
  (Component: FC<FooterAchievementsInputProps>) =>
  ({ name = "FooterAchievements" }: FooterAchievementsInputProps) => {
    const { achievements } = useSettings()
    const { enabled } = achievements || {}
    Component.displayName = name
    if (!enabled) {
      return null
    }
    return <Component />
  }
