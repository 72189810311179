import { useCallback } from "react"

export const useTripleWhale = () => {
  //@ts-ignore
  const triplePixel = typeof window !== "undefined" && window.TriplePixel ? window.TriplePixel : () => {}
  //@ts-ignore
  const isDev = process.env.GATSBY_ACTIVE_ENV === "development" || process.env.NODE_ENV === "development"

  const trackAddToCart = useCallback(
    (item: string, q: number) => {
      if (isDev) console.log(`[TRIPLEWHALE] add to cart fired.`)
      triplePixel("AddToCart", { item, q })
    },
    [triplePixel, isDev]
  )

  const trackVisitorContact = useCallback(
    (email: string) => {
      if (isDev) console.log(`[TRIPLEWHALE] visitor contact fired.`)
      triplePixel("Contact", { email })
    },
    [triplePixel, isDev]
  )

  const trackPageLoad = useCallback(
    (type: string) => {
      if (isDev) console.log(`[TRIPLEWHALE] page load fired.`)
      triplePixel("PageLoad", { type })
    },
    [triplePixel, isDev]
  )

  return { trackAddToCart, trackVisitorContact, trackPageLoad }
}
