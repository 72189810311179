import { type DropdownOption } from "@hooks/useSelector"
import { type ComponentProps } from "@ts/components"
import React, { type FC, type Ref } from "react"

type DropdownOptionAction = {
  action: string
  name?: string
  option?: string
}

export type DropdownInputProps = ComponentProps & {
  error?: string
  handleChange?: (value: string, name: string, id?: string) => void
  handleMenuClose?: () => void
  label?: string
  layout?: "form" | "return" | "cart" | "addToCartFooter" | "sizes" | "denominations"
  name?: string
  optional?: boolean
  options?: DropdownOption[]
  placeholder?: string
  placeholderColor?: string
  required?: boolean
  selectorRef?: Ref<{ state: { menuIsOpen: boolean } }>
  value?: string
}

type DropdownOutputProps = Omit<DropdownInputProps, "handleChange" | "value"> & {
  dataTestId: string
  handleChange: (option: DropdownOption, action: DropdownOptionAction) => void
  isSearchable: boolean
  value: DropdownOption
}

export const withDropdown =
  (Component: FC<DropdownOutputProps>) =>
  ({
    handleChange,
    id,
    name,
    options,
    placeholder,
    value,
    layout = "form",
    "data-testid": dataTestId = "form-dropdown",
    ...rest
  }: DropdownInputProps) => {
    const normalisedProps = {
      placeholder,
      value: options?.find(option => option.value === value),
      isSearchable: true,
    }

    if (options?.length === 1) {
      const [onlyOption] = options
      normalisedProps.placeholder = onlyOption?.value
      normalisedProps.value = onlyOption
    }

    if (layout === "sizes") {
      normalisedProps.isSearchable = false
    }

    const onChange = (option: DropdownOption, action?: DropdownOptionAction) => {
      handleChange(option.value, name, id)
    }

    Component.displayName = "FormDropdown"

    return (
      <Component
        dataTestId={dataTestId}
        handleChange={onChange}
        id={id}
        layout={layout}
        name={name}
        options={options}
        {...rest}
        {...normalisedProps}
      />
    )
  }
