import React, { FC, ChangeEvent, useCallback, useState, useEffect, Ref, useMemo } from "react"
import { Suggestion } from "use-places-autocomplete"
import { ComponentProps } from "../../types/components"
import { ThemeColors } from "../../types/theme"

export type FormPlaceAutocompleteInputProps = ComponentProps & {
  bgColour?: Extract<keyof ThemeColors, "transparent" | "beige" | "light">
  defaultValue?: string
  inputRef?: Ref<HTMLInputElement>
  loading?: boolean
  loadingLabel?: string
  name?: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onSelect: (address: string, place: Suggestion) => void
  placeholder?: string
  placeholderColor?: string
  readOnly?: boolean
  required?: boolean
  suggestions?: Suggestion[]
  suggestionsLabel?: string
  value?: string
  width?: string
}

export type FormPlaceAutocompleteOutputProps = Omit<FormPlaceAutocompleteInputProps, "onSelect"> & {
  active: boolean
  onClose?: () => void
  onSelect?: (address: string) => void
  onTouched?: () => void
  touched: boolean
}

export const withFormPlaceAutocomplete =
  (Component: FC<FormPlaceAutocompleteOutputProps>) =>
  ({ name = "FormPlaceAutocomplete", onChange, onSelect, suggestions, bgColour = "transparent", ...props }: FormPlaceAutocompleteInputProps) => {
    const [hasResult, setHasResult] = useState<boolean>(false)
    const [touched, setTouched] = useState<boolean>(false)
    const [active, setActive] = useState<boolean>(false)

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (!onChange) {
        return
      }
      onChange(e)
    }

    const handleTouched = () => {
      setTouched(true)
    }

    const handleSelect = useCallback(
      async selected => {
        setHasResult(true)
        const [place] = suggestions?.filter((datum: Suggestion) => datum?.description === selected) ?? []
        onSelect(selected, place)
      },
      [suggestions]
    )

    const handleClose = () => {
      setActive(false)
    }

    useEffect(() => {
      setActive(!hasResult)
    }, [suggestions, hasResult])

    Component.displayName = name

    return useMemo(
      () => (
        <Component
          active={active}
          bgColour={bgColour}
          onChange={handleChange}
          onSelect={handleSelect}
          onTouched={handleTouched}
          onClose={handleClose}
          suggestions={suggestions}
          touched={touched}
          {...props}
        />
      ),
      [active, handleChange, handleSelect, handleTouched, handleClose, suggestions, touched]
    )
  }
