import React, { useMemo } from "react"

import { useRoutes } from "../../hooks/useRoutes"

export const withContentButton = Component =>
  React.memo(({ name = "ContentButton", title, customContentLink, colour, alignment, layout }) => {
    const { linkResolver } = useRoutes()

    const link = useMemo(
      () => ({
        ...customContentLink?.[0],
        ...linkResolver(customContentLink?.[0]),
      }),
      [customContentLink, linkResolver]
    )

    const isAnchorLink = !link.external && link._type === "link"

    Component.displayName = name
    return useMemo(
      () => <Component title={title} link={link} colour={colour} alignment={alignment} layout={layout} isAnchorLink={isAnchorLink} />,
      [title, link, colour, alignment, layout, isAnchorLink]
    )
  })
