import { useApp } from "@hooks/useApp"
import { useLocalisation } from "@hooks/useLocalisation"
import { usePage } from "@hooks/usePage"
import { ComponentProps } from "@ts/components"
import { SearchResultSanity } from "@ts/search"
import { useSearch } from "@usereactify/search"
import React, { FC } from "react"

export type SearchFormResultsInputProps = ComponentProps & {
  active: boolean
  sanitySearchResults: SearchResultSanity[]
}

export type SearchFormResultsOutputProps = SearchFormResultsInputProps & {
  searchTerm: string
  handleClick: () => void
  handleSearchClose: () => void
  additionalTopSuggestions: string
  additionalTopRelatedProducts: string
  sanitySearchResults: SearchResultSanity[]
}

export const withSearchFormResults =
  (Component: FC<SearchFormResultsOutputProps>) =>
  ({ name = "SearchFormResults", active, sanitySearchResults }: SearchFormResultsInputProps) => {
    const { searchTerm } = useSearch()
    const { globalStateReducer } = useApp()
    const { navigate } = useLocalisation()
    const {
      search: { additionalTopSuggestions, additionalTopRelatedProducts },
    } = usePage()
    const [, dispatch] = globalStateReducer

    const handleClick = () => {
      navigate(`/search?q=${searchTerm}`)
      handleSearchClose()
    }

    const handleSearchClose = () => {
      dispatch({
        type: "setActiveSearch",
        payload: false,
      })
    }

    Component.displayName = name

    return (
      <Component
        active={active}
        searchTerm={searchTerm}
        handleClick={handleClick}
        handleSearchClose={handleSearchClose}
        additionalTopSuggestions={additionalTopSuggestions}
        additionalTopRelatedProducts={additionalTopRelatedProducts}
        sanitySearchResults={sanitySearchResults}
      />
    )
  }
