export const useScrollPosition = () => {
  const scrollHidden = hidden => {
    if (hidden) {
      document.querySelector(`html`).classList.add(`is-hidden`)
    } else {
      document.querySelector(`html`).classList.remove(`is-hidden`)
    }
  }
  return { scrollHidden }
}
