import React, { useCallback } from "react"

import { UIProvider as _UIProvider, UIProviderProps as _UIProviderProps } from "@bared/ui"

import { useSanityClient } from "~/hooks/sanity"

type HandleTrackingEvent = NonNullable<_UIProviderProps["onTrackingEvent"]>

export interface UIProviderProps {
  children: React.ReactNode
}

export function UIProvider({ children }: UIProviderProps) {
  /** Handle tracking events emitted by the UI lib. */
  const handleTrackingEvent = useCallback<HandleTrackingEvent>(event => {
    // @todo send to gtm
  }, [])

  /** Sanity client resolved from context. */
  const sanityClient = useSanityClient()

  // @todo get actual locale from context
  return (
    <_UIProvider locale="en-AU" sanityClient={sanityClient} onTrackingEvent={handleTrackingEvent}>
      {children}
    </_UIProvider>
  )
}

UIProvider.displayName = "UIProvider"
