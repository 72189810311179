import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from "@apollo/client"
import { onError } from "@apollo/client/link/error"
import * as Sentry from "@sentry/gatsby"
import React from "react"
import config from "../../../config.default"

export const ShopifyProvider = ({ children }): JSX.Element => {
  const shopifyConfig = {
    ...config.stores[process.env["GATSBY_SHOPIFY_DEFAULT_SHOP"]],
    ...config.services.shopify,
  }

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        Sentry.captureException({ message, locations, path })
      })
    }

    if (networkError) {
      Sentry.captureException(networkError)
    }
  })

  const accessTokenLink = new HttpLink({
    uri: `https://${shopifyConfig.shopDomain}/api/${shopifyConfig.apiVersion}/graphql.json`,
    headers: {
      "X-Shopify-Storefront-Access-Token": shopifyConfig.accessToken,
    },
  })

  const links = [errorLink, accessTokenLink]

  const client = new ApolloClient({
    link: ApolloLink.from(links),
    cache: new InMemoryCache(),
  })

  return (
    <ApolloProvider client={client}>{children}</ApolloProvider>
  )
}