import { useApp } from "@hooks/useApp"
import { useLocalisation, useLocalisationContext } from "@hooks/useLocalisation"
import { useMeta } from "@hooks/useMeta"
import { useShopify } from "@hooks/useShopify"
import { ComponentProps } from "@ts/components"
import React, { FC } from "react"
import { BreadcrumbList, Organization, Product, WebPage } from "schema-dts"

type PossibleSchemaTypes = BreadcrumbList | Organization | WebPage | Product

export type MetaInputProps = ComponentProps & {
  data: unknown
  path: string
  routes: Record<string, string>
}

export type MetaOutputProps = ComponentProps & {
  mobileImage?: string
  desktopImage?: string
  languages: {
    primary: boolean
    href: string
    hrefLang: string
    rel: string
    language: string
  }[]
  canonical: {
    href: string
    rel: string
  }
  metatags: (
    | {
        name: string
        content: string
        property?: undefined
      }
    | {
        property: string
        content: string
        name?: undefined
      }
  )[]
  opengraph: {
    title: string
    image: {
      url: string
      description: string
    }
    description: string
    url: string
    facebookAppId: string
  }
  metadata: {
    robots: string
    schema: string
    document: string
    type: string
    metaLang: string
    siteName: string
    siteURL: string
    title: string
    description: string
    pageUrl: string
    googleSiteVerify: string
    author: string
  }
  schemas: PossibleSchemaTypes[]
}

export const withMeta =
  (Component: FC<MetaOutputProps>) =>
  ({ name = "Meta", data, path, routes }: MetaInputProps) => {
    const {
      config: {
        app: { url: baseUrl },
      },
    } = useApp()
    const { getData, getLanguages, getOpenGraphData, getTags, getSchemas } = useMeta()
    const { currentLocale } = useLocalisationContext()
    const { findNodeByLocale } = useLocalisation()

    const { languageCode: language } = currentLocale || {}

    const localisePageData = data => {
      const { pages, page, vip, test, sale } = data || {}
      const nodes = pages || page || vip || test || sale

      if (!nodes?.edges) {
        return data
      }

      return {
        ...data,
        page: findNodeByLocale(nodes),
      }
    }

    const sanitisedPath = path || ""
    const localisedData = localisePageData(data)
    const languages = getLanguages({ data: localisedData, url: baseUrl, path: sanitisedPath })
    const canonical = {
      href: `${baseUrl}${currentLocale?.baseRoute || ""}${path || ""}`,
      rel: "canonical",
    }
    const metadata = getData({ data: localisedData, url: sanitisedPath, language, routes })
    const opengraph = getOpenGraphData({ ...metadata })
    const metatags = getTags({ ...metadata, ...opengraph, lang: metadata?.metaLang })
    const schemas = getSchemas({ ...metadata, url: sanitisedPath })
    const { imageUrl } = useShopify()
    const mobileImage = metadata?.type === "product" ? imageUrl(metadata?.document?.image, 400) : null
    const desktopImage = metadata?.type === "product" ? imageUrl(metadata?.document?.image, 2000) : null

    Component.displayName = name

    return (
      <Component
        mobileImage={mobileImage}
        desktopImage={desktopImage}
        languages={languages}
        canonical={canonical}
        metatags={metatags}
        opengraph={opengraph}
        metadata={metadata}
        schemas={schemas}
      />
    )
  }
