import React from "react"
import { withNavigationHeader } from "./withNavigationHeader"
import MobileMenu from "./NavigationHeaderMobile"
import DesktopMenu from "./NavigationHeaderDesktop"

const NavigationHeader = withNavigationHeader(({ location, navigationRootPath }) => {
  return (
    <>
      <DesktopMenu location={location} navigationRootPath={navigationRootPath} />
      <MobileMenu location={location} navigationRootPath={navigationRootPath} />
    </>
  )
})

export default NavigationHeader
