import { useRichText } from "@hooks/useRichText"
import { useCustomerContext } from "@hooks/useCustomer"
import { useFirebase } from "@hooks/useFirebase"
import { useSettings } from "@hooks/useSettings"
import { useRoutes } from "@hooks/useRoutes"
import { useHelper } from "@hooks/useHelper"
import { ComponentProps, ResolvedLink } from "@ts/components"
import { Collection, Timer } from "@ts/sanity"
import React, { FC, useCallback, useEffect, useRef, useState } from "react"
import moment, { duration } from "moment"

type NormalisedTimer = Omit<Timer, "_key" | "heading" | "pdpHeading" | "link" | "collections"> & {
  heading: string
  pdpHeading: string
  link?: ResolvedLink
  collections?: Collection[]
}

export type CountdownTimerInputProps = ComponentProps & {
  layout?: "default" | "product"
  collection?: Collection
}

export type CountdownTimerOutputProps = CountdownTimerInputProps & {
  hour: string
  minutes: string
  seconds: string
  handleClick: (link: ResolvedLink) => void
  timers?: NormalisedTimer
}

export const withCountdownTimer =
  (Component: FC<CountdownTimerOutputProps>) =>
  ({ name = "CountdownTimer", layout, collection }: CountdownTimerInputProps) => {
    const { sale: liveSale } = useFirebase()
    const { sale: prevSale, countdownTimer } = useSettings()
    const { linkResolver } = useRoutes()
    const { handleClickOnCustomContentLink } = useHelper()
    const { customer } = useCustomerContext()
    const sale = liveSale || prevSale
    const activeTimers = useRef<NormalisedTimer>()
    const [hour, setHour] = useState("--")
    const [minutes, setMinutes] = useState("--")
    const [seconds, setSeconds] = useState("--")
    const [showTimer, setShowTimer] = useState(true)
    const { parseContent } = useRichText()

    const collectionHandle = collection?.shopify?.shopifyHandle ?? collection?.handle
    const isCustomerVip = !!customer?.tags?.some(tag => tag.includes("loyalty:VIP"))
    const isCustomerTest = !!customer?.tags?.some(tag => tag.includes("loyalty:TEST"))

    const countdownTimers = countdownTimer?.timers?.map(timer => ({
      ...timer,
      heading: parseContent(timer?.heading),
      pdpHeading: parseContent(timer?.pdpHeading),
      link: linkResolver(timer?.link?.customContentLink[0]),
    }))

    const hasMatchingCollection = useCallback(
      (collections?: Collection[]) => {
        if (!collections?.length || !collectionHandle) {
          return true
        }

        return collections?.some(c => c?.shopify?.shopifyHandle === collectionHandle)
      },
      [collectionHandle]
    )

    const enabledTimer = countdownTimers?.filter(timer => {
      const { collections, enabled, saleEnabled, saleType } = timer || {}
      if (!saleEnabled && enabled) {
        if (!hasMatchingCollection(collections)) return false
        if (saleType === "default") return true
        if (saleType === "test" && isCustomerTest) return true
        if (saleType === "vip-test" && isCustomerVip && isCustomerTest) return true

        return isCustomerVip ? saleType === "vip" : saleType === "sale"
      }
    })

    const saleEnabledTimer = countdownTimers?.filter(timer => {
      const { collections, saleEnabled } = timer || {}
      if (!hasMatchingCollection(collections)) return false
      if (!sale?.enabled) return false
      if (!saleEnabled) return false
      if (!["sale", "vip", "test", "vip-test"].includes(sale?.type)) return false

      return true
    })

    const updateDuration = () => {
      const differenceOfEnabledTimer = moment(enabledTimer?.[0]?.timer).diff(moment().local())
      const differenceOfSaleEnabledTimer = moment(saleEnabledTimer?.[0]?.timer).diff(moment().local())
      const isSaleTimerActive = differenceOfEnabledTimer < differenceOfSaleEnabledTimer && differenceOfEnabledTimer < 0
      const currentTimer = isSaleTimerActive ? saleEnabledTimer?.[0] : enabledTimer?.[0]

      activeTimers.current = currentTimer

      const difference = duration(moment(currentTimer?.timer).local().diff(moment().local())) || 0
      const time = duration(difference)

      if (time.milliseconds() < 0) {
        setShowTimer(false)
      } else {
        setShowTimer(true)
        setHour((time.days() * 24 + time.hours()).toLocaleString("en-AU", { minimumIntegerDigits: 2 }))
        setMinutes(time.minutes().toLocaleString("en-AU", { minimumIntegerDigits: 2 }))
        setSeconds(time.seconds().toLocaleString("en-AU", { minimumIntegerDigits: 2 }))
      }
    }

    useEffect(() => {
      const timer = setTimeout(updateDuration, 1000)
      return () => clearTimeout(timer)
    }, [updateDuration])

    useEffect(() => {
      updateDuration()
    }, [])

    if (!showTimer || !activeTimers.current) {
      return null
    }

    Component.displayName = name
    return (
      <Component
        layout={layout}
        timers={activeTimers.current}
        handleClick={handleClickOnCustomContentLink}
        hour={hour}
        minutes={minutes}
        seconds={seconds}
      />
    )
  }
