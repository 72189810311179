import { GooglePlacesContext } from "@providers/global/google-places"
import { useJsApiLoader } from "@react-google-maps/api"
import * as Sentry from "@sentry/gatsby"
import { useCallback, useContext, useEffect, useState } from "react"
import usePlacesAutocomplete, { ClearSuggestions, getGeocode, SetValue, Suggestions } from "use-places-autocomplete"
import { useApp } from "./useApp"
import { useCheckout } from "./useCheckout"
import { useCore } from "./useCore"

export type UseGooglePlaces = {
  address?: AddressDetails
  clearAddress: () => void
  clearSuggestions: ClearSuggestions
  error: boolean
  getGeocode: (args: any) => AddressDetails
  isGeocoding: boolean
  ready: boolean
  setValue: SetValue
  suggestions: Suggestions
  value: string
}

export type AddressDetails = {
  streetNo?: string
  street?: string
  suburb?: string
  postcode?: string
  state?: string
  country?: string
  formatted_address?: string
}

const libraries: "places"[] = ["places"]

export const useGooglePlacesContext = () => {
  const googlePlaces: any = useContext(GooglePlacesContext)
  return { ...googlePlaces }
}

export const useGooglePlaces = () => {
  const [isGeocoding, setIsGeocoding] = useState<boolean>(false)
  const { address, error, placeId, setAddress, setError, setPlaceId } = useGooglePlacesContext()
  const { updateAttributes, updateShippingAddress } = useCheckout()
  const {
    config: {
      services: { googleMaps },
      settings: { keys },
    },
  } = useApp()

  const {
    helpers: { storage },
  } = useCore()

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleMaps?.apiKey,
    libraries,
  })

  const { init, ready, value, suggestions, setValue, clearSuggestions } = usePlacesAutocomplete({
    debounce: 300,
  })

  const handleGetGeocode = useCallback(
    async (args: any, setFormattedAddress: boolean = false): Promise<void> => {
      setIsGeocoding(true)

      try {
        const details = await getGeocode({
          ...args,
        })

        const { place_id, address_components, formatted_address } = details[0]
        const [unitNo] = address_components?.filter(({ types }) => types?.includes("subpremise")) ?? []
        const [streetNo] = address_components?.filter(({ types }) => types?.includes("street_number")) ?? []
        const [street] = address_components?.filter(({ types }) => types?.includes("route")) ?? []
        const [suburb] = address_components?.filter(({ types }) => types?.includes("locality") || types?.includes("postal_town")) ?? []
        const [state] = address_components?.filter(({ types }) => types?.includes("administrative_area_level_1")) ?? []
        const [postcode] = address_components?.filter(({ types }) => types?.includes("postal_code")) ?? []
        const [country] = address_components?.filter(({ types }) => types?.includes("country")) ?? []

        await updateAttributes({ allowPartialAddresses: true })
        await updateShippingAddress({
          address1: `${streetNo?.short_name} ${street?.short_name}`,
          address2: unitNo?.short_name,
          city: suburb?.short_name,
          country: country?.short_name,
          province: state?.short_name,
          zip: postcode?.short_name,
        })

        setPlaceId(place_id)

        setAddress({
          streetNo: streetNo?.short_name,
          street: street?.short_name,
          suburb: suburb?.short_name,
          state: state?.short_name,
          postcode: postcode?.short_name,
          country: country?.short_name,
          formatted_address,
        })

        if (setFormattedAddress) {
          setValue(formatted_address)
        }

        storage?.set(keys?.place_id, place_id)
        setError(false)
      } catch (e) {
        Sentry.captureException(e)
        setError(true)
      } finally {
        setIsGeocoding(false)
      }
    },
    [storage, getGeocode, updateShippingAddress, setPlaceId]
  )

  useEffect(() => {
    if (isLoaded) {
      init()
    }
  }, [isLoaded])

  useEffect(() => {
    if (!ready) {
      return
    }

    const savedPlaceId = storage?.get(keys?.place_id)
    if (!savedPlaceId) {
      return
    }

    handleGetGeocode(
      {
        placeId: savedPlaceId,
      },
      true
    )
  }, [ready])

  return {
    address,
    setAddress,
    clearSuggestions,
    error,
    getGeocode: handleGetGeocode,
    isGeocoding,
    placeId,
    ready,
    setValue,
    suggestions,
    value,
  }
}
