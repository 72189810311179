import { useQuery } from "@tanstack/react-query"

import type { ServerSettingsResponse } from "@/api/server-settings"

/**
 * Return server generated settings.
 *
 * This hook makes a request to the server-settings API and returns the settings.
 *
 * You can use hook in multiple places, as requests are deduped and cached such
 * that only one request to the API is ever made.
 *
 * @see @/api/server-settings.ts
 */
export function useServerSettings() {
  const { isLoading, error, data } = useQuery({
    queryKey: ["server-settings"],
    queryFn: () =>
      fetch("/api/server-settings")
        .then(res => res.json())
        .then(json => json as ServerSettingsResponse),
  })

  if (error) console.error(error)

  return { isLoading, error, data }
}
