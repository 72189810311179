import { withFocusRing } from "@components/Accessibility/FocusRing/FocusRing"
import { Icon } from "@components/Icon"
import { styledWithInvalidProps } from "@components/Styled/StyledWithInvalidProps"
import React from "react"
import ScrollLock, { TouchScrollable } from "react-scrolllock"
import tw, { TwStyle } from "twin.macro"
import { ModalLayout, ModalsPopupOutputProps, withModalsPopup } from "./withModalsPopup"

const wrapperStyles: Record<ModalLayout, TwStyle> = {
  default: tw`max-w-[1000px]`,
  subscribe: tw`fixed bottom-0 left-0 px-0 shadow-large max-w-[300px] md:max-w-[630px]`,
  upsell: tw`max-w-[700px]`,
  image: tw`max-w-80vh max-h-full-vh px-mega xl:px-mega`,
  "size-guide": tw`top-0 overflow-x-hidden overflow-y-auto max-w-[800px] md:top-auto md:max-h-70vh`,
  notify: tw`max-w-[470px]`,
  "gift-card": tw`max-w-[700px] xl:px-midi`,
  "wishlist-share": tw`max-w-[630px]`,
  review: tw`max-h-full px-zero md:max-h-70vh`,
}

const iconStyles: Partial<Record<ModalLayout, TwStyle>> = {
  image: tw`text-white`,
}

const Container = styledWithInvalidProps("div")(({ active }: Partial<ModalsPopupOutputProps>) => [
  tw`fixed inset-0 z-20 flex items-center justify-center transition-layout mt-[86px] md:mt-peta`,
  active ? tw`visible duration-slow` : tw`invisible`,
])

const Bg = styledWithInvalidProps("div")(({ active }: Pick<ModalsPopupOutputProps, "active">) => [
  tw`fixed inset-0 transition-opacity cursor-pointer bg-primary top-[86px] opacity-90 md:top-[123px]`,
  active ? tw`opacity-50 duration-slow` : tw`opacity-0`,
])

const Wrapper = styledWithInvalidProps("div")(({ active, layout }: Pick<ModalsPopupOutputProps, "active" | "layout">) => [
  tw`absolute z-20 w-full overflow-x-hidden overflow-y-auto transition-transform max-w-[1000px] px-midi max-h-70vh xl:px-0`,
  active ? tw`translate-y-0 opacity-100 pointer-events-auto duration-slow` : tw`translate-y-full opacity-0 pointer-events-none`,
  wrapperStyles[layout],
])

const Content = tw.div`
  relative flex flex-col items-center justify-center bg-white leading-caption
`

const StyledIcon = styledWithInvalidProps(Icon)(({ layout }: Pick<ModalsPopupOutputProps, "layout">) => [
  tw`block pt-nano pr-nano md:pt-micro md:pr-micro`,
  iconStyles[layout],
])

const Close = withFocusRing(tw.button`
  absolute top-0 right-0 cursor-pointer z-50 hover:opacity-70
`)

const ModalsPopup = withModalsPopup(({ active, children, handleClick, layout, "data-testid": dataTestId }) => {
  if (layout === "subscribe") {
    return (
      <Wrapper active={active} className="hide-scrollbar" dataTestId={dataTestId} layout={layout}>
        <Content>
          <Close onClick={handleClick} title="close">
            <StyledIcon name="close" size="xsmall" />
          </Close>
          {children}
        </Content>
      </Wrapper>
    )
  }

  return (
    <Container active={active}>
      <Bg active={active} onClick={handleClick} data-testid="modal-popup-bg" />
      <ScrollLock isActive={active} />
      <TouchScrollable>
        <Wrapper layout={layout} className="hide-scrollbar" active={active} data-testid={dataTestId}>
          <Content>
            <Close onClick={handleClick} title="close">
              <StyledIcon name="close" size="xsmall" layout={layout} />
            </Close>
            {children}
          </Content>
        </Wrapper>
      </TouchScrollable>
    </Container>
  )
})

export default ModalsPopup
