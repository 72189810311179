import { useCheckoutContext } from "@hooks/useCheckout"
import { ComponentProps } from "@ts/components"
import React, { FC } from "react"
import { DonationItemProps } from "./withCartDonationItemCard"

export type CartDonationItemsInputProps = ComponentProps & {
  items: DonationItemProps[]
  layout: string
}
export type CartDonationItemsOutputProps = CartDonationItemsInputProps

export const withCartDonationItems =
  (Component: FC<CartDonationItemsOutputProps>) =>
  ({ name = "CartDonationItems", items, layout }: CartDonationItemsInputProps) => {
    const { checkout } = useCheckoutContext()

    const lineItemHandles = checkout?.lineItems?.map(lineItem => lineItem.variant?.product?.handle) || []
    const mappedItems = items?.map(item => ({
      ...item,
      selected: lineItemHandles.includes(item.shopify?.shopifyHandle),
    }))

    Component.displayName = name
    return <Component items={mappedItems} layout={layout} />
  }
