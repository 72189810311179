// Obviously the admin API should not be called on the frontend, but sometimes we deal with types
// sent to cloud functions which do use the admin API

export enum CustomerMarketingOptInLevel {
  SINGLE_OPT_IN = "SINGLE_OPT_IN",
  CONFIRMED_OPT_IN = "CONFIRMED_OPT_IN",
  UNKNOWN = "UNKNOWN",
}

export interface CustomerSmsMarketingConsentState {
  marketingOptInLevel: CustomerMarketingOptInLevel
  marketingState: CustomerSmsMarketingState
}

export enum CustomerSmsMarketingState {
  NOT_SUBSCRIBED = "NOT_SUBSCRIBED",
  PENDING = "PENDING",
  SUBSCRIBED = "SUBSCRIBED",
  UNSUBSCRIBED = "UNSUBSCRIBED",
  REDACTED = "REDACTED",
}
