import { useApp } from "@hooks/useApp"
import { useCore } from "@hooks/useCore"
import { useScrollPosition } from "@hooks/useScrollPosition"
import { ComponentProps } from "@ts/components"
import React, { Dispatch, FC, memo, SetStateAction, useEffect } from "react"

export type ModalLayout = "default" | "gift-card" | "image" | "notify" | "size-guide" | "subscribe" | "upsell" | "wishlist-share" | "review"

export type ModalsPopupInputProps = ComponentProps & {
  active: boolean
  layout?: ModalLayout
  onClose?: () => void
  setActive: Dispatch<SetStateAction<boolean>>
}

export type ModalsPopupOutputProps = Omit<ModalsPopupInputProps, "onClose" | "layout"> & {
  handleClick: () => void
  layout: ModalLayout
  shareWishlist?: boolean
}

export const withModalsPopup = (Component: FC<ModalsPopupOutputProps>) =>
  memo(({ name = "ModalsPopup", active, setActive, onClose, children, layout = "default" }: ModalsPopupInputProps) => {
    const { scrollHidden } = useScrollPosition()
    const {
      helpers: { storage },
    } = useCore()
    const {
      config: {
        settings: { keys },
      },
    } = useApp()

    const handleKeyDown = e => {
      if (e.key !== "Escape") {
        return
      }
      setActive(false)
      if (onClose) {
        onClose()
      }
    }

    const handleClick = () => {
      setActive(false)
      if (layout === "subscribe") {
        storage.set(keys?.subscribe, "hidden")
      }
      if (onClose) {
        onClose()
      }
    }

    useEffect(() => {
      scrollHidden(active)
    }, [active, scrollHidden])

    useEffect(() => {
      window.addEventListener("keydown", handleKeyDown)
      return () => window.removeEventListener("keydown", handleKeyDown)
    }, [active])

    Component.displayName = name
    return (
      <Component layout={layout} active={active} setActive={setActive} handleClick={handleClick}>
        {children}
      </Component>
    )
  })
