import { TemplateCollection, TemplateProduct } from "@ts/sanity"
import { graphql, useStaticQuery } from "gatsby"

export type UseTemplate = {
  product: TemplateProduct
  collection: TemplateCollection
}

export const useTemplate = (): UseTemplate => {
  const { product, collection } = useStaticQuery(graphql`
    query TEMPLATE {
      product: sanityTemplateProduct {
        settingLowStockThreshold
        settingShowRelatedProducts
        settingEnableShopifyRecommendations
        settingReviewInitalQuantity
        settingReviewsLoadMoreStep
        additionalAddingToCart
        additionalAddToCart
        additionalOutOfStock
        additionalNotify
        additionalFindInStore
        additionalFindInStoreStockLevels
        additionalErrorTitle
        additionalErrorDescription
        additionalErrorButton
        additionalReviewsFormSubmitingText
        additionalReviewsFormSubmitText
        additionalReviewsFormPlaceholderName
        additionalReviewsFormPlaceholderEmail
        additionalReviewsFormPlaceholderTitle
        additionalReviewsFormPlaceholderContent
        additionalReviewsFormErrorMessage
        additionalReviewsFormSuccessTitle
        additionalReviewsFormSuccessMessage
        additionalReviewsLoadMoreText
        additionalReviewsLoadingMoreText
        additionalQuestionsFormHelperText: _rawAdditionalQuestionsFormHelperText(resolveReferences: { maxDepth: 2 })
        additionalSuccessTitle
        additionalSuccessDescription
        additionalSuccessCheckoutButtonText
        addtionalSuccessViewCartButtonText
        addtionalSuccessKeepShoppingButtonText
        additionalPastDateMessage
        additionalInvalidDateMessage
        mensFootbedImage: _rawMensFootbedImage(resolveReferences: { maxDepth: 2 })
        womensFootbedImage: _rawWomensFootbedImage(resolveReferences: { maxDepth: 2 })
      }
      collection: sanityTemplateCollection {
        additionalLoadMoreButton
        additionalLoadingMoreButton
        additionalCount
      }
    }
  `)

  return {
    product,
    collection,
  }
}
