import React from "react"

import { useLocation } from "../../../hooks/useLocation"

export const withCartLineItemPrice =
  Component =>
  ({ name = "CartLineItemPrice", price, discountAllocationsAmount, className, layout }) => {
    const { amount: priceAmount, currencyCode } = price
    const { checkoutCurrency } = useLocation()
    const priceAfterDiscount = (priceAmount - discountAllocationsAmount).toFixed(2)
    Component.displayName = name
    return (
      <Component
        price={Number(priceAmount)}
        priceAfterDiscount={priceAfterDiscount}
        onSale={discountAllocationsAmount > 0}
        currencyCode={currencyCode}
        checkoutCurrency={checkoutCurrency}
        className={className}
        layout={layout}
      />
    )
  }
