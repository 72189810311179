import { SkipLink } from "@components/Accessibility/SkipLink/SkipLink"
import CartDrawer from "@components/Cart/Drawer/CartDrawer"
import CountdownTimer from "@components/CountdownTimer/CountdownTimer"
import FooterSection from "@components/Footer/FooterSection"
import Geofencing from "@components/Geofencing/Geofencing"
import Header from "@components/Header/Header"
import { Meta } from "@components/Meta/Meta"
import SubscribeFormPopup from "@components/Subscribe/SubscribeFormPopup"
import React from "react"
import AppStyles from "./AppStyles"
import { withApp } from "./withApp"

export const App = withApp(({ children, data, routes, title, location, navigationRootPath, path, hasServerData }) => (
  <>
    <AppStyles />
    <SkipLink href="#content-main">Skip Navigation</SkipLink>
    {!hasServerData && <Meta data={data} routes={routes} title={title} path={path} />}
    <Header location={location} navigationRootPath={navigationRootPath} />
    <main className="flex-1 pt-[86px] md:pt-[122px]" id="content-main">
      <CountdownTimer />
      {children}
    </main>
    <FooterSection />
    <CartDrawer />
    <SubscribeFormPopup />
    <Geofencing />
  </>
))
