import { useAnalytics } from "@hooks/useAnalytics"
import { useApp } from "@hooks/useApp"
import { useCheckout, useCheckoutContext } from "@hooks/useCheckout"
import { useCore } from "@hooks/useCore"
import { useCustomerAccessToken, useCustomerContext, useCustomerSession } from "@hooks/useCustomer"
import { useLocalisationContext } from "@hooks/useLocalisation"
import { useMaintenance } from "@hooks/useMaintenance"
import { useSale } from "@hooks/useSale"
import { useSettings } from "@hooks/useSettings"
import { globalHistory } from "@reach/router"
import { ComponentProps } from "@ts/components"
import { PageProps } from "gatsby"
import React, { FC, useEffect } from "react"

export type AppPageContextProps = {
  languageCode: string
  navigationRootPath?: string
  rawPath: string
}

export type AppInputProps = ComponentProps &
  Omit<PageProps<Record<string, any>, AppPageContextProps>, "children"> & {
    settings: any
    hasServerData: boolean
  }

export type AppOutputProps = AppInputProps & {
  routes: Record<string, string>
  title: string
  navigationRootPath?: string
  path: string
}

export const withApp =
  (Component: FC<AppOutputProps>) =>
  ({ name = "App", location, data, children, ...props }: AppInputProps) => {
    const {
      helpers: { storage },
    } = useCore()
    const {
      config: {
        settings: { keys },
      },
    } = useApp()
    const { checkout } = useCheckoutContext()
    const { getCheckout, createCheckout } = useCheckout()
    const { customer } = useCustomerContext()
    const { getCustomer } = useCustomerAccessToken()
    const { createSessionId } = useCustomerSession()
    const { isSaleActive } = useSale()
    const isSale = isSaleActive()
    const {
      organisation: { title },
    } = useSettings()
    const { currentLocale, isResolvingPreferredLocale } = useLocalisationContext()
    const { defaultCountryCode } = currentLocale || {}

    const { active } = useMaintenance(location)
    const routes = props?.settings?.routes

    useEffect(() => {
      return globalHistory.listen(({ location }) => {
        const scrollRestorationRoutes = ["/products/", "/collections/"]

        if (!scrollRestorationRoutes.some(route => location.pathname.match(route))) {
          storage.remove(keys.collection_scroll_position)
        }
      })
    }, [])

    useEffect(() => {
      const checkoutCountryCode = checkout?.buyerIdentity?.countryCode
      if (checkoutCountryCode === defaultCountryCode || isResolvingPreferredLocale) {
        return
      }

      createCheckout(defaultCountryCode)
    }, [checkout, defaultCountryCode, isResolvingPreferredLocale])

    useEffect(() => {
      getCheckout(isSale)
    }, [isSale])

    useEffect(() => {
      if (customer) {
        return
      }

      getCustomer()
      createSessionId()
    }, [customer])

    Component.displayName = name

    return active ? (
      <>{children}</>
    ) : (
      <Component
        {...props}
        data={data}
        routes={routes}
        title={title}
        location={location}
        navigationRootPath={props?.pageContext?.navigationRootPath}
        path={props?.pageContext?.rawPath}
      >
        {children}
      </Component>
    )
  }
