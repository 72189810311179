import React, { type Context, type MutableRefObject, useMemo, useRef } from "react"

export type UIContextProps = {
  headerRef?: MutableRefObject<HTMLElement>
  topBarRef?: MutableRefObject<HTMLElement>
}

export const UIContext: Context<UIContextProps> = React.createContext<UIContextProps>({})

export const UIProvider = ({ children }) => {
  const headerRef = useRef(null)
  const topBarRef = useRef(null)

  const values = useMemo(
    () => ({
      headerRef,
      topBarRef,
    }),
    [headerRef, topBarRef]
  )

  return <UIContext.Provider value={values}>{children}</UIContext.Provider>
}
