import { useCheckoutContext } from "@hooks/useCheckout"
import { usePage } from "@hooks/usePage"
import { useShopify } from "@hooks/useShopify"
import { ComponentProps } from "@ts/components"
import React, { FC } from "react"

export type CartReturnsPolicyInputProps = ComponentProps

export type CartReturnsPolicyOutputProps = CartReturnsPolicyInputProps & {
  finalSaleItemReturnPolicy: string
  hasFinalSaleItems: boolean
  hasSaleItems: boolean
  saleItemReturnPolicy: string
}

export const withCartReturnsPolicy =
  (Component: FC<CartReturnsPolicyOutputProps>) =>
  ({ name = "CartReturnsPolicy" }: CartReturnsPolicyInputProps) => {
    const { checkout } = useCheckoutContext()
    const {
      cart: { saleItemReturnPolicy, finalSaleItemReturnPolicy },
    } = usePage()

    const { getBadgeFromTags } = useShopify()
    const { lineItems } = checkout || {}
    const hasSaleItems = lineItems.reduce((prev, curr) => {
      return prev || curr?.variant?.isDiscounted
    }, false)
    const hasFinalSaleItems = lineItems.reduce((prev, curr) => {
      const badge = getBadgeFromTags(curr?.variant?.product?.tags)
      return prev || badge?.name === "Final Sale"
    }, false)

    Component.displayName = name

    if ((!saleItemReturnPolicy || !hasSaleItems) && (!finalSaleItemReturnPolicy || !hasFinalSaleItems)) {
      return null
    }

    return (
      <Component
        saleItemReturnPolicy={saleItemReturnPolicy}
        hasSaleItems={hasSaleItems}
        finalSaleItemReturnPolicy={finalSaleItemReturnPolicy}
        hasFinalSaleItems={hasFinalSaleItems}
      />
    )
  }
