import { Accordion } from "@components/Accordion/Accordion"
import { Link } from "@components/Link/Link"
import React from "react"
import tw from "twin.macro"
import { withNavigationFooter } from "./withNavigationFooter"

const Wrapper = tw.div`
  w-full xl:w-3/4
`
const Desktop = tw.div`
  hidden w-full justify-between flex-wrap pb-mini md:flex 
`
const Mobile = tw.div`
  block md:hidden
`
const DesktopList = tw.div`
  block pb-mini md:w-1/2 xl:w-1/4 
`
const MobileList = tw.div`
  pb-maxi
`
const Title = tw.p`
  block text-hint leading-body mb-mini mr-1 uppercase font-medium tracking-heading
`
const ListItem = tw(Link)`
  block text-body leading-[1.5] mb-mini transition-presentation mr-mini text-primary hover:text-secondary
`

const NavigationFooter = withNavigationFooter(({ items }) => (
  <Wrapper>
    <Desktop>
      {items?.map(item => (
        <DesktopList key={item?.title}>
          <Title>{item?.title}</Title>
          {item?.items?.map(listItem => (
            <ListItem key={listItem.title} to={listItem.url}>
              {listItem.title}
            </ListItem>
          ))}
        </DesktopList>
      ))}
    </Desktop>
    <Mobile>
      {items?.map(item => (
        <Accordion key={item?.title} title={item?.title} size="footer" icon={item?.items?.length > 0}>
          <MobileList>
            {item?.items?.map(listItem => (
              <ListItem key={listItem.title} to={listItem.url}>
                {listItem.title}
              </ListItem>
            ))}
          </MobileList>
        </Accordion>
      ))}
    </Mobile>
  </Wrapper>
))

export default NavigationFooter
