import { useApp } from "@hooks/useApp"
import { useCore } from "@hooks/useCore"
import { useLocalisationContext } from "@hooks/useLocalisation"
import React, { createContext, useState, useEffect } from "react"

export type LocationContext = {
  country?: string
  locationCurrency?: string
  locationCurrencyCountryCode?: string
  checkoutCurrency?: string
  shopifyStore?: string
  shopifyStoreDomain?: string
  updateLocation?: (countryCode: string) => void
}

export const LocationContext = createContext<LocationContext>({})

export const LocationProvider = ({ children }) => {
  const {
    helpers: { storage },
  } = useCore()
  const { currentLocale } = useLocalisationContext()
  const { config } = useApp()
  const { defaultCountryCode, defaultCurrency, countryCodeCurrencyMap } = currentLocale
  const {
    stores,
    services,
    settings: { keys },
  } = config
  const { location: locationService, shopify } = services
  const locationCurrency = defaultCurrency
  const locationCurrencyCountryCode = defaultCountryCode
  const defaultStoreName = shopify?.defaultShopName
  const defaultStore = stores[defaultStoreName]
  const defaultStoreDomain = defaultStore?.shopDomain
  const shopifyStore = storage.get(keys?.shopify) || defaultStoreName
  const shopifyStoreDomain = stores[shopifyStore]?.shopDomain || defaultStoreDomain
  const checkoutCurrency = defaultStore?.checkoutCurrency
  const [settings, setSettings] = useState({
    country: storage.get(keys?.country) || "",
    locationCurrency,
    locationCurrencyCountryCode,
    checkoutCurrency,
    shopifyStore,
    shopifyStoreDomain,
  })

  const updateLocation = (countryCode: string) => {
    const countryCurrency = countryCodeCurrencyMap[countryCode]
    const locationCurrency = countryCurrency || defaultCurrency
    const locationCurrencyCountryCode = countryCurrency ? countryCode : defaultCountryCode

    setSettings(prevState => ({
      ...prevState,
      country: countryCode,
      checkoutCurrency,
      locationCurrency,
      locationCurrencyCountryCode,
    }))
  }

  useEffect(() => {
    if (settings?.country && settings?.shopifyStore) {
      storage.set(keys?.country, settings?.country)
      storage.set(keys?.shopify, settings?.shopifyStore)
    } else {
      fetch(locationService?.serviceUrl)
        .then(res => res.json())
        .then(result => updateLocation(result[locationService?.countryFieldKey] || defaultCountryCode))
        .catch(() => updateLocation(defaultCountryCode))
    }
  }, [settings])

  useEffect(() => {
    updateLocation(settings.country)
  }, [defaultCountryCode, defaultCurrency, countryCodeCurrencyMap])

  return <LocationContext.Provider value={settings}>{children}</LocationContext.Provider>
}
