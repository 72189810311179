import { Link } from "@components/Link/Link"
import React from "react"
import tw, { styled } from "twin.macro"
import AnchorLink from "react-anchor-link-smooth-scroll"

import { withContentButton } from "./withContentButton"
import { StyledButton } from "../Styled/Button"

const styles = {
  colour: {
    default: "secondary",
    green: "secondary",
    charcoal: "primary",
    white: "lightest",
  },
  layout: {
    secondary: "transparent",
  },
  alignment: {
    default: tw`justify-start`,
    left: tw`justify-start`,
    center: tw`justify-center`,
    right: tw`justify-end`,
  },
}

const Wrapper = styled.div`
  ${tw`flex w-full`}
  ${({ alignment }) => (alignment && styles.alignment[alignment] ? styles.alignment[alignment] : styles.alignment.default)}
`

const Button = styled(StyledButton)`
  ${tw`w-full text-center capitalize p-mini m-mini max-w-exa sm:max-w-[200px]`}
  & {
    text-decoration: none !important;
  }
`

const ContentButton = withContentButton(({ link, colour, alignment, layout, isAnchorLink }) => {
  return (
    <Wrapper alignment={alignment}>
      <Button
        colour={colour && styles.colour[colour] ? styles.colour[colour] : styles.colour.default}
        layout={layout}
        as={link.external ? `a` : isAnchorLink ? AnchorLink : Link}
        to={link.external || isAnchorLink ? undefined : link.url}
        href={link.external || isAnchorLink ? link.url : undefined}
        target="_blank"
        offset="100"
      >
        {link.title}
      </Button>
    </Wrapper>
  )
})

export default ContentButton
