import { StyledH4 } from "@components/Styled/Text"
import React from "react"
import tw from "twin.macro"
import { CartUpsellsCard } from "./CartUpsellsCard"
import { CartUpsellsOutputProps, withCartUpsells } from "./withCartUpsells"

const Container = tw.section``

const Title = tw(StyledH4)`text-[18px] font-display leading-h4 my-0 text-left bg-primary bg-opacity-10 p-midi`
const CardsWrapper = tw.div``

export const CartUpsells = withCartUpsells(({ title, upsells }: CartUpsellsOutputProps) => (
  <Container>
    <Title>{title}</Title>
    <CardsWrapper>
      {upsells?.map((product, index) => <CartUpsellsCard key={product.handle} product={product} isLast={index === upsells?.length - 1} />)}
    </CardsWrapper>
  </Container>
))
