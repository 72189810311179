import React from "react"
import { Script } from "gatsby"

import * as Sentry from "@sentry/gatsby"

import type { GatsbyBrowser } from "@ts/gatsby"

import { App } from "@components/App/App"
import { LiveChatScripts } from "@components/LiveChat/LiveChatScripts"
import { LiveChatDataLayer } from "@components/LiveChat/LiveChatDataLayer"

import { GlobalProviders } from "@providers/global"
import { LocalisedProviders } from "@providers/localised"

import "./src/tailwind.css"

import config from "./config.default.mjs"

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({ element }) => (
  <>
    <GlobalProviders>
      {element}
      <LiveChatScripts />
    </GlobalProviders>
    {/**
     * Required for Vimeo videos embedded into richtext.
     * Should only be included once (hence why it's here, not in the richtext).
     * @todo Find a more performant way of including this only when needed.
     */}
    <Script src="https://player.vimeo.com/api/player.js" />
  </>
)

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({ element, props }) => {
  const data = props.serverData ?? props.data
  const languageCode = props.pageContext.languageCode

  return (
    <LocalisedProviders languageCode={languageCode}>
      <App {...props} data={data} settings={config.settings} hasServerData={!!props.serverData}>
        {element}
      </App>
      <LiveChatDataLayer />
    </LocalisedProviders>
  )
}

export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = ({ location, prevLocation }) => {
  if (
    location?.pathname !== prevLocation?.pathname &&
    ((prevLocation?.pathname.includes(config.settings.routes.COLLECTION) && location?.pathname.includes(config.settings.routes.COLLECTION)) ||
      (prevLocation?.pathname.includes(config.settings.routes.COLLECTION) && location?.pathname.includes(config.settings.routes.SEARCH)) ||
      (prevLocation?.pathname.includes(config.settings.routes.SEARCH) && location?.pathname.includes(config.settings.routes.COLLECTION)))
  )
    window.dispatchEvent(new Event(`refreshSearch`))
}

export const onClientEntry: GatsbyBrowser["onClientEntry"] = () => {
  if (!window) {
    return
  }

  window.addEventListener("unhandledrejection", (event: PromiseRejectionEvent) => {
    const reasonsToForceReload = [/loading chunk \d* failed./i, /we couldn't find the correct component chunk with the name component--src/i]

    const hasValidErrors = reasonsToForceReload.reduce((prev, curr) => prev || curr.test(event.reason), false)

    if (!hasValidErrors) {
      return
    }

    const forceReloadKey = "__fr"
    const queryStringParams = new URLSearchParams(window.location.search)
    const queryStringKeyValues = [...queryStringParams.entries()]
    const hasForceReloadParam = queryStringKeyValues.find(([key]) => key === forceReloadKey)
    const newQueryString = [...queryStringKeyValues, [forceReloadKey, Date.now()]].reduce(
      (prev, [key, value]) => `${prev}${prev === "?" ? "" : "&"}${key}=${value}`,
      "?"
    )

    if (!hasForceReloadParam) {
      Sentry.captureMessage("Chunk load error failed, attempting reload", {
        level: "warning",
        extra: {
          event,
        },
      })
      window.location.href = `${window.location.protocol}//${window.location.host}${window.location.pathname}${newQueryString}`
    } else {
      Sentry.captureMessage("Chunk load error force reload failed", {
        level: "fatal",
        extra: {
          event,
        },
      })
    }
  })
}
