import { fromZodError } from "zod-validation-error"

/**
 * Validate the given value against the zod schema, throw a friendly error on failure.
 *
 * @template {import("zod").ZodSchema} T
 * @param {unknown} value
 * @param {{ label: string, schema: T }} params
 * @returns {import("zod").infer<T>}
 */
export function validate(value, { label, schema }) {
  try {
    return schema.parse(value)
  } catch (e) {
    throw fromZodError(e, { prefix: label })
  }
}
