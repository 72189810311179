import { useApp } from "@hooks/useApp"
import { useCore } from "@hooks/useCore"
import * as Sentry from "@sentry/gatsby"
import { navigate as gatsbyNavigate } from "gatsby"
import React, { Context, Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react"

import type { ConfigLocalisation } from "@ts/config"

import { useServerSettings } from "@/hooks/useServerSettings"

export type LocalisationContext = {
  contextCountry?: string
  currentLocale?: ConfigLocalisation
  isResolvingPreferredLocale?: boolean
  localisation?: ConfigLocalisation[]
  navigate?: (url: string, options?: Record<string, string | boolean>) => void
  normaliseRoute?: (url: string, newBaseRoute?: string) => void
  preferredLocaleBasePath?: string
  setContextCountry?: Dispatch<SetStateAction<string>>
  setIsResolvingPreferredLocale?: Dispatch<SetStateAction<boolean>>
  enableGeolocation: boolean
}

export const LocalisationContext: Context<LocalisationContext> = React.createContext<LocalisationContext>({})

export interface LocalisationProviderProps {
  languageCode?: string
  children: React.ReactNode
}

export const LocalisationProvider: React.FC<LocalisationProviderProps> = ({ children, languageCode }) => {
  const {
    config: {
      localisation,
      settings: {
        keys: { geofencing_locale },
      },
    },
  } = useApp()
  const {
    helpers: { storage },
  } = useCore()

  const enabledLanguages = localisation?.filter(({ enabled }) => enabled)
  const preferredLocaleBasePath = storage.get(geofencing_locale)
  const baseRoutes = enabledLanguages?.map(locale => locale.baseRoute) || []
  const defaultLocale = enabledLanguages?.find(({ isDefault }) => isDefault)
  const activeLocale = enabledLanguages?.find(({ languageCode: code }) => code === languageCode)
  const currentLocale = activeLocale || defaultLocale
  const { baseRoute, defaultCountryCode } = currentLocale || {}
  const [contextCountry, setContextCountry] = useState<string>(defaultCountryCode)
  const [isResolvingPreferredLocale, setIsResolvingPreferredLocale] = useState(true)

  const serverSettings = useServerSettings()
  const enableGeolocation = !!serverSettings.data?.enableGeolocation

  const normaliseRoute = useCallback(
    (url: string, newBaseRoute?: string) => {
      const normalisedBaseRoute = newBaseRoute === "" ? "/" : newBaseRoute
      const targetBaseRoute = normalisedBaseRoute || baseRoute
      const result = `${targetBaseRoute}${baseRoutes?.reduce((prev, curr) => {
        return prev?.replace(new RegExp(`^${curr}`), "")
      }, url)}`

      return result
    },
    [baseRoute, baseRoutes]
  )

  const navigate = (url: string, options?: Record<string, string | boolean>) => {
    const { force, ...rest } = options || {}

    if (force) {
      gatsbyNavigate(url, rest)
      return
    }

    gatsbyNavigate(normaliseRoute(url), rest)
  }

  useEffect(() => {
    if (!enableGeolocation) return

    if (preferredLocaleBasePath === null || currentLocale.baseRoute === preferredLocaleBasePath) {
      return setIsResolvingPreferredLocale(false)
    }

    const newUrl = `${normaliseRoute(window.location.pathname, preferredLocaleBasePath)}${window.location.search}`
    navigate(newUrl, { force: true })

    Sentry.addBreadcrumb({
      type: "info",
      category: "providers/localised/localisation",
      data: {
        action: "Redirect",
        pathname: window.location.pathname,
        search: window.location.search,
        newUrl,
      },
    })

    setIsResolvingPreferredLocale(false)
  }, [preferredLocaleBasePath, currentLocale, enableGeolocation])

  useEffect(() => {
    setContextCountry(currentLocale.defaultCountryCode)
  }, [currentLocale])

  const values = useMemo<LocalisationContext>(
    () => ({
      contextCountry,
      currentLocale,
      localisation: enabledLanguages,
      navigate,
      normaliseRoute,
      isResolvingPreferredLocale,
      preferredLocaleBasePath,
      setContextCountry,
      setIsResolvingPreferredLocale,
      enableGeolocation,
    }),
    [
      contextCountry,
      currentLocale,
      enabledLanguages,
      navigate,
      normaliseRoute,
      isResolvingPreferredLocale,
      preferredLocaleBasePath,
      setContextCountry,
      setIsResolvingPreferredLocale,
      enableGeolocation,
    ]
  )

  return <LocalisationContext.Provider value={values}>{children}</LocalisationContext.Provider>
}
