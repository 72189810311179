import { useCallback, useState } from "react"
import { useApp } from "./useApp"
import { useCore } from "./useCore"
import { useLocalisation } from "./useLocalisation"
import { useRoutes } from "./useRoutes"
import { useSettings } from "./useSettings"

const INITIAL_STATE = {
  password: "",
}

export const useMaintenance = location => {
  const {
    config: {
      settings: { keys, params, routes },
    },
  } = useApp()
  const {
    helpers: { isBrowser, decodeBase64, encodeBase64, storage },
  } = useCore()
  const { navigate } = useLocalisation()
  const { getUrlParameter } = useRoutes()
  const {
    maintenance: { enabled, password },
  } = useSettings()
  const [error, setError] = useState(false)
  const [data, setData] = useState(INITIAL_STATE)

  const saved = storage.get(keys?.password)
  const authorised = saved && password === decodeBase64(saved)
  const active = location.pathname.startsWith(routes.PASSWORD)
  const navigateToSite = () => navigate(`${routes.HOMEPAGE}${getUrlParameter(params?.continue) || ``}`, { replace: true })

  if (isBrowser) {
    if (enabled) {
      storage.set(keys?.maintenance, enabled)
      if (!authorised && !active)
        navigate(`${routes.PASSWORD}${location.pathname !== routes.HOMEPAGE ? `?${params?.continue}=${location.pathname}` : ``}`, {
          replace: true,
        })
    }

    if ((active && !enabled) || (active && enabled && authorised)) {
      storage.remove(keys?.maintenance)
      navigateToSite()
    }
  }

  const validatePassword = useCallback(() => {
    if (password === data?.password) {
      storage.set(keys?.password, encodeBase64(data?.password))
      setData(INITIAL_STATE)
      setError(false)
      navigateToSite()
    } else {
      storage.remove(keys?.password)
      setData(INITIAL_STATE)
      setError(true)
    }
  }, [encodeBase64, setData, setError, navigate, routes, password, data])

  return { active, authorised, enabled, error, data, setData, validatePassword }
}
