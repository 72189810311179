import React from "react"
import { GatsbyImage } from "./GatsbyImage"
import { ShopifyImage } from "./ShopifyImage"
import { ImageProps, withImage } from "./withImage"

export const Image = withImage(
  ({
    hoverImage,
    image,
    imageLink,
    maxWidth,
    onClick,
    ratio,
    alt = "",
    cover = false,
    critical = false,
    fullHeight = false,
    fullWidth = false,
    loading = "lazy",
    staticImage = false,
    ...rest
  }: ImageProps) => {
    if (!image) {
      return null
    }

    if (typeof image === "string") {
      return (
        <ShopifyImage
          alt={alt}
          onClick={onClick}
          ratio={ratio}
          src={image}
          cover={cover}
          fullWidth={fullWidth}
          hoverSrc={hoverImage}
          maxWidth={maxWidth}
          loading={loading}
          {...rest}
        />
      )
    }

    if ("asset" in image || "images" in image) {
      // @ts-ignore
      if (!image.asset && !image.images) {
        return null
      }

      return (
        <GatsbyImage
          image={image}
          alt={alt}
          onClick={onClick}
          ratio={ratio}
          fullHeight={fullHeight}
          imageLink={imageLink}
          critical={critical}
          staticImage={staticImage}
          {...rest}
        />
      )
    }

    return (
      <ShopifyImage
        alt={alt}
        onClick={onClick}
        ratio={ratio}
        cover={cover}
        fullWidth={fullWidth}
        hoverSrc={hoverImage}
        maxWidth={maxWidth}
        loading={loading}
        {...image}
        {...rest}
      />
    )
  }
)
