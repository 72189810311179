import { IconBCorp, IconSustainability, Link } from "@bared/ui"
import React from "react"
import { withFooterAchievements } from "./withFooterAchievements"

export const FooterAchievements = withFooterAchievements(() => (
  <aside className="w-fit flex pb-mini">
    <IconBCorp className="mr-maxi h-giga w-[40px]" />
    <Link href="https://www.sustainabilitytracker.com/brand/bared-footwear/" variant="link" target="_blank">
      <IconSustainability className="h-giga w-peta" />
    </Link>
  </aside>
))
