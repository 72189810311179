export type UseGeolocation = {
  getCurrentPosition: () => Promise<GeolocationPosition | GeolocationPositionError>
}
export const useGeolocation = (): UseGeolocation => {
  const getCurrentPosition = () =>
    new Promise<GeolocationPosition | GeolocationPositionError>((resolve, reject) => {
      if (!navigator) {
        reject("Geolocation unavailable")
      }

      navigator.geolocation.getCurrentPosition(
        position => {
          resolve(position)
        },
        err => {
          reject(err)
        }
      )
    })

  return {
    getCurrentPosition,
  }
}
