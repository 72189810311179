import { UIContext } from "@providers/global/ui"
import { type MutableRefObject, useContext, useCallback } from "react"

import { useHelper } from "./useHelper"

export const useScroll = () => {
  const { headerRef, topBarRef } = useContext(UIContext)
  const { scrollTo } = useHelper()

  const scrollToElementWithHeaderOffset = useCallback(
    (element: HTMLElement) => {
      const headerHeight = headerRef?.current.getBoundingClientRect().height ?? 0
      const topBarHeight = topBarRef?.current.getBoundingClientRect().height ?? 0
      const offsetTop = element.offsetTop

      scrollTo(offsetTop - headerHeight - topBarHeight)
    },
    [headerRef, topBarRef, scrollTo]
  )

  const scrollToRefWithHeaderOffset = useCallback(
    (ref: MutableRefObject<HTMLElement>) => {
      const element = ref.current
      if (!element) return
      scrollToElementWithHeaderOffset(ref.current)
    },
    [scrollToElementWithHeaderOffset]
  )

  return {
    headerRef,
    topBarRef,
    scrollToRefWithHeaderOffset,
    scrollToElementWithHeaderOffset,
  }
}
