import React from "react"

import { LocationProvider } from "./location"
import { TrackingProvider } from "./tracking"
import { WishlistProvider } from "./wishlist"
import { LocalisationProvider } from "./localisation"

import { UIProvider } from "./UIProvider"

export interface LocalisedProvidersProps {
  languageCode?: string
  children: React.ReactNode
}

export const LocalisedProviders: React.FC<LocalisedProvidersProps> = ({ languageCode, children }) => {
  return (
    <LocalisationProvider languageCode={languageCode}>
      <LocationProvider>
        <WishlistProvider>
          <TrackingProvider>
            <UIProvider>{children}</UIProvider>
          </TrackingProvider>
        </WishlistProvider>
      </LocationProvider>
    </LocalisationProvider>
  )
}
