import { useCallback } from "react"
import { useApp } from "./useApp"
import { useCore } from "./useCore"

export type UseFeatureToggle = {
  percentageToggle: (key: string, value: string) => boolean
}

export const useFeatureToggle = (): UseFeatureToggle => {
  const {
    config: {
      settings: { keys },
    },
  } = useApp()

  const {
    helpers: { storage },
  } = useCore()

  const savedState = storage.get(keys.feature_toggle)

  const percentageToggle = useCallback(
    (key: string, value: string) => {
      const valueAsFloat = parseFloat(value)
      if (isNaN(valueAsFloat)) {
        return !!value
      }

      if (savedState?.[key]) {
        return !!savedState?.[key]
      }

      const randomNumber = Math.random()
      const isToggleOn = randomNumber < valueAsFloat

      storage.set(keys.feature_toggle, {
        ...savedState,
        [key]: isToggleOn,
      })

      return isToggleOn
    },
    [savedState]
  )

  return {
    percentageToggle,
  }
}
