import React from "react"

import { ImageRatio, ImageWrapper, StyledImage } from "../Styled/Image"
import { withShopifyImage, ShopifyImageProps } from "./withShopifyImage"

export const ShopifyImage = withShopifyImage(
  ({ alt, cover, onClick, ratio, src, srcSet, fullWidth, hoverSrc, loading = "lazy" }: ShopifyImageProps) => {
    return ratio ? (
      <ImageWrapper fullWidth={fullWidth} onClick={onClick} className={hoverSrc ? "group" : ""}>
        <ImageRatio ratio={ratio} />
        <StyledImage className="bg-canvas" alt={alt} cover src={src} srcSet={srcSet} draggable={false} loading={loading} />
        {hoverSrc && <StyledImage className="bg-canvas" alt={alt} cover hover src={hoverSrc} draggable={false} loading={loading} />}
      </ImageWrapper>
    ) : (
      <StyledImage className="bg-canvas" alt={alt} cover={cover} onClick={onClick} src={src} srcSet={srcSet} draggable={false} loading={loading} />
    )
  }
)
