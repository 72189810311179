import tw, { TwStyle } from "twin.macro"
import { ThemeAspectRatio, ThemeColors, ThemeScreens } from "../../types/theme"
import { styledWithInvalidProps } from "./StyledWithInvalidProps"

export type StyledContainerProps = {
  width?: "full" | Extract<keyof ThemeScreens, string>
  ratio?: "none" | Extract<keyof ThemeAspectRatio, string>
  background?: "none" | Extract<keyof ThemeColors, "primary" | "beige" | "green" | "light" | "lighter" | "lightest" | "transparent">
}

type StyleKeyValuePair = {
  [key: string]: TwStyle
}

type Styles = {
  width: StyleKeyValuePair
  background: StyleKeyValuePair
  ratio: StyleKeyValuePair
}

const styles: Styles = {
  width: {
    full: tw`max-w-full`,
    xl: tw`max-w-xl mx-auto px-midi sm:px-maxi xl:px-tera`,
    lg: tw`mx-auto max-w-[1200px] px-midi sm:px-maxi lg:px-tera`,
    md: tw`mx-auto max-w-[1000px] px-midi sm:px-maxi xl:px-tera`,
    sm: tw`mx-auto max-w-[770px] px-midi sm:px-maxi`,
    xs: tw`mx-auto max-w-[600px] px-midi sm:px-maxi`,
    "2xl": tw`max-w-2xl mx-auto px-midi sm:px-maxi xl:px-tera`,
  },
  background: {
    none: null,
    primary: tw`bg-primary`,
    beige: tw`bg-tertiary`,
    green: tw`text-white bg-secondary`,
    light: tw`bg-neutral text-primary`,
    lighter: tw`bg-neutral-alt`,
    lightest: tw`bg-white`,
    transparent: tw`bg-transparent`,
  },
  ratio: {
    none: null,
    square: tw`aspect-square`,
    landscape: tw`aspect-landscape`,
    "3/4": tw`aspect-3-4`,
    "4/3": tw`aspect-4-3`,
    "4/5": tw`aspect-4-5`,
    "9/16": tw`aspect-9-16`,
    "11/12": tw`aspect-11-12`,
    "12/13": tw`aspect-12-13`,
    "16/9": tw`aspect-16-9`,
  },
}

export const BackgroundContainer = styledWithInvalidProps<StyledContainerProps>("section")(({ background = "none" }) => [
  tw`w-full`,
  styles.background[background],
])

export const StyledContainer = styledWithInvalidProps<StyledContainerProps>("div")(({ width = "2xl", ratio = "none", background = "none" }) => [
  tw`w-full`,
  styles.width[width],
  styles.ratio[ratio],
  styles.background[background],
])
