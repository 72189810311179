import * as Sentry from "@sentry/gatsby"
import { useCallback } from "react"
import { useApp } from "./useApp"

export type UseCurrency = {
  currencyMapping: Record<string, string>
  formatCurrency: (amount, code, precision) => string
  getCountryCodeFromCurrencyCode: (currencyCode: string) => string
}

export const useCurrency = () => {
  const {
    config: { stores },
  } = useApp()
  const [storeName] = Object.keys(stores)
  const currencyMapping = stores[storeName]?.currencyMapping

  const getCountryCodeFromCurrencyCode = useCallback(
    (currencyCode: string) => {
      const currencyMappingReverse = Object.keys(currencyMapping || {}).reduce((result, key) => {
        return {
          ...result,
          [currencyMapping[key]]: key,
        }
      }, {})

      return currencyMappingReverse[currencyCode]
    },
    [currencyMapping]
  )

  const formatCurrency = (amount, code, precision = 0) => {
    if (typeof amount === "undefined") {
      return ""
    }
    const price = amount
    try {
      return Intl.NumberFormat("en-AU", {
        style: "currency",
        currencyDisplay: "narrowSymbol",
        currency: code || "AUD",
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
      }).format(price)
    } catch (ex) {
      Sentry.addBreadcrumb({
        data: {
          amount,
          type: typeof amount,
          code,
          precision,
        },
      })

      // Safari doesn't support currencyDisplay: "narrowSymbol"
      // So in older browsers customers will see GBP 249
      return `${code} ${amount?.toString()?.replace(code, "")}`
    }
  }

  return {
    currencyMapping,
    formatCurrency,
    getCountryCodeFromCurrencyCode,
  }
}
