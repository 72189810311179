import { withFocusRing } from "@components/Accessibility/FocusRing/FocusRing"
import {
  Combobox,
  ComboboxInput as StyledComboboxInput,
  ComboboxList as StyledComboboxList,
  ComboboxOption as StyledComboboxOption,
  ComboboxPopover as StyledComboboxPopover,
} from "@reach/combobox"
import "@reach/combobox/styles.css"
import React from "react"
import tw, { styled } from "twin.macro"
import { Icon as StyledIcon } from "@components/Icon"
import { StyledH5 } from "@components/Styled/Text"
import { FormPlaceAutocompleteOutputProps, withFormPlaceAutocomplete } from "./withFormPlaceAutocomplete"

const styles = {
  light: disabled => (disabled ? tw`from-transparent via-neutral to-neutral` : tw`from-transparent via-white to-white`),
  beige: () => tw`from-transparent via-neutral to-neutral`,
  transparent: () => null,
}

const Wrapper = styled.div`
  ${tw`w-full h-full border-grey`}
  ${({ width }) => (width === `1/2` ? tw`md:w-1/2` : null)}
`

const ComboboxInput = styled(StyledComboboxInput)`
  ${tw`w-full outline-none transition-presentation text-body leading-input p-mini focus:outline-none md:text-h4`}
  ${({ error }) => (error ? tw`border-warning` : tw`border-grey`)}
  ${({ disabled }) => (disabled ? tw`bg-neutral` : tw`bg-transparent`)}
`

const ComboboxInputContainer = tw.div`
  relative
`

const ComboboxIconContainer = styled.div`
  ${tw`absolute top-0 right-0 z-10 flex items-center h-full pl-maxi pointer-events-none pr-mini text-grey bg-gradient-to-r`}
  ${({ disabled, bgColour }) => styles[bgColour]?.(disabled)}
`

const ComboboxList = styled(StyledComboboxList)`
  ${tw`whitespace-pre-wrap bg-transparent outline-none text-hint leading-input focus:outline-none`}
  [data-suggested-value],
  [data-user-value] {
    ${tw`font-normal`}
  }
`

const ComboboxPopover = styled(StyledComboboxPopover)`
  ${tw`pb-mini z-100 rounded-pico shadow-large`}
`

const ComboboxOption = styled(StyledComboboxOption)`
  ${tw`flex py-mini px-mini`}
`

const ComboboxSuggestions = styled(StyledH5)`
  ${tw`flex leading-caption pt-mini pb-pico px-mini text-grey relative`}
`

const ComboboxLoading = styled(ComboboxSuggestions)`
  ${tw`text-black normal-case leading-input`}
`

const Close = withFocusRing(tw.button`
  absolute right-0 top-pico cursor-pointer z-10 hover:opacity-70
`)

const CloseIcon = styled(StyledIcon)`
  ${tw`block pt-mini pr-mini text-grey`}
`

const InputIcon = styled(StyledIcon)``

const FormPlaceAutocomplete = withFormPlaceAutocomplete(
  ({
    active,
    bgColour,
    "data-testid": dataTestId,
    className,
    inputRef,
    loading,
    loadingLabel,
    onClose,
    onChange,
    onSelect,
    onTouched,
    placeholder,
    readOnly,
    required,
    suggestions,
    suggestionsLabel,
    touched,
    value,
    width,
  }: FormPlaceAutocompleteOutputProps) => (
    <Wrapper width={width} className={className}>
      <Combobox aria-required={required} onSelect={onSelect}>
        <ComboboxInputContainer>
          <ComboboxInput
            data-testid={dataTestId}
            disabled={readOnly}
            onChange={onChange}
            onFocus={onTouched}
            placeholder={placeholder}
            ref={inputRef}
            value={value}
          />
          <ComboboxIconContainer disabled={readOnly} bgColour={bgColour}>
            {!loading && <InputIcon name="search" size="xsmall" />}
            {loading && <InputIcon spin={true} name="spinner" size="xsmall" />}
          </ComboboxIconContainer>
        </ComboboxInputContainer>
        {touched && active && (
          <ComboboxPopover>
            <ComboboxSuggestions as="span">
              {suggestionsLabel}
              <Close onClick={onClose} title="Close">
                <CloseIcon name="close" size="xxs" />
              </Close>
            </ComboboxSuggestions>
            {loading && <ComboboxLoading>{loadingLabel}</ComboboxLoading>}
            <ComboboxList>
              {!loading &&
                suggestions?.map(({ description, place_id }) => (
                  <ComboboxOption key={place_id} value={description}>
                    {description}
                  </ComboboxOption>
                ))}
            </ComboboxList>
          </ComboboxPopover>
        )}
      </Combobox>
    </Wrapper>
  )
)

export default FormPlaceAutocomplete
