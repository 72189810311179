import { Footer } from "@bared/ui"
import React from "react"
import SubscribeFormFooter from "../Subscribe/SubscribeFormFooter"
import FooterContent from "./FooterContent"

const FooterSection = () => (
  <Footer className="flex flex-col lg:flex-row">
    <SubscribeFormFooter />
    <FooterContent />
  </Footer>
)

export default FooterSection
