exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-activate-tsx": () => import("./../../../src/pages/account/activate.tsx" /* webpackChunkName: "component---src-pages-account-activate-tsx" */),
  "component---src-pages-account-addresses-tsx": () => import("./../../../src/pages/account/addresses.tsx" /* webpackChunkName: "component---src-pages-account-addresses-tsx" */),
  "component---src-pages-account-edit-jsx": () => import("./../../../src/pages/account/edit.jsx" /* webpackChunkName: "component---src-pages-account-edit-jsx" */),
  "component---src-pages-account-forgot-tsx": () => import("./../../../src/pages/account/forgot.tsx" /* webpackChunkName: "component---src-pages-account-forgot-tsx" */),
  "component---src-pages-account-giftcard-jsx": () => import("./../../../src/pages/account/giftcard.jsx" /* webpackChunkName: "component---src-pages-account-giftcard-jsx" */),
  "component---src-pages-account-jsx": () => import("./../../../src/pages/account.jsx" /* webpackChunkName: "component---src-pages-account-jsx" */),
  "component---src-pages-account-login-auth-tsx": () => import("./../../../src/pages/account/login/auth.tsx" /* webpackChunkName: "component---src-pages-account-login-auth-tsx" */),
  "component---src-pages-account-login-loading-tsx": () => import("./../../../src/pages/account/login/loading.tsx" /* webpackChunkName: "component---src-pages-account-login-loading-tsx" */),
  "component---src-pages-account-login-tsx": () => import("./../../../src/pages/account/login.tsx" /* webpackChunkName: "component---src-pages-account-login-tsx" */),
  "component---src-pages-account-loyalty-tsx": () => import("./../../../src/pages/account/loyalty.tsx" /* webpackChunkName: "component---src-pages-account-loyalty-tsx" */),
  "component---src-pages-account-orders-tsx": () => import("./../../../src/pages/account/orders.tsx" /* webpackChunkName: "component---src-pages-account-orders-tsx" */),
  "component---src-pages-account-preferences-jsx": () => import("./../../../src/pages/account/preferences.jsx" /* webpackChunkName: "component---src-pages-account-preferences-jsx" */),
  "component---src-pages-account-register-tsx": () => import("./../../../src/pages/account/register.tsx" /* webpackChunkName: "component---src-pages-account-register-tsx" */),
  "component---src-pages-account-reset-tsx": () => import("./../../../src/pages/account/reset.tsx" /* webpackChunkName: "component---src-pages-account-reset-tsx" */),
  "component---src-pages-account-wishlist-tsx": () => import("./../../../src/pages/account/wishlist.tsx" /* webpackChunkName: "component---src-pages-account-wishlist-tsx" */),
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-feedback-tsx": () => import("./../../../src/pages/feedback.tsx" /* webpackChunkName: "component---src-pages-feedback-tsx" */),
  "component---src-pages-password-jsx": () => import("./../../../src/pages/password.jsx" /* webpackChunkName: "component---src-pages-password-jsx" */),
  "component---src-pages-schedule-tsx": () => import("./../../../src/pages/schedule.tsx" /* webpackChunkName: "component---src-pages-schedule-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-wishlist-tsx": () => import("./../../../src/pages/wishlist.tsx" /* webpackChunkName: "component---src-pages-wishlist-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-collection-tsx": () => import("./../../../src/templates/collection.tsx" /* webpackChunkName: "component---src-templates-collection-tsx" */),
  "component---src-templates-flexible-tsx": () => import("./../../../src/templates/flexible.tsx" /* webpackChunkName: "component---src-templates-flexible-tsx" */),
  "component---src-templates-homepage-tsx": () => import("./../../../src/templates/homepage.tsx" /* webpackChunkName: "component---src-templates-homepage-tsx" */),
  "component---src-templates-product-route-tsx": () => import("./../../../src/templates/product/route.tsx" /* webpackChunkName: "component---src-templates-product-route-tsx" */),
  "component---src-templates-returns-portal-tsx": () => import("./../../../src/templates/returns-portal.tsx" /* webpackChunkName: "component---src-templates-returns-portal-tsx" */)
}

