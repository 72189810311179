import { useCallback } from "react"
import { useLocalisationContext } from "./useLocalisation"
import { sanityContent } from "@helpers/sanity"

export const useRichText = () => {
  const { currentLocale } = useLocalisationContext()

  const parseContent = useCallback(
    content => {
      return sanityContent(content, currentLocale?.languageCode)
    },
    [currentLocale]
  )

  return {
    parseContent,
  }
}
