import { useApp } from "@hooks/useApp"
import { useCart } from "@hooks/useCart"
import { useTemplate } from "@hooks/useTemplate"
import { ProductVariant } from "@shopify/hydrogen-react/storefront-api-types"
import { ComponentProps, NormalisedProductVariant } from "@ts/components"
import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useRef, useState } from "react"

export type AddToCartInputProps = ComponentProps & {
  cart?: boolean
  notify?: boolean
  selectedQuantity?: number
  selectedVariant?: NormalisedProductVariant | ProductVariant
  selectedVariantAccessories?: NormalisedProductVariant[]
  wishlist?: boolean
  upsell?: boolean
}

export type AddToCartOutputProps = Omit<AddToCartInputProps, "selectedQuantity"> & {
  active: boolean
  additionalAddingToCart?: string
  additionalAddToCart?: string
  additionalNotify?: string
  additionalOutOfStock?: string
  className?: string
  errorActive: boolean
  loading: boolean
  onAddToCart: (id: string) => void
  productUpsellActive: boolean
  setActive: Dispatch<SetStateAction<boolean>>
  setErrorActive: Dispatch<SetStateAction<boolean>>
  setProductUpsellActive: Dispatch<SetStateAction<boolean>>
}

export const withAddToCart =
  (Component: FC<AddToCartOutputProps>) =>
  ({
    name = "AddToCart",
    selectedVariant,
    selectedVariantAccessories,
    notify,
    wishlist,
    cart,
    selectedQuantity = 1,
    upsell,
    ...rest
  }: AddToCartInputProps) => {
    const { addToCart, loading, addAttributes } = useCart()

    const { globalStateReducer } = useApp()
    const [{ activeCart }, dispatch] = globalStateReducer
    const handleCartActive = useCallback(() => {
      dispatch({
        type: "setActiveCart",
        payload: {
          activeCart: true,
          newItemAdded: true,
        },
      })
    }, [activeCart])

    const {
      product: { additionalAddingToCart, additionalAddToCart, additionalOutOfStock, additionalNotify },
    } = useTemplate()

    const [backToStockActive, setBackToStockActive] = useState(false)
    const [errorActive, setErrorActive] = useState(false)
    const [productUpsellActive, setProductUpsellActive] = useState(false)
    const prevLoading = useRef(loading)

    const handleAddToCart = id => {
      const attributes = addAttributes(selectedVariant)
      addToCart(id, selectedVariantAccessories, selectedQuantity, attributes)
    }

    useEffect(() => {
      if (prevLoading.current === false) {
        prevLoading.current = loading
      }
    }, [loading])

    useEffect(() => {
      if (prevLoading.current === true && loading === false) {
        handleCartActive()
      }
    }, [prevLoading, loading])

    Component.displayName = name

    return (
      <Component
        {...rest}
        active={backToStockActive}
        additionalAddingToCart={additionalAddingToCart}
        additionalAddToCart={additionalAddToCart}
        additionalNotify={additionalNotify}
        additionalOutOfStock={additionalOutOfStock}
        cart={cart}
        errorActive={errorActive}
        loading={loading}
        notify={notify}
        onAddToCart={handleAddToCart}
        productUpsellActive={productUpsellActive}
        selectedVariant={selectedVariant}
        setActive={setBackToStockActive}
        setErrorActive={setErrorActive}
        setProductUpsellActive={setProductUpsellActive}
        wishlist={wishlist}
        upsell={upsell}
      />
    )
  }
