import React, { ChangeEvent, FC, Ref, useCallback, useEffect, useRef, useState } from "react"
import * as Sentry from "@sentry/gatsby"
import { Suggestion } from "use-places-autocomplete"
import { useCheckout, useCheckoutContext } from "../../hooks/useCheckout"
import { useRichText } from "@hooks/useRichText"
import { useGeolocation } from "../../hooks/useGeolocation"
import { AddressDetails, useGooglePlaces } from "../../hooks/useGooglePlaces"
import { useLocation } from "../../hooks/useLocation"
import { useSettings } from "../../hooks/useSettings"
import { ComponentProps } from "../../types/components"
import { DeliveryOptionTimeEstimate, Store } from "../../types/sanity"
import { MoneyV2 } from "../../types/shopify-storefront"

export type DeliveryOptionsInputProps = ComponentProps & {
  layout: "cart" | "minicart"
}

export type DeliveryOptionsOutputProps = DeliveryOptionsInputProps & {
  address?: AddressDetails
  changeLocationLabel?: string
  dataTestId?: string
  error?: boolean
  geolocationErrorMessage?: string
  googlePlacesErrorMessage?: string
  hasGeolocationError: boolean
  hasGooglePlacesError: boolean
  hasResults: boolean
  invalidAddressErrorMessage?: string
  inputRef: Ref<HTMLInputElement>
  isShippingErrorActive: boolean
  loading?: boolean
  loadingLabel?: string
  locationButtonLabel?: string
  modalTriggerLabel?: string
  noShippingOptionsErrorMessage?: string
  onAddressSelect: (address: string, place: Suggestion) => void
  onAddressChange: (e: ChangeEvent<HTMLInputElement>) => void
  onChangeAddress: () => void
  onGeolocationClick: () => void
  onOptionSelect: (handle: string) => () => void
  readOnly?: boolean
  ready?: boolean
  shippingRates?: any
  suggestions?: Suggestion[]
  suggestionsLabel?: string
  textInputLabel?: string
  value?: string
  yourDeliveryOptionsLabel?: string
  yourLocationLabel?: string
}

export type DeliveryOptionsClickCollectHours = {
  label: string
  isAvailable: boolean
}

export type StoreWithTodaysOpeningHours = Store & {
  openingTimes: string
}

export type ShippingRatesWithEstimates = {
  content: string
  deliveryTimeEstimate: DeliveryOptionTimeEstimate
  handle: string
  name: string
  price: MoneyV2
  selected: boolean
}

// Remove this once Shopify allow us to set a flat rate regardless of fulfillment location
const fixedShippingRates = {
  AUD: [
    {
      name: "Express",
      cost: 15,
    },
    {
      name: "Standard",
      cost: 5.95,
    },
  ],
}

const adjustSplitFulfilmentShippingCosts = (shippingRate: ShippingRatesWithEstimates) => {
  const { price, name } = shippingRate
  const { currencyCode } = price

  if (!fixedShippingRates[currencyCode]) {
    return shippingRate
  }

  const adjustedShippingRate = fixedShippingRates[currencyCode].find(rate => rate.name.includes(name))

  return {
    ...shippingRate,
    price: {
      ...price,
      amount: adjustedShippingRate?.cost || price.amount,
    },
  }
}

export const withDeliveryOptions =
  (Component: FC<DeliveryOptionsOutputProps>) =>
  ({ name = "DeliveryOptions", "data-testid": dataTestId, layout, ...props }: DeliveryOptionsInputProps) => {
    const { address, error, ready, setValue, suggestions, value, getGeocode, isGeocoding } = useGooglePlaces()
    const { loading, data } = suggestions
    const [isShippingErrorActive, setIsShippingErrorActive] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [readOnly, setReadOnly] = useState<boolean>(false)
    const [hasResults, setHasResults] = useState<boolean>(false)
    const [hasGeolocationError, setHasGeolocationError] = useState<boolean>(false)
    const [hasGooglePlacesError, setHasGooglePlacesError] = useState<boolean>(false)
    const [shippingRates, setShippingRates] = useState<ShippingRatesWithEstimates[]>()
    const [tempShippingLine, setTempShippingLine] = useState<string>()
    const inputRef = useRef<HTMLInputElement | undefined>()
    const { country: location } = useLocation()
    const { parseContent } = useRichText()
    const { checkout } = useCheckoutContext()
    const { getShippingRates, updateShippingLine } = useCheckout()
    const { getCurrentPosition } = useGeolocation()
    const { deliveryOptionsSettings } = useSettings()
    const { lineItems, shippingLine } = checkout || {}
    const {
      changeLocationLabel,
      deliveryOptions,
      geolocationErrorMessage,
      googlePlacesErrorMessage,
      invalidAddressErrorMessage,
      loadingLabel,
      locationButtonLabel,
      modalTriggerLabel,
      noShippingOptionsErrorMessage,
      suggestionsLabel,
      textInputLabel,
      yourDeliveryOptionsLabel,
      yourLocationLabel,
    } = deliveryOptionsSettings

    const handleAddressSelect = async (address: string, place: Suggestion) => {
      setReadOnly(true)
      setValue(address)
      await getGeocode({
        placeId: place?.place_id,
      })
      setReadOnly(false)
      setHasResults(true)
    }

    const handleAddressChange = (e: ChangeEvent<HTMLInputElement>) => {
      setTempShippingLine(null)
      setValue(e.target.value)
    }

    const handleOptionSelect = (handle: string) => async () => {
      setTempShippingLine(handle)
      await updateShippingLine(handle)
    }

    const handleGeolocationClick = async () => {
      try {
        setReadOnly(true)
        setIsLoading(true)
        const position: GeolocationPosition = (await getCurrentPosition()) as GeolocationPosition
        await getGeocode(
          {
            location: {
              lat: position?.coords?.latitude,
              lng: position?.coords?.longitude,
            },
          },
          true
        )
        setHasGeolocationError(false)
      } catch (e) {
        Sentry.captureException(e)
        setHasGeolocationError(true)
      } finally {
        setReadOnly(false)
        setIsLoading(false)
      }
    }

    const handleChangeAddress = () => {
      setValue("")
      setHasResults(false)
    }

    const updateShippingRates = useCallback(() => {
      setShippingRates(
        prevState =>
          prevState?.map((item: ShippingRatesWithEstimates) => ({
            ...item,
            selected: tempShippingLine ? item.handle === tempShippingLine : item.handle == shippingLine?.handle,
          }))
      )
    }, [shippingLine, tempShippingLine, setShippingRates])

    const normaliseShippingRates = () => {
      if (!checkout?.availableShippingRates?.shippingRates) {
        return
      }

      const ratesWithEstimates = checkout?.availableShippingRates?.shippingRates
        ?.map(({ handle, priceV2 }) => {
          const [item] = deliveryOptions?.filter(item => handle.startsWith(item.handle)) ?? []
          if (!item) {
            return null
          }

          const { name, timeEstimates, content: rawContent } = item
          const content = parseContent(rawContent)
          const [timeEstimateFallback] = timeEstimates.filter(({ country }) => !country)
          const [timeEstimateForCountry] = timeEstimates.filter(({ country }) => country === location)

          return adjustSplitFulfilmentShippingCosts({
            content,
            deliveryTimeEstimate: timeEstimateForCountry || timeEstimateFallback,
            handle,
            name,
            price: priceV2,
            selected: shippingLine?.handle === handle,
          })
        })
        .filter(item => !!item)

      setShippingRates(ratesWithEstimates)
      setHasResults(true)
    }

    const fetchShippingRates = async () => {
      setHasResults(true)
      setIsLoading(true)
      try {
        await getShippingRates()
        setIsShippingErrorActive(false)
      } catch (e) {
        Sentry.captureException(e)
        setIsShippingErrorActive(true)
      } finally {
        setIsLoading(false)
      }
    }

    useEffect(() => {
      updateShippingRates()
    }, [shippingLine, tempShippingLine])

    useEffect(() => {
      normaliseShippingRates()
    }, [checkout])

    useEffect(() => {
      if (!address) {
        setValue("")
        setHasResults(false)
        return
      }
      fetchShippingRates()
    }, [address])

    useEffect(() => {
      setReadOnly(isGeocoding)
      setIsLoading(isGeocoding)
    }, [isGeocoding])

    useEffect(() => {
      setHasGooglePlacesError(error)
    }, [error])

    Component.displayName = name

    if (!lineItems?.length) {
      return null
    }

    return (
      <Component
        address={address}
        changeLocationLabel={changeLocationLabel}
        dataTestId={dataTestId}
        error={error}
        geolocationErrorMessage={geolocationErrorMessage}
        googlePlacesErrorMessage={googlePlacesErrorMessage}
        hasGeolocationError={hasGeolocationError}
        hasGooglePlacesError={hasGooglePlacesError}
        hasResults={hasResults}
        inputRef={inputRef}
        invalidAddressErrorMessage={invalidAddressErrorMessage}
        isShippingErrorActive={isShippingErrorActive}
        layout={layout}
        loading={loading || isLoading}
        loadingLabel={loadingLabel}
        locationButtonLabel={locationButtonLabel}
        modalTriggerLabel={modalTriggerLabel}
        noShippingOptionsErrorMessage={noShippingOptionsErrorMessage}
        onAddressSelect={handleAddressSelect}
        onAddressChange={handleAddressChange}
        onChangeAddress={handleChangeAddress}
        onGeolocationClick={handleGeolocationClick}
        onOptionSelect={handleOptionSelect}
        readOnly={readOnly}
        ready={ready}
        shippingRates={shippingRates}
        suggestions={data}
        suggestionsLabel={suggestionsLabel}
        textInputLabel={textInputLabel}
        value={value}
        yourDeliveryOptionsLabel={yourDeliveryOptionsLabel}
        yourLocationLabel={yourLocationLabel}
        {...props}
      />
    )
  }
