import React, { useMemo, useCallback, FC } from "react"

import { useApp } from "@hooks/useApp"
import { useWishlistContext } from "@hooks/useWishlist"
import { useCustomerContext } from "@hooks/useCustomer"
import { ComponentProps } from "@ts/components"

export type WishlistWidgetInputProps = ComponentProps

export type WishlistWidgetOutputProps = WishlistWidgetInputProps & {
  wishlistUrl: string
  count: number
  handleClick: () => void
}

export const withWishlistWidget = (Component: FC<WishlistWidgetOutputProps>) =>
  React.memo(({ name = "WishlistWidget" }: WishlistWidgetInputProps) => {
    const {
      config: {
        settings: { routes },
      },
      globalStateReducer,
    } = useApp()

    const { customer } = useCustomerContext()
    const { count } = useWishlistContext()

    const [, dispatch] = globalStateReducer

    const handleClick = useCallback(() => {
      dispatch({ type: "navigationClicked" })
    }, [dispatch])

    const wishlistUrl = !customer ? routes?.SAVED : routes?.WISHLIST

    Component.displayName = name
    return useMemo(() => <Component wishlistUrl={wishlistUrl} count={count} handleClick={handleClick} />, [wishlistUrl, count, handleClick])
  })
