import { LocationContext } from "@providers/localised/location"
import { useCallback, useContext } from "react"
import { useApp } from "./useApp"

export const useLocation = () => {
  const location = useContext(LocationContext)
  const { config } = useApp()

  const userData = useCallback(async () => {
    const response = await fetch(config?.services?.location?.serviceUrl)
    const data = await response.json()

    return {
      ipData: data,
      userAgent: navigator.userAgent || "",
    }
  }, [fetch, config])

  return { ...location, userData }
}
