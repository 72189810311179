import { useScrollPosition } from "@hooks/useScrollPosition"
import { ComponentProps } from "@ts/components"
import React, { useEffect, memo, FC } from "react"

export type ModalsDrawerInputProps = ComponentProps & {
  setActive: (active: boolean) => void
  active?: boolean
  right?: boolean
  noClose?: boolean
}

export type ModalsDrawerOutputProps = Omit<ModalsDrawerInputProps, "setActive" | "hash"> & {
  handleClick: () => void
}

export const withModalsDrawer = (Component: FC<ModalsDrawerOutputProps>) =>
  memo(({ name = "ModalsDrawer", setActive, active, ...props }: ModalsDrawerInputProps) => {
    const { scrollHidden } = useScrollPosition()

    const closeDrawer = e => {
      if (e.key === "Escape" && active) {
        setActive(false)
      }
    }

    const handleClick = () => {
      setActive(false)
    }

    useEffect(() => {
      window.addEventListener("keydown", closeDrawer)
      return () => window.removeEventListener("keydown", closeDrawer)
    }, [active])

    useEffect(() => {
      scrollHidden(active)
    }, [active, scrollHidden])

    Component.displayName = name
    return <Component children right={false} noClose={false} active={active} handleClick={handleClick} {...props} />
  })
