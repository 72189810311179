import FormCheckbox from "@components/Form/FormCheckbox"
import React from "react"
import tw, { styled } from "twin.macro"
import { CartNotesOutputProps, withCartNotes } from "./withCartNotes"

const Wrapper = styled.div`
  ${tw`flex flex-wrap w-full mt-midi p-midi bg-white`}
`

const Title = tw.div`
  text-body w-full mb-mini font-medium
`

const Description = tw.div`
  text-body mt-mini mb-micro w-full
`

const CheckoutWrapper = tw.div`
  w-full md:w-1/2
`

const ErrorMessage = tw.div`
  text-body mb-mini text-warning
`

const Textarea = styled.textarea`
  ${tw`w-full bg-transparent border focus:outline-none border-primary min-h-giga my-micro p-mini`} ::placeholder {
    ${tw`text-grey`}
  }
`

const CartNotes = withCartNotes(
  ({
    additionalCustomerNotePlaceholder,
    errors,
    handleNoteChange,
    handleOptionChange,
    handleSave,
    note,
    noteDescription,
    noteOption,
    noteTitle,
    selected,
  }: CartNotesOutputProps) => {
    return (
      <Wrapper>
        <Title>{noteTitle}</Title>
        {noteOption.map(option => {
          return (
            <CheckoutWrapper key={option.toString()}>
              <FormCheckbox name={option} label={option} handleChange={handleOptionChange} value={selected.includes(option)} internalState />
            </CheckoutWrapper>
          )
        })}
        {noteDescription && <Description>{noteDescription}</Description>}
        <Textarea
          data-testid="cart-notes"
          placeholder={additionalCustomerNotePlaceholder}
          value={note}
          onChange={handleNoteChange}
          onBlur={handleSave}
        />
        {errors?.length > 0 && <ErrorMessage>Could not save notes, please try again</ErrorMessage>}
      </Wrapper>
    )
  }
)

export default CartNotes
