import { AppContext, AppContextOutputProps } from "@providers/global/app"
import { useCallback, useContext } from "react"
import { useLocalisationContext } from "./useLocalisation"

export const useApp = (): AppContextOutputProps => {
  const { currentLocale } = useLocalisationContext()

  const mergeLocalGlobalKeys = useCallback(
    config => {
      const keys = config.settings.keys

      if (!keys.local) {
        return config
      }

      return {
        ...config,
        settings: {
          ...config.settings,
          keys: {
            ...keys.global,
            ...Object.keys(keys.local).reduce((result, key) => {
              if (currentLocale?.isDefault) {
                return {
                  ...result,
                  [key]: keys.local[key],
                }
              }

              return {
                ...result,
                [key]: `${keys.local[key]}:${currentLocale?.defaultCountryCode?.toLowerCase()}`,
              }
            }, {}),
          },
        },
      }
    },
    [currentLocale]
  )

  const app = useContext(AppContext)

  if (!app) {
    throw new Error("useApp must be wrapped in an AppProvider")
  }

  return {
    ...app,
    config: mergeLocalGlobalKeys(app.config),
  }
}
