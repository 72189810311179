import React, { type PropsWithChildren, useMemo, useState } from "react"

export const ShopContext: any = React.createContext({})

export const ShopProvider = ({ children }: PropsWithChildren) => {
  const [shop, setShop] = useState(false)

  const values = useMemo(
    () => ({
      shop,
      setShop,
    }),
    [shop, setShop]
  )

  return <ShopContext.Provider value={values}>{children}</ShopContext.Provider>
}
