import { type GatsbyCustomImage } from "@ts/components"
import { type CustomImage } from "@ts/sanity"
import { useApp } from "./useApp"

import { type GatsbyImageDataArgs, getGatsbyImageData } from "~/lib/images"

export const useImage = () => {
  const {
    config: {
      services: { sanity },
    },
  } = useApp()

  const getFluidImage = (
    image?: GatsbyCustomImage | CustomImage,
    options: GatsbyImageDataArgs = {
      placeholder: "dominantColor",
      layout: "constrained",
      width: 1000,
    }
  ) => {
    if (!image || !image?.asset) {
      return image
    }

    if ("gatsbyImageData" in image.asset) {
      return {
        ...image,
        ...image?.asset?.gatsbyImageData,
      }
    }

    if ("id" in image.asset || "_id" in image.asset || "_ref" in image.asset) {
      const imageData = getGatsbyImageData(image.asset, options, sanity)

      return {
        ...image,
        ...imageData,
      }
    }

    return image
  }

  return { getFluidImage }
}
