import { useApp } from "@hooks/useApp"
import { useLocalisationContext } from "@hooks/useLocalisation"
import { useNavigation, type NormalisedNavigation } from "@hooks/useNavigation"
import { useSale } from "@hooks/useSale"
import { useDebounce } from "@react-hooks-library/core"
import { type ComponentProps } from "@ts/components"
import React, { type FC, memo, useCallback, useMemo, useState } from "react"

export type NavigationHeaderItemsInputProps = ComponentProps & {
  navigationRootPath?: string
}

export type NavigationHeaderItemsOutputProps = Omit<NavigationHeaderItemsInputProps, "location"> & {
  active?: string
  activeMenu?: boolean
  activeSearch?: boolean
  handleSearchClick: () => void
  handleHover: (title: string | null) => () => void
  handleMenuClick: () => void
  isActivePath: (item: NormalisedNavigation) => boolean
  items: NormalisedNavigation[]
}

export const withNavigationHeaderItems = (Component: FC<NavigationHeaderItemsOutputProps>) =>
  memo(({ name = "NavigationHeaderItems", location, navigationRootPath }: NavigationHeaderItemsInputProps) => {
    const { sale, isSaleActive } = useSale()
    const { globalStateReducer } = useApp()
    const { currentLocale } = useLocalisationContext()
    const currentSale = isSaleActive()
    const [active, setActive] = useState<string | null>(null)
    const debouncedActive = useDebounce<string>(active, 200)
    const [{ activeMenu, activeSearch }, dispatch] = globalStateReducer
    const {
      header: { items, flattened },
      sale: { items: itemsSale, flattened: flattenedSale },
      vipSale: { items: itemsVIPSale, flattened: flattenedVIPSale },
    } = useNavigation()

    const handleMenuClick = useCallback(() => {
      dispatch({
        type: "setActiveMenu",
        payload: !activeMenu,
      })
    }, [dispatch, activeMenu])

    const handleSearchClick = useCallback(() => {
      dispatch({
        type: "setActiveSearch",
        payload: !activeSearch,
      })
    }, [dispatch, activeSearch])

    const handleHover = useCallback(
      (value: string | null) => () => {
        setActive(value)
      },
      [setActive]
    )

    const getNavigationItems = useCallback(() => {
      if (currentSale) {
        if (["vip", "vip-test"].includes(sale?.type)) {
          return {
            items: itemsVIPSale,
            flattened: flattenedVIPSale,
          }
        }
        return {
          items: itemsSale,
          flattened: flattenedSale,
        }
      }

      return {
        items,
        flattened,
      }
    }, [currentLocale, currentSale, sale])

    const isActivePath = useCallback(
      (item: NormalisedNavigation) => {
        if (!flattened || !item?.url) {
          return false
        }

        return (
          active === item?.title ||
          location?.pathname?.endsWith(item?.url) ||
          flattened[item.url]?.find(url => location?.pathname?.endsWith(url)) ||
          item?.url === navigationRootPath
        )
      },
      [active, navigationRootPath, location]
    )

    const { items: itemsNav } = getNavigationItems()

    Component.displayName = name

    return useMemo(
      () => (
        <Component
          active={debouncedActive}
          activeMenu={activeMenu}
          activeSearch={activeSearch}
          handleHover={handleHover}
          handleMenuClick={handleMenuClick}
          handleSearchClick={handleSearchClick}
          isActivePath={isActivePath}
          items={itemsNav}
        />
      ),
      [items, itemsSale, itemsVIPSale, active, activeMenu, activeSearch, sale, flattened, flattenedSale, flattenedVIPSale]
    )
  })
