import { HeadlessCore } from "@dotdev/headless-core-app"
import {
  CHECKOUT_ATTRIBUTES_UPDATE,
  CHECKOUT_CREATE,
  CHECKOUT_CUSTOMER_ASSOCIATE,
  CHECKOUT_CUSTOMER_DISASSOCIATE,
  CHECKOUT_DISCOUNT_APPLY,
  CHECKOUT_GIFTCARDS_APPEND,
  CHECKOUT_LINE_ITEM_ADD,
  CHECKOUT_LINE_ITEMS_REMOVE,
  CHECKOUT_LINE_ITEMS_REPLACE,
  CHECKOUT_SHIPPING_ADDRESS_UPDATE,
  CHECKOUT_SHIPPING_LINE_UPDATE,
  CUSTOMER_ACTIVATE,
  GET_CHECKOUT,
  GET_CUSTOMER_ORDERS,
  GET_COLLECTION_PRODUCT_COMPLETE,
  GET_COLLECTION_PRODUCT_REFERENCES,
  GET_PRODUCT_LITE,
  GET_PRODUCT_WITH_ACCESSORIES,
  GET_PRODUCT_WITH_RECOMMENDATIONS,
  GET_PRODUCT_WITH_RECOMMENDATIONS_AND_ACCESSORIES,
  GET_PRODUCT,
  GET_PRODUCTS_BY_HANDLE_LIGHT,
  GET_PRODUCTS_BY_HANDLE,
  GET_SHIPPING_RATES,
} from "../graphql/shopify"

export const useCore = () => {
  const queries = {
    GET_CHECKOUT,
    GET_COLLECTION_PRODUCT_COMPLETE,
    GET_COLLECTION_PRODUCT_REFERENCES,
    GET_CUSTOMER_ORDERS,
    GET_PRODUCT_LITE,
    GET_PRODUCT_WITH_ACCESSORIES,
    GET_PRODUCT_WITH_RECOMMENDATIONS,
    GET_PRODUCT_WITH_RECOMMENDATIONS_AND_ACCESSORIES,
    GET_PRODUCT,
    GET_PRODUCTS_BY_HANDLE_LIGHT,
    GET_PRODUCTS_BY_HANDLE,
    GET_SHIPPING_RATES,
  }

  const mutations = {
    CHECKOUT_ATTRIBUTES_UPDATE,
    CHECKOUT_CREATE,
    CHECKOUT_CUSTOMER_ASSOCIATE,
    CHECKOUT_CUSTOMER_DISASSOCIATE,
    CHECKOUT_DISCOUNT_APPLY,
    CHECKOUT_GIFTCARDS_APPEND,
    CHECKOUT_LINE_ITEM_ADD,
    CHECKOUT_LINE_ITEMS_REMOVE,
    CHECKOUT_LINE_ITEMS_REPLACE,
    CHECKOUT_SHIPPING_ADDRESS_UPDATE,
    CHECKOUT_SHIPPING_LINE_UPDATE,
    CUSTOMER_ACTIVATE,
  }

  return {
    ...HeadlessCore,
    graphql: {
      ...HeadlessCore.graphql,
      queries: {
        ...HeadlessCore.graphql.queries,
        ...queries,
      },
      mutations: {
        ...HeadlessCore.graphql.mutations,
        ...mutations,
      },
    },
    helpers: {
      ...HeadlessCore.helpers,
    },
  }
}
