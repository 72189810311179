import { useAnalytics } from "@hooks/useAnalytics"
import { useRoutes } from "@hooks/useRoutes"
import { useShopify } from "@hooks/useShopify"
import { useTemplate } from "@hooks/useTemplate"
import { ComponentProps, ResolvedLink } from "@ts/components"
import { SearchResultProductPresentmentPriceRanges } from "@ts/search"
import { ElasticImage, ElasticProduct, ElasticVariant } from "@usereactify/search"
import React, { FC } from "react"

export type SearchFormResultsProductInputProps = ComponentProps & {
  item: ElasticProduct
  index: number
  handleSearchClose: () => void
}

export type SearchFormResultsProductOutputProps = Pick<SearchFormResultsProductInputProps, "handleSearchClose" | "data-testid"> & {
  category: string
  handleClick: () => void
  image: ElasticImage
  isGiftCard: boolean
  link: ResolvedLink
  presentment_price_ranges: SearchResultProductPresentmentPriceRanges
  saleTag: string
  subTitle: string
  tags: string[]
  title: string
  variants: ElasticVariant[]
}

export const withSearchFormResultsProduct =
  (Component: FC<SearchFormResultsProductOutputProps>) =>
  ({ name = "SearchFormResultsProduct", item, index, handleSearchClose, "data-testid": dataTestId }: SearchFormResultsProductInputProps) => {
    const { linkResolver } = useRoutes()
    const { formatProductTitle, getSaleTagFromTags } = useShopify()
    const { trackProductImpression, trackProductClick, VisibilitySensor } = useAnalytics()
    const { images, presentment_price_ranges, tags, title, url, variants } = item || {}
    const formattedTitle = formatProductTitle(item?.tags) || title?.split(" ")[0]
    const subTitle = title?.replace(formattedTitle, "")
    const colour = tags?.find(tag => tag.includes("colour:"))?.split(":")[1]
    const category = tags?.find(tag => tag.includes("gender:"))?.replace("gender:", "")
    const saleTag = getSaleTagFromTags(tags)
    const image = images?.[0]
    const isGiftCard = tags?.includes("type:giftcard")
    const link = {
      ...linkResolver(item),
      url,
    }

    const handleClick = () => {
      trackProductClick({ product: item, colour, index, dataLayer: { item_list_name: "Instant Search Results" } })
    }

    const handleVisibilityChange = (visible: boolean) => {
      if (!visible) {
        return
      }
      trackProductImpression({ product: item, index, dataLayer: { item_list_name: "Instant Search Results" } })
    }

    Component.displayName = name
    return (
      <VisibilitySensor onChange={handleVisibilityChange}>
        <Component
          category={category}
          data-testid={dataTestId}
          handleClick={handleClick}
          handleSearchClose={handleSearchClose}
          image={image}
          isGiftCard={isGiftCard}
          link={link}
          presentment_price_ranges={presentment_price_ranges}
          saleTag={saleTag}
          subTitle={subTitle}
          tags={tags}
          title={formattedTitle}
          variants={variants}
        />
      </VisibilitySensor>
    )
  }
