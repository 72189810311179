import { type NormalisedProduct } from "@ts/components"
import { type Analytics } from "@ts/sanity"
import { type CheckoutLineItem, type ProductVariant } from "@ts/shopify-storefront"
import { useCallback } from "react"
import { useCheckoutContext } from "./useCheckout"
import { useCore } from "./useCore"
import { useCustomerContext } from "./useCustomer"
import { useKlaviyo } from "./useKlaviyo"
import { useShopify } from "./useShopify"
import { useTrackingContext } from "./useTracking"
import { useTripleWhale } from "./useTripleWhale"

export const useAnalytics = () => {
  const { checkout } = useCheckoutContext()
  const { customer } = useCustomerContext()
  const { track } = useKlaviyo()
  const tripleWhale = useTripleWhale()
  const { productNormaliser } = useShopify()
  const { referrer, tracked, gtmTrack, VisibilitySensor } = useTrackingContext()
  const {
    helpers: { decodeShopifyId },
  } = useCore()

  const formatPrice = price => {
    if (typeof price == "number" && !isNaN(price)) {
      return price.toFixed(2)
    }
    return price && price?.replace ? parseFloat(price.replace(/,/g, "."))?.toFixed(2) : 0
  }

  const getVariantOptionValue = (options, selectedName) =>
    options?.find(({ name }) => name?.toLowerCase() === selectedName?.toLowerCase())?.value || ""

  const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const isBrowser = () => typeof window !== "undefined"

  const decorateUrl = (url: string) => {
    if (isBrowser()) {
      const ga = window[window["GoogleAnalyticsObject"]]
      // @ts-ignore next-line
      if (ga && typeof ga.getAll === "function" && ga.plugins) {
        // @ts-ignore next-line
        const gaTracker = ga.getAll()[0]
        // @ts-ignore next-line
        return new window.gaplugins.Linker(gaTracker).decorate(url)
      }
    }
    return url
  }

  const pushGA4Event = dataLayer => {
    // Clear the previous ecommerce object.
    if (dataLayer?.ecommerce) {
      gtmTrack({ dataLayer: { ecommerce: null }, dataLayerName: "GA4" })
    }

    gtmTrack({ dataLayer, dataLayerName: "GA4" })
  }

  const trackProductListImpression = useCallback(
    ({ products, dataLayer }: { products: unknown[]; dataLayer?: { item_list_id?: string; item_list_name?: string } }) => {
      if (!tracked) {
        return
      }

      const productsList = products.map(product => {
        const { collections, id, title, vendor, productType, metafields, variants, tags_category, variant_options } = productNormaliser(product)
        return { collections, id, title, vendor, productType, metafields, variants, tags_category, variant_options }
      })

      pushGA4Event({
        event: "view_item_list",
        ecommerce: {
          item_list_id: dataLayer?.item_list_id ?? "",
          item_list_name: dataLayer?.item_list_name ?? "Collection Results",
          currency: checkout?.currencyCode,
          items: productsList.map((product, index: number) => ({
            item_id: typeof product.id === "number" ? `${product.id}` : decodeShopifyId(product.id, "Product"),
            item_name: product.title,
            item_brand: product.vendor,
            item_variant: product.variants?.[0]?.selectedOptions
              ? getVariantOptionValue(product.variants?.[0]?.selectedOptions, `Colour`)
              : product.variant_options?.[0],
            item_category: product?.metafields?.find(item => item?.key === "netsuite_gender")?.value || product?.productType,
            item_category2: product?.metafields?.find(item => item?.key === "netsuite_class")?.value,
            item_category3: product?.metafields?.find(item => item?.key === "netsuite_subclass")?.value,
            index,
            quantity: 1,
            price: product.variants?.[0]?.priceV2?.amount ?? formatPrice(product.variants?.[0]?.price),
            item_list_id: dataLayer?.item_list_id ?? "",
            item_list_name: dataLayer?.item_list_name ?? "Collection Results",
          })),
        },
      })
    },
    [gtmTrack, checkout, productNormaliser, decodeShopifyId]
  )

  const trackProductImpression = useCallback(
    ({ product, index, dataLayer }: { product: any; index: number; dataLayer?: { item_list_id?: string; item_list_name?: string } }) => {
      if (!tracked) {
        return
      }

      const { id, title, vendor, price_min, variants, variant_options, productType, metafields } = productNormaliser(product)
      if (title) {
        pushGA4Event({
          event: "view_item_list",
          ecommerce: {
            item_list_id: dataLayer?.item_list_id ?? "",
            item_list_name: dataLayer?.item_list_name ?? "Collection Results",
            currency: checkout?.currencyCode,
            items: [
              {
                item_id: typeof id === "number" ? `${id}` : decodeShopifyId(id, "Product"),
                item_name: title,
                item_brand: vendor,
                item_variant:
                  variants && variants[0]?.selectedOptions ? getVariantOptionValue(variants[0]?.selectedOptions, "Colour") : variant_options?.[0],
                item_category: metafields?.find(item => item?.key === "netsuite_gender")?.value || productType,
                item_category2: metafields?.find(item => item?.key === "netsuite_class")?.value,
                item_category3: metafields?.find(item => item?.key === "netsuite_subclass")?.value,
                item_list_name: dataLayer?.item_list_name ?? "Collection Results",
                item_list_id: dataLayer?.item_list_id ?? "",
                index,
                quantity: 1,
                price: variants?.[0]?.priceV2?.amount ?? formatPrice(price_min),
              },
            ],
          },
        })
      }
    },
    [gtmTrack, checkout, productNormaliser, decodeShopifyId]
  )

  const trackProductClick = useCallback(
    ({
      product,
      colour,
      index,
      dataLayer,
    }: {
      product: any
      colour: string
      index: number
      dataLayer?: { item_list_id?: string; item_list_name?: string }
    }) => {
      if (!tracked) {
        return
      }

      const { id, title, vendor, price_min, variants, variant_options, productType, metafields } = productNormaliser(product)

      if (title) {
        pushGA4Event({
          event: "select_item",
          ecommerce: {
            item_list_id: dataLayer?.item_list_id ?? "",
            item_list_name: dataLayer?.item_list_name ?? "Collection Results",
            currency: checkout?.currencyCode,
            items: [
              {
                item_id: id,
                item_name: title,
                item_brand: vendor,
                item_variant:
                  variants && variants[0]?.selectedOptions ? getVariantOptionValue(variants[0]?.selectedOptions, "Colour") : variant_options?.[0],
                item_category: metafields?.find(item => item?.key === "netsuite_gender")?.value || productType,
                item_category2: metafields?.find(item => item?.key === "netsuite_class")?.value,
                item_category3: metafields?.find(item => item?.key === "netsuite_subclass")?.value,
                item_list_name: dataLayer?.item_list_name ?? "Collection Results",
                item_list_id: dataLayer?.item_list_id ?? "",
                colour,
                index,
                quantity: 1,
                price: variants?.[0]?.priceV2?.amount ?? formatPrice(price_min),
              },
            ],
          },
        })
      }
    },
    [gtmTrack, checkout, productNormaliser, decodeShopifyId]
  )

  const trackProductView = useCallback(
    (product: NormalisedProduct, variant: ProductVariant, parentCollection) => {
      if (!tracked) {
        return
      }

      const { id, productType, title, vendor, metafields } = productNormaliser(product)

      if (title) {
        const dataLayer = {
          event: "view_item",
          ecommerce: {
            currency: checkout?.currencyCode,
            items: [
              {
                item_id: decodeShopifyId(id, "Product"),
                item_name: title,
                item_brand: vendor,
                item_variant: getVariantOptionValue(
                  variant?.selectedOptions || (product?.variants && product?.variants[0]?.selectedOptions),
                  `Colour`
                ),
                item_category: metafields?.find(item => item?.key === "netsuite_gender")?.value || productType,
                item_category2: metafields?.find(item => item?.key === "netsuite_class")?.value,
                item_category3: metafields?.find(item => item?.key === "netsuite_subclass")?.value,
                quantity: 1,
                price: formatPrice(variant?.priceV2?.amount || (product?.variants && product?.variants[0]?.priceV2?.amount)),
              },
            ],
          },
        }

        pushGA4Event(dataLayer)
      }
    },
    [gtmTrack, checkout, productNormaliser, decodeShopifyId]
  )

  const trackCartUpdate = useCallback(
    (type: "add" | "remove" | "change", variantId: string, quantity: number, lineitems: CheckoutLineItem[]) => {
      if (!tracked) {
        return
      }

      const selectedLineItem = lineitems?.filter(({ variant }) => variant?.id === variantId)[0]
      if (selectedLineItem?.title) {
        const dataLayer = {
          event: ["add", "change"].includes(type) ? "add_to_cart" : "remove_from_cart",
          ecommerce: {
            currency: checkout?.currencyCode,
            items: [
              {
                item_id: decodeShopifyId(variantId, "ProductVariant"),
                item_name: selectedLineItem?.title,
                item_brand: selectedLineItem?.variant?.product?.vendor,
                item_variant: getVariantOptionValue(selectedLineItem?.variant?.selectedOptions, `Colour`),
                item_category:
                  selectedLineItem?.variant?.product?.metafields?.find(item => item?.key === "netsuite_gender")?.value ||
                  selectedLineItem?.variant?.product?.productType,
                item_category2: selectedLineItem?.variant?.product?.metafields?.find(item => item?.key === "netsuite_class")?.value,
                item_category3: selectedLineItem?.variant?.product?.metafields?.find(item => item?.key === "netsuite_subclass")?.value,
                price: formatPrice(selectedLineItem?.variant?.priceV2?.amount),
                quantity,
              },
            ],
          },
        }

        pushGA4Event(dataLayer)

        track(`Cart ${type === "add" ? "Add" : type == "change" ? "Update" : "Remove"}`, {
          ...selectedLineItem,
          quantity,
        })

        // TripleWhale Analytics: Send add to cart events to TripleWhale analytics
        if (type === "add" && !!variantId && !!quantity) tripleWhale?.trackAddToCart(decodeShopifyId(variantId, "ProductVariant"), quantity)
      }
    },
    [gtmTrack, checkout, decodeShopifyId, track]
  )

  const trackCartView = useCallback(() => {
    if (!tracked) {
      return
    }

    const dataLayer = {
      event: "view_cart",
      ecommerce: {
        currency: checkout?.currencyCode,
        items: checkout?.lineItems?.map(lineitem => ({
          item_id: decodeShopifyId(lineitem?.variant?.id, "ProductVariant"),
          item_name: lineitem?.title,
          item_brand: lineitem?.variant?.product?.vendor,
          item_variant: getVariantOptionValue(lineitem?.variant?.selectedOptions, `Colour`),
          item_category:
            lineitem?.variant?.product?.metafields?.find(item => item?.key === "netsuite_gender")?.value || lineitem?.variant?.product?.productType,
          item_category2: lineitem?.variant?.product?.metafields?.find(item => item?.key === "netsuite_class")?.value,
          item_category3: lineitem?.variant?.product?.metafields?.find(item => item?.key === "netsuite_subclass")?.value,
          price: formatPrice(lineitem?.variant?.priceV2?.amount),
          quantity: lineitem?.quantity,
        })),
      },
    }

    pushGA4Event(dataLayer)
  }, [gtmTrack, checkout, decodeShopifyId, track])

  const trackWishlistUpdate = useCallback(
    (type, product) => {
      if (!tracked) {
        return
      }

      if (product?.title) {
        const dataLayer = {
          event: type === "add" ? "add_to_wishlist" : "remove_from_wishlist",
          ecommerce: {
            currency: checkout?.currencyCode,
            items: [
              {
                item_id: decodeShopifyId(product?.selectedVariant?.id, "ProductVariant") ?? product?.id,
                item_name: product?.title,
                item_brand: product?.vendor,
                item_variant: product?.variant_options
                  ? product?.variant_options?.[0]
                  : getVariantOptionValue(product?.selectedVariant?.selectedOptions ?? product?.variants?.[0]?.selectedOptions, `Colour`),
                item_category: product?.metafields?.find(item => item?.key === "netsuite_gender")?.value || product?.productType,
                item_category2: product?.metafields?.find(item => item?.key === "netsuite_class")?.value,
                item_category3: product?.metafields?.find(item => item?.key === "netsuite_subclass")?.value,
                price: formatPrice(product?.selectedVariant?.priceV2?.amount ?? product?.variants?.[0]?.priceV2?.amount ?? product?.price_min),
              },
            ],
          },
        }

        pushGA4Event(dataLayer)
      }
    },
    [gtmTrack, checkout, decodeShopifyId, track]
  )

  const trackPromoImpression = useCallback(
    ({ analyticsId, name, creative, position }: Partial<Analytics>) => {
      if (!tracked) {
        return
      }

      if (name) {
        const dataLayer = {
          event: "view_promotion",
          ecommerce: {
            currency: checkout?.currencyCode,
            items: [
              {
                promotion_id: analyticsId,
                promotion_name: name,
                creative_name: creative,
                creative_slot: position,
              },
            ],
          },
        }

        pushGA4Event(dataLayer)
      }
    },
    [gtmTrack, checkout]
  )

  const trackPromoClick = useCallback(
    ({ analyticsId, name, creative, position }: Partial<Analytics>) => {
      if (!tracked) {
        return
      }
      if (name) {
        const dataLayer = {
          event: "select_promotion",
          ecommerce: {
            currency: checkout?.currencyCode,
            items: [
              {
                promotion_id: analyticsId,
                promotion_name: name,
                creative_name: creative,
                creative_slot: position,
              },
            ],
          },
        }

        pushGA4Event(dataLayer)
      }
    },
    [gtmTrack, checkout]
  )

  const trackLogin = useCallback(
    async method => {
      if (!tracked) {
        return
      }

      if (method) {
        const dataLayer = {
          event: "login",
          ecommerce: {
            method: capitalizeFirstLetter(method),
          },
        }

        pushGA4Event(dataLayer)
      }
    },
    [gtmTrack]
  )

  const trackSignup = useCallback(
    async method => {
      if (!tracked) {
        return
      }

      if (method) {
        const dataLayer = {
          event: "sign_up",
          ecommerce: {
            method: capitalizeFirstLetter(method),
          },
        }

        pushGA4Event(dataLayer)
      }
    },
    [gtmTrack]
  )

  const trackShare = useCallback(
    async (method, type, id) => {
      if (!tracked) {
        return
      }

      if (method) {
        const dataLayer = {
          event: "share",
          ecommerce: {
            method: capitalizeFirstLetter(method),
            content_type: type,
            content_id: id,
          },
        }

        pushGA4Event(dataLayer)
      }
    },
    [gtmTrack]
  )

  const trackClick = useCallback(
    async (type, id) => {
      if (!tracked) {
        return
      }

      if (type) {
        const dataLayer = {
          event: "select_content",
          ecommerce: {
            content_type: type,
            content_id: id,
          },
        }

        pushGA4Event(dataLayer)
      }
    },
    [gtmTrack]
  )

  const trackEvent = useCallback(
    async (type: string, options: Record<string, string | undefined>) => {
      if (!tracked) {
        return
      }

      if (type) {
        const dataLayer = {
          event: type,
          ecommerce: {
            ...options,
          },
        }

        pushGA4Event(dataLayer)
      }
    },
    [gtmTrack]
  )

  const trackSearch = useCallback(
    async term => {
      if (!tracked) {
        return
      }

      if (term) {
        const dataLayer = {
          event: "search",
          ecommerce: {
            search_term: term,
          },
        }

        pushGA4Event(dataLayer)
      }
    },
    [gtmTrack]
  )

  return {
    capitalizeFirstLetter,
    formatPrice,
    getVariantOptionValue,
    tracked,
    trackEvent,
    trackProductListImpression,
    trackProductImpression,
    trackProductView,
    trackProductClick,
    trackCartView,
    trackCartUpdate,
    trackWishlistUpdate,
    trackPromoImpression,
    trackPromoClick,
    trackLogin,
    trackSignup,
    trackShare,
    trackSearch,
    trackClick,
    decorateUrl,
    VisibilitySensor,
  }
}
