import { LocalisationContext } from "@providers/localised/localisation"
import { type AsGraph, type WithLocalisation } from "@ts/components"
import { useCallback, useContext } from "react"

export type LocalisationFilterOptions = {
  language?: string
  includeBase?: boolean
  ignoreParents?: boolean
}

export const useLocalisationContext = () => {
  const localisationContext: LocalisationContext = useContext(LocalisationContext)
  return { ...localisationContext }
}

export const useLocalisation = () => {
  const { localisation, currentLocale, navigate } = useLocalisationContext()
  const { languageCode } = currentLocale || {}
  const routeMap = localisation?.reduce<Record<string, string>>((prev, curr) => ({ ...prev, [curr.languageCode]: curr.baseRoute }), {})

  const toGraph = <T>(node: T, nodes: AsGraph<T>): AsGraph<T> => {
    return {
      edges: [
        {
          node,
        },
        ...(nodes?.edges ?? nodes ?? []),
      ],
    }
  }

  const findNodeByLocale = useCallback(
    <T>(nodes: AsGraph<WithLocalisation<T>>, language: string = languageCode): T => {
      if (nodes?.edges?.length === 1) {
        return nodes.edges[0].node
      }

      const defaultNode = nodes?.edges?.find(({ node: { i18n_base } }) => i18n_base === null)
      const matchedOrDefaultNode = nodes?.edges?.find(({ node: { i18n_lang } }) => i18n_lang === language) || defaultNode
      return matchedOrDefaultNode?.node
    },
    [languageCode]
  )

  const filterNodesByLocale = useCallback(
    <T>(nodes: WithLocalisation<T>[], options?: LocalisationFilterOptions): T[] => {
      const { language = languageCode, includeBase = true, ignoreParents = false } = options || {}
      const parents = {}
      const filteredNodes = nodes?.filter(({ i18n_base, i18n_lang }) => {
        if (!includeBase) {
          return i18n_lang === language
        }

        if (i18n_base) {
          // There is an inconsistency between GROQ and GraphQL, GROQ (which we use for Search) uses _id, GraphQL uses id
          parents[i18n_base._id || i18n_base.id] = true
        }

        return i18n_base === null || i18n_lang === language
      })

      if (ignoreParents) {
        return filteredNodes?.filter(({ _id, id }) => !parents[_id || id])
      }

      return filteredNodes
    },
    [languageCode]
  )

  const filterEdgesByLocale = useCallback(
    <T>(nodes: AsGraph<WithLocalisation<T>>, options?: LocalisationFilterOptions): T[] => {
      return filterNodesByLocale(nodes?.edges.map(({ node }) => node), options)
    },
    [languageCode]
  )

  return {
    currentLocale,
    filterEdgesByLocale,
    filterNodesByLocale,
    findNodeByLocale,
    localisation,
    navigate,
    routeMap,
    toGraph,
  }
}
