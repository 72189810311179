import { useNavigation, type NormalisedNavigation } from "@hooks/useNavigation"
import { type ComponentProps } from "@ts/components"
import React, { FC, useMemo } from "react"

type NavigationFooterOutputProps = {
  items: NormalisedNavigation[]
}

export const withNavigationFooter = (Component: FC<NavigationFooterOutputProps>) =>
  React.memo(({ name = "NavigationFooter" }: ComponentProps) => {
    const {
      footer: { items },
    } = useNavigation()

    Component.displayName = name
    return useMemo(() => <Component items={items} />, [items])
  })
