import { withFocusRing } from "@components/Accessibility/FocusRing/FocusRing"
import { VisuallyHidden } from "@components/Accessibility/VisuallyHidden/VisuallyHidden"
import { FooterSocialLinks } from "@components/Footer/FooterSocialLinks"
import FormInput from "@components/Form/FormInput"
import { Icon } from "@components/Icon"
import { StyledButton } from "@components/Styled/Button"
import { BackgroundContainer, StyledContainer } from "@components/Styled/Container"
import { styledWithInvalidProps } from "@components/Styled/StyledWithInvalidProps"
import React from "react"
import tw, { styled } from "twin.macro"
import { withSubscribeForm } from "./withSubscribeForm"

const Container = tw(BackgroundContainer)`
  flex flex-col items-center md:items-start border-opacity-40 flex-1
`
const Wrapper = tw(StyledContainer)`
  flex flex-col items-center md:items-start justify-center pt-mega pb-midi 
`
const Title = tw.h5`
  font-display mb-mini text-h1 leading-h4 text-left max-w-full
`
const OptionsWrapper = styledWithInvalidProps("div")(({ hasError }) => [tw`flex items-center flex-row`, hasError ? tw`mb-0` : tw`mb-midi`])
const OptionsTitle = tw.div`
  text-body leading-h1 mr-micro pb-nano pt-mini mb-mini flex md:mb-0 md:mr-midi md:pt-0
`
const Options = tw.div`
  flex flex-row text-body
`
const Description = tw.div`
  pb-mini text-center md:text-left
`
const Option = withFocusRing(tw.button`
  mx-micro flex flex-row items-center cursor-pointer
`)
const OptionTitle = tw.span`
  pl-pico text-body leading-h1 pb-nano mt-nano ml-nano
`
const Form = tw.form`
  flex flex-col items-end w-full
`
const Input = withFocusRing(tw(FormInput)`
  flex-1 w-full mb-mini p-zero h-giga md:ml-mini md:mr-zero
`)
const Terms = styled.div`
  ${tw`mt-midi max-w-[600px] w-full text-center md:text-left text-caption leading-caption`}
  a {
    text-decoration: underline;
  }
`

const ErrorMessageBar = tw.div`
  text-caption mt-mini p-mini bg-white text-warning font-medium rounded-nano
`
const ErrorMessage = tw.p`text-caption my-micro text-warning`
const SuccessMessage = styled.div`
  ${tw`flex flex-col items-center justify-center w-full bg-white max-w-60 min-h-[56px] text-primary p-mini`}
  .rte {
    p {
      text-align: center;
      line-height: 1.5;
      padding-bottom: 8px;
    }
    p:last-of-type {
      padding-bottom: 0px;
    }
  }
`
const Button = styled(StyledButton)`
  ${tw`flex-none text-center w-full ml-0 mt-mini h-giga md:ml-mini md:mt-0`}
`
const SuccessTitle = tw.h5`
  mb-mini
`
const Required = tw.div`
  text-warning pl-micro
`

const SubscribeFormFooter = withSubscribeForm(
  ({
    data,
    emailTitle,
    description,
    terms,
    emailsInterestedInNotSetupMessage,
    errorInterestedInIsEmpty,
    errors,
    handleChange,
    handleInvalid,
    handleSubmit,
    loading,
    options,
    requiredFields,
    success,
    successMessage,
    successTitle,
    tags,
    toggleTag,
  }) => {
    return (
      <Container background="light">
        <Wrapper>
          <Title>{emailTitle}</Title>
          <Description>{description}</Description>
          <OptionsWrapper hasError={errorInterestedInIsEmpty}>
            <OptionsTitle>
              Interests<Required aria-hidden="true">*</Required>
              <VisuallyHidden>required</VisuallyHidden>
            </OptionsTitle>
            <Options>
              {options.map((option, index) => (
                <Option aria-label={`I am interested in: ${option?.title}`} key={`${option?.title}-${index}`} onClick={() => toggleTag(option?.tag)}>
                  {tags.indexOf(option?.tag) === -1 ? (
                    <Icon name="tickedBoxInactiveReverse" size="xxs" />
                  ) : (
                    <Icon name="tickedBoxActiveReverse" size="xxs" />
                  )}
                  <OptionTitle>{option?.title}</OptionTitle>
                </Option>
              ))}
            </Options>
          </OptionsWrapper>
          {errorInterestedInIsEmpty && <ErrorMessage>{emailsInterestedInNotSetupMessage}</ErrorMessage>}
          {!success ? (
            <>
              <Form onSubmit={handleSubmit}>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  value={data?.email}
                  handleChange={handleChange}
                  handleInvalid={handleInvalid}
                  required
                  data-testid="email-input"
                  label="Email"
                  requiredFields={requiredFields}
                />
                <Input id="phone" name="phone" type="phone" value={data?.phone} handleChange={handleChange} optional label="Mobile" />
                <Button colour="primary" height="56" textSize="16" type="submit">
                  {!loading ? "Sign up" : "Signing up..."}
                </Button>
              </Form>
              <Terms>{terms}</Terms>
              {errors?.length > 0 &&
                errors?.map(({ code, message }) => (
                  <ErrorMessageBar key={code}>
                    {message === "Email has already been taken" ? "This email address is already subscribed." : message}
                  </ErrorMessageBar>
                ))}
            </>
          ) : (
            <SuccessMessage>
              <SuccessTitle>{successTitle}</SuccessTitle>
              {successMessage}
            </SuccessMessage>
          )}
          <FooterSocialLinks />
        </Wrapper>
      </Container>
    )
  },
  {
    trackingName: "Footer",
  }
)

export default SubscribeFormFooter
