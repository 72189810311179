import { Icon } from "@components/Icon"
import ModalsPopup from "@components/Modals/ModalsPopup"
import { StyledButton } from "@components/Styled/Button"
import { UnderlineText } from "@components/Styled/Text"
import React from "react"
import tw from "twin.macro"
import { GeofencingOutputProps, withGeofencing } from "./withGeofencing"

const Container = tw.div`
  bg-neutral flex flex-col justify-center items-center p-maxi py-mega w-full md:px-giga
`
const Content = tw.div`
  text-center
`
const Button = tw(StyledButton)`
  w-full my-mini
`
const Link = tw(UnderlineText)`cursor-pointer mt-micro hover:text-secondary`
const Arrow = tw(Icon)``

const Geofencing = withGeofencing(
  ({
    active,
    children,
    content,
    continueCTA,
    onClose,
    onRedirect,
    redirectUrl,
    redirectCTA,
    redirectCTAColour,
    setActive,
  }: GeofencingOutputProps) => (
    <>
      <ModalsPopup active={active} setActive={setActive} onClose={onClose}>
        <Container>
          <Content>{content}</Content>
          <Button
            onClick={onRedirect}
            as="a"
            href={redirectUrl}
            height="56"
            textSize="16"
            colour={redirectCTAColour || "primary"}
            border
            data-testid="geofencing-redirect-trigger"
          >
            {redirectCTA}
          </Button>
          <Link onClick={onClose} data-testid="geofencing-continue-trigger">
            {continueCTA} <Arrow name="arrow" size="none" width="20" height="8" />
          </Link>
        </Container>
      </ModalsPopup>
      {children}
    </>
  )
)

export default Geofencing
