import { withFocusRing } from "@components/Accessibility/FocusRing/FocusRing"
import { Icon } from "@components/Icon"
import { ComponentProps } from "@ts/components"
import React, { ChangeEvent, MouseEvent } from "react"
import tw, { styled } from "twin.macro"
import FormLabel from "./FormLabel"

export type FormCheckboxProps = Omit<ComponentProps, "name"> & {
  handleChange?: (event: MouseEvent<HTMLDivElement> | ChangeEvent<HTMLInputElement>) => void
  internalState?: boolean
  label: string
  name: string
  value: boolean
  optional?: boolean
}

const Wrapper = tw.div`
  relative flex flex-row items-center cursor-pointer text-body
`
const Label = styled(FormLabel)`
  inline-block leading-h5 ml-micro p-0
`
const Input = withFocusRing(tw.input`
  block appearance-none opacity-0 absolute w-full h-full inset-0 cursor-pointer
`)

const FormCheckbox = ({ value, handleChange, label, name, className, internalState, id, optional, required, tabIndex }: FormCheckboxProps) => {
  return (
    <Wrapper onClick={!internalState ? handleChange : null} className={className}>
      <Icon name={!value ? `tickedBoxInactive` : `tickedBoxActive`} size="xxs" />
      <Label field="checkbox" id={id} required={required} optional={optional} label={label} />
      {internalState && <Input type="checkbox" checked={value} name={name} onChange={handleChange} value={value?.toString()} tabIndex={tabIndex} />}
    </Wrapper>
  )
}

export default FormCheckbox
